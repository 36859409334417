import React from "react";

// Material-UI
import { FormControlLabel, Switch } from "@mui/material";

const SwitchWarningFilter = ({ filterByWarnings, setFilterByWarnings }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={filterByWarnings}
          size="large"
          onChange={() => setFilterByWarnings(!filterByWarnings)}
        />
      }
       label="Show only rows with warnings"
    />
  );
};

export default SwitchWarningFilter;