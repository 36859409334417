import { useCallback } from "react";

/**
 * Custom hook to update selected rows with new employee name.
 * @param {Object} props - The properties.
 * @param {Array} props.data - The data array.
 * @param {Array} props.selection - The selected rows.
 * @param {Function} props.updateRow - Function to update row.
 * @param {Function} props.handleEmployeeValidationClose - Function to close employee validation.
 * @returns {Object} - Object containing the updateSelectedRows function.
 *
 */

const useEmployeeUpdateNameConfirmation = ({
  data,
  selection,
  updateRow,
  handleEmployeeValidationClose,
}) => {
  // Employee Name Confirmation and Update
  const updateSelectedRows = useCallback(
    (newEmployeeGivenames, newEmployeeSurname) => {
      try {
        const employeeRowIndex = data
          .filter((row) => selection.includes(row.__index))
          .map((row) => data.indexOf(row));

        const updatedData = data.map((row) => {
          if (selection.includes(row.__index)) {
            return {
              ...row,
              Givennames: newEmployeeGivenames,
              Surname: newEmployeeSurname,
            };
          } else {
            return row;
          }
        });
        updateRow(updatedData, { columns: {}, indexes: [employeeRowIndex] });
        handleEmployeeValidationClose();
      } catch (error) {
        console.error(error);
      }
    },
    [data, selection, updateRow, handleEmployeeValidationClose]
  );

  return { updateSelectedRows };
};

export default useEmployeeUpdateNameConfirmation;
