import React, { Fragment } from "react";

// Material ui
import {
  Avatar,
  Container,
  Paper,
  Stack,
  Typography,
  SvgIcon,
  Collapse,
} from "@mui/material";

// Icons
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ErrorStatementPeriodCollapse = () => {
  return (
    <Fragment>
      <Collapse in={true}>
        <Container maxWidth="auto">
          <Paper sx={{ border: "2px solid rgba(0, 133, 34, 0.3)" }}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", p: 3 }}>
              <Avatar
                sx={{
                  backgroundColor: "error.lightest",
                  color: "error.main",
                }}
              >
                <SvgIcon>
                  <WarningAmberIcon />
                </SvgIcon>
              </Avatar>
              <div>
                <Typography variant="h5">Errors Detected</Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 1 }}
                  variant="body1"
                >
                  There are some errors with the statement period start and end dates. Please review your spreadsheet data and try again.
                </Typography>
              </div>
            </Stack>
          </Paper>
        </Container>
      </Collapse>
    </Fragment>
  );
};

export default ErrorStatementPeriodCollapse;
