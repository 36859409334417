import React, { useState, Fragment } from 'react';

// AWS Amplify - API - Request Handler
// import { post, put } from 'aws-amplify/api';
import { handlePost, handlePut } from '../../utils/amplifyInstance';

// Auth Session
import { useSession } from '../../context/AuthSession';

// Material UI
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormControl,
  FormLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

// Common Functions
import { isUndefined, LookupValues, isEquivalent } from '../../common/HelperFunctions';
import useEmployeeInfoForm from '../../common/useEmployeeInfoForm';
import {
  validateEmployeeInfo,
  validSINFormat,
  validPhoneFormat,
} from '../../common/ValidationRules';
import CustomLoadingScreen from '../../ui-components/CustomLoadingScreen';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

// Third Party
import { useSnackbar } from 'notistack';

const classes = {
  datepickerInput: 'datepicker-input',
};

export const StyledTextField = styled(TextField)(({ theme }) => ({
  [`&.datepicker-input`]: {
    '& input::-webkit-calendar-picker-indicator': {
      marginLeft: '0px',
      opacity: 0.62,
    },
  },
}));

const EmployeeInfo = (props) => {
  const { authToken } = useSession();
  const { accountid, roleid, setOpenDialog, setRefresh } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [cancelEdit, setCancelEdit] = useState(false);
  const [reload, setReload] = useState(0);
  const [employeelistid, setEmployeeListId] = useState(props.employeelistid);

  const addData = async () => {
    let elid = 0;

    try {
      // const response = await post(
      //   "EmployerApiGw",
      //   "/employer/" + accountid + "/employeelist/" + data.employeelistid,
      //   {
      //     body: [data],
      //   }
      // );
      const response = await handlePost({
        apiName: 'EmployerApiGw',
        path: '/employer/' + accountid + '/employeelist/' + data.employeelistid,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [data],
      });

      const { body } = await response;
      const json = await body.json();

      elid = 'employeelistid' in json ? json.employeelistid : data.employeelistid;

      if (json.result === 1) {
        enqueueSnackbar('Employee added successfully!', { variant: 'success' });
        setEmployeeListId(elid);
        var i = reload + 1;
        setReload(i);
        setRefresh(true);
      } else {
        enqueueSnackbar(json.message, { variant: 'error' });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const updateData = async () => {
    try {
      // const response = await put(
      //   "EmployerApiGw",
      //   "/employer/" + accountid + "/employeelist/" + data.employeelistid,
      //   {
      //     body: [data],
      //   }
      // );

      const response = await handlePut({
        apiName: 'EmployerApiGw',
        path: '/employer/' + accountid + '/employeelist/' + data.employeelistid,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [data],
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        enqueueSnackbar('Employee updated successfully!', {
          variant: 'success',
        });
        setRefresh(true);
      } else {
        enqueueSnackbar(json.message, { variant: 'error' });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const submitData = () => {
    setReadOnlyMode(true);

    if (!isEquivalent(origData, data)) {
      if (employeelistid) {
        updateData();
      } else {
        addData();
      }
    } else {
      enqueueSnackbar('No changes detected.', { variant: 'success' });
    }
  };

  // Hook Props
  const hookProps = { ...props, authToken };

  // Validate Employee Information Hook
  const { isLoading, origData, data, errors, handleChange, handleLOVChange, handleSubmit } =
    useEmployeeInfoForm(
      submitData,
      validateEmployeeInfo,
      hookProps,
      employeelistid,
      cancelEdit,
      reload
    );

  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingScreen text="Loading Employee Information" />
      ) : (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="surname"
                name="surname"
                label="Last name"
                value={data.surname}
                InputProps={{
                  readOnly: readOnlyMode || employeelistid > 0,
                  endAdornment: employeelistid ? (
                    <InputAdornment position="start">
                      <Tooltip
                        arrow
                        leaveDelay={250}
                        title="If you need to change the member’s name, please contact the Plan Office."
                      >
                        <InfoIcon
                          sx={{
                            color: blue[500],
                          }}
                          fontSize={'small'}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ) : (
                    <div />
                  ),
                }}
                onChange={handleChange}
                error={!isUndefined(errors.surname)}
                helperText={errors.surname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="givennames"
                name="givennames"
                label="Legal first name"
                value={data.givennames}
                InputProps={{
                  readOnly: readOnlyMode || employeelistid > 0,
                  endAdornment: employeelistid ? (
                    <InputAdornment position="start">
                      <Tooltip
                        arrow
                        leaveDelay={250}
                        title="If you need to change the member’s name, please contact the Plan Office."
                      >
                        <InfoIcon
                          sx={{
                            color: blue[500],
                          }}
                          fontSize={'small'}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ) : (
                    <div />
                  ),
                }}
                onChange={handleChange}
                error={!isUndefined(errors.givennames)}
                helperText={errors.givennames}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="address1"
                name="address1"
                label="Address line 1"
                value={data.address1}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                error={!isUndefined(errors.address1)}
                helperText={errors.address1}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address2"
                name="address2"
                label="Address line 2"
                value={data.address2}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                value={data.city}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                fullWidth
                error={!isUndefined(errors.city)}
                helperText={errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <LookupValues
                accountid={accountid}
                name="province"
                label="Province"
                value={data.province}
                readOnlyMode={readOnlyMode}
                authToken={authToken}
                onValueChange={handleLOVChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                id="postalcode"
                name="postalcode"
                label="Postal code"
                value={data.postalcode}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                fullWidth
                error={!isUndefined(errors.postalcode)}
                helperText={errors.postalcode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="phone"
                name="phone"
                label="Phone number"
                value={
                  data.phone
                    ? data.phone.replace(/(\(?\d{3}\)?)(\d{3})(\d{4})/, validPhoneFormat)
                    : ''
                }
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                fullWidth
                error={!isUndefined(errors.phone)}
                helperText={errors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="emailaddress"
                name="emailaddress"
                label="Email"
                value={data.emailaddress}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                error={!isUndefined(errors.emailaddress)}
                helperText={errors.emailaddress}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField disabled id="id" name="id" label="ID" value={data.id} fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                id="sin"
                name="sin"
                label="SIN"
                value={
                  readOnlyMode
                    ? data.sin.length > 0
                      ? '***-***-' + data.sin.substr(data.sin.length - 3)
                      : data.sin
                    : data.sin.replace(/(\d{3})(\d{3})(\d{3})/, validSINFormat)
                }
                InputProps={{
                  readOnly: employeelistid > 0,
                  endAdornment: employeelistid ? (
                    <InputAdornment position="start">
                      <Tooltip
                        arrow
                        leaveDelay={250}
                        title="If you need to change the member’s Social Insurance Number, please contact the Plan Office."
                      >
                        <InfoIcon
                          sx={{
                            color: blue[500],
                          }}
                          fontSize={'small'}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ) : (
                    <div />
                  ),
                }}
                onChange={handleChange}
                fullWidth
                error={!isUndefined(errors.sin)}
                helperText={errors.sin}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledTextField
                className={classes.datepickerInput}
                type="date"
                value={data.birthdate}
                required
                id="birthdate"
                name="birthdate"
                label="Date of Birth"
                onKeyDown={(e) => (readOnlyMode ? e.preventDefault() : null)}
                InputProps={{
                  readOnly: employeelistid > 0,
                  endAdornment: employeelistid ? (
                    <InputAdornment position="start">
                      <Tooltip
                        arrow
                        leaveDelay={250}
                        title="If you need to change the member’s date of birth, please contact the Plan Office."
                      >
                        <InfoIcon
                          sx={{
                            color: blue[500],
                          }}
                          fontSize={'small'}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ) : (
                    <div />
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: '1900-01-01',
                  max: '2999-12-31',
                }}
                onChange={handleChange}
                fullWidth
                error={!isUndefined(errors.birthdate)}
                helperText={errors.birthdate ? errors.birthdate : ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl sx={{ paddingLeft: '8px' }} error={!isUndefined(errors.sex)}>
                <FormLabel
                  required={true}
                  className=""
                  sx={{
                    transformOrigin: 'top left',
                    transform: 'translate(0, -1.5px) scale(0.75)',
                  }}
                >
                  Sex at Birth
                </FormLabel>
                <RadioGroup row name="sex" value={data.sex} onChange={handleChange}>
                  <FormControlLabel
                    disabled={readOnlyMode}
                    value="F"
                    control={<Radio required={true} size="small" />}
                    label="Female"
                    sx={{ height: '1.4375em' }}
                  />
                  <FormControlLabel
                    disabled={readOnlyMode}
                    value="M"
                    control={<Radio required={true} size="small" />}
                    label="Male"
                    sx={{ height: '1.4375em' }}
                  />
                </RadioGroup>
                <FormHelperText>{errors.sex}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledTextField
                className={classes.datepickerInput}
                type="date"
                value={data.hiredate}
                required
                id="hiredate"
                name="hiredate"
                label="Hire Date"
                onKeyDown={(e) => (readOnlyMode ? e.preventDefault() : null)}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: '1900-01-01',
                  max: '2999-12-31',
                }}
                onChange={handleChange}
                fullWidth
                error={!isUndefined(errors.hiredate)}
                helperText={errors.hiredate ? errors.hiredate : ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <LookupValues
                accountid={accountid}
                name="employmenttype"
                label="Work Type"
                value={data.employmenttype}
                readOnlyMode={readOnlyMode}
                authToken={authToken}
                onValueChange={handleLOVChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LookupValues
                accountid={accountid}
                name="jobtitle"
                label="Occupation"
                value={data.jobtitle}
                readOnlyMode={readOnlyMode}
                authToken={authToken}
                onValueChange={handleLOVChange}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Box sx={{ paddingTop: '16px' }} visibility={readOnlyMode ? 'visible' : 'hidden'}>
              <Tooltip title="Edit Contact">
                <span>
                  <IconButton
                    disabled={roleid !== 1}
                    onClick={(e) => {
                      setCancelEdit(false);
                      setReadOnlyMode(false);
                    }}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton
                  color="secondary"
                  onClick={(event) => {
                    setOpenDialog(false);
                    setCancelEdit(true);
                    setReadOnlyMode(true);
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Grid container justifyContent="flex-end" sx={{ padding: 0, margin: 0 }}>
              <Box visibility={!readOnlyMode ? 'visible' : 'hidden'}>
                <Tooltip title="Save Changes">
                  <IconButton type="submit" color="primary" size="large">
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel Changes">
                  <IconButton
                    color="secondary"
                    onClick={(event) => {
                      setCancelEdit(true);
                      var i = reload + 1;
                      setReload(i);
                      setReadOnlyMode(true);
                    }}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Fragment>
  );
};

export default EmployeeInfo;
