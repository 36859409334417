import React, { Fragment, useState } from "react";

// Material-UI
import { styled } from "@mui/material/styles";
import { Button, Box, Grid, Card } from "@mui/material";

// Project Imports
import AuthLogin from "./auth-forms/AuthLogin";
import AuthRegister from "./auth-forms/AuthRegister";
import AuthResetPassword from "./auth-forms/AuthResetPassword";

// Third-party
import Tabs from "rc-tabs";
import "rc-tabs/assets/index.css";

// ===========================|| AUTH - LOGIN |============================ //

const AuthWrapper = styled("div")(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : theme.palette.primary.light,
  minHeight: "100vh",
}));

// Logo Responsive Box
const LogoResponsiveBox = styled("div")({
  img: {
    width: "auto",
    "@media (min-width:1200px)": {
      width: 240,
    },
    "@media (min-width:992px) and (max-width:1199px)": {
      width: 240,
    },
    "@media (min-width:768px) and (max-width:991px)": {
      width: 240,
    },
    "@media (min-width:576px) and (max-width:767px)": {
      width: 240,
    },
    "@media (min-width:480px) and (max-width:575px)": {
      width: 220,
    },
    "@media (max-width:479px)": {
      width: 220,
    },
  },
});

const Authentication = () => {
  // Logos
  const ErConn = "/assets/images/erconn.png";
  const IWA = "/assets/images/logo.png";

  // State - Forgot Password
  const [forgotPasswordStep, setForgotPasswordStep] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  // State -Active Tab
  const [activeTab, setActiveTab] = useState("1");

  // Handle Forgot Password
  const handleForgotPassword = () => {
    setForgotPasswordStep(!forgotPasswordStep);
    setShowPassword(!showPassword);
  };

  // Handle Back to Sign In
  const handleBackToSignIn = () => {
    setActiveTab("1");
  };

  // Tab items
  const items = [
    {
      key: "1",
      label: "Sign In",
      children: <AuthLogin />,
    },
    {
      key: "2",
      label: "Create Account",
      children: <AuthRegister handleBackToSignIn={handleBackToSignIn} />,
    },
  ];

  return (
    <Fragment>
      <AuthWrapper>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "100vh", backgroundColor: "#fff" }}
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: `calc(100vh - 65px)` }}
            >
              <LogoResponsiveBox>
                <img
                  src={ErConn}
                  alt="ERConn Logo"
                  style={{ height: "120px" }}
                />
              </LogoResponsiveBox>
              <Card
                sx={{
                  maxWidth: { xs: 400, lg: 560 },
                  margin: { xs: 1.5, md: 3 },
                  "& > *": {
                    flexGrow: 1,
                    flexBasis: "100%",
                  },
                  border: "1px solid #008521",
                  borderTop: "3px solid #008521",
                }}
              >
                <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12}>
                      {forgotPasswordStep ? (
                        <AuthResetPassword
                          handleForgotPassword={handleForgotPassword}
                        />
                      ) : (
                        <Tabs
                          tabPosition="top"
                          items={items}
                          defaultActiveKey="1"
                          activeKey={activeTab}
                          onChange={setActiveTab}
                          className="no-border  equal-width-tabs"
                          style={{ color: "#008521" }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 1 }} />
                  {showPassword && activeTab === "1" && (
                    <Grid item xs={12}>
                      <Grid
                        item
                        container
                        direction="column"
                        alignItems="center"
                        xs={12}
                      >
                        <Button
                          fullWidth
                          size="large"
                          onClick={handleForgotPassword}
                          variant="subtitle1"
                          sx={{ textDecoration: "none", color: "#008521" }}
                        >
                          Forgot your password?
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Card>
              <LogoResponsiveBox>
                <img
                  src={IWA}
                  alt="IWA Logo"
                  style={{ width: "auto", height: "80px" }}
                />
              </LogoResponsiveBox>
            </Grid>
          </Grid>
        </Grid>
      </AuthWrapper>
    </Fragment>
  );
};

export default Authentication;
