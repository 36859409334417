import { useCallback } from 'react';

// React Query
import { useQuery } from '@tanstack/react-query';

// AWS Amplify - Storage
import { list } from 'aws-amplify/storage';

const useProtectedFileList = (account, companyid, refresh) => {
  const fetchFiles = useCallback(async () => {
    if (!account || !companyid) {
      return;
    }

    try {
      var options = {
        level: 'protected',
        pageSize: 1000,
        listAll: true,
        path: `protected/${account.map((i) => i.cognito_identity_uuid)}/${companyid}/employer/`,
      };

      var fileList = await list(options);
      fileList = fileList.items.filter((file) => {
        return file.path.split('/').pop() !== '';
      });

      return fileList;
    } catch (error) {
      console.log(error);  
      throw error;
    }
  }, [account, companyid]);

  const { data, error, isFetching, isLoading } = useQuery({
    queryKey: ['protectedFileList', account, companyid, refresh],
    queryFn: fetchFiles,
    enabled: !!account && !!companyid,
    placeholderData: [],
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });

  return { files: data, isLoading, isFetching, error };
};

export default useProtectedFileList;
