import { useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet } from '../utils/amplifyInstance';

const useEmployeeInfoForm = (submitData, validate, props, employeelistid, cancelEdit, reload) => {
  const { accountid, companyid, authToken } = props;
  const [data, setData] = useState({});
  const [origData, setOrigData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      if (!accountid || !authToken) {
        return;
      }

      if (employeelistid) {
        try {
          // let response = await get("EmployerApiGw", "/employer/" + accountid + "/employeelist/" + employeelistid);
          const response = await handleGet({
            apiName: "EmployerApiGw",
            path: "/employer/" + accountid + "/employeelist/" + employeelistid,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          const { body } = await response;
          const json = await body.json();

          if (cancel) {
            return;
          }

          setOrigData(json.items[0]);
        } catch (e) {
          console.log(e);
          setOrigData({});
        }
      } else {
        setOrigData({
          employeelistid: 0,
          companyid: companyid*1,
          id: "",
          sin: "",
          surname: "",
          givennames: "",
          address1: "",
          address2: "",
          city: "",
          province: "BC",
          postalcode: "",
          phone: "",
          emailaddress: "",
          birthdate: "",
          sex: "",
          hiredate: "",
          employmenttype: "",
          jobtitle: ""
        });
      }

      setErrors({});
      setIsSubmitting(false);
    };

    fetchData();

    return () => {
      cancel = true;
    }
  }, [reload, accountid, companyid, authToken, employeelistid]);

  useEffect(() => {
    if (Object.entries(origData).length > 0) {

      setData(origData);
      setIsLoading(false);
    }
  }, [origData]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setIsSubmitting(false);

      if (!cancelEdit) {
        submitData();
        setOrigData(data);
      }
    }
  }, [errors, data, cancelEdit, isSubmitting, submitData]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(data));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();

    var value = "";

    const target = event.target;
    const name = target.name;
    if (name === "surname" || name === "givennames"
      || name === "address1" || name === "address2" || name === "city" || name === "postalcode"
      || name === "emailaddress") {
        value = target.value.toUpperCase();
    } else {
      if (name === "phone" ) {
        if (/^[0-9]*$/.test(target.value)) {
          value = target.value;
        }
      } else {
        value = target.value;
      }
    }

    setData(data => ({ ...data, [name]: value }));
  };

  const handleLOVChange = (name, value) => {
    setData({...data, [name]: value });
  };

  return {
    isLoading,
    handleChange,
    handleLOVChange,
    handleSubmit,
    origData,
    data,
    errors
  }
};

export default useEmployeeInfoForm;