import React, { useState, Fragment } from "react";

// AWS Amplify - API - Request Handler
import { handlePost } from "../../utils/amplifyInstance";

// Auth Session 
import { useSession } from "../../context/AuthSession";

// Material-UI
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Button
  } from '@mui/material';

// Third Party
import { useSnackbar } from 'notistack';

// Helper Functions
import useMakePADForm from "../../common/useMakePADForm";
import { validateMakePADInfo } from '../../common/ValidationRules';
import {
  toCurrency,
  LookupValues,
  isUndefined
} from "../../common/HelperFunctions";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";

// Icons
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';

// ===========================|| MAKE PAD ||=========================== //
function MakePAD(props) {
  const { authToken } = useSession();
  const { accountid, companyid, padagreementid, amountreadonly, setStatusChanged, setOpenPADDialog} = props;
  const { enqueueSnackbar } = useSnackbar();
  const [cancelEdit, setCancelEdit] = useState(false);

  const updateData = async () => {
    try {
      // const response = await post("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/payment/" + padagreementid,
      //   {
      //     body: [data]
      //   });
      const response = await handlePost({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/${companyid}/payment/${padagreementid}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [data]
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        enqueueSnackbar("Pre Authorized Debit created successfully!", {variant: "success"});
      }
      else {
        enqueueSnackbar(response.message, {variant: "error"});
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, {variant: "error"});
    }
  };

  const submitData = () => {
    if (!cancelEdit
      && window.confirm('Please confirm that you are authorizing a payment of ' + toCurrency(data.onetime_startamount) + ' CAD to be debited from your account on the next 5 business days. Once you have authorized this payment, it cannot be cancelled.')) {
      updateData();
      if (setStatusChanged) {
        setStatusChanged(true);
      }
      setOpenPADDialog(false);
    }
  };

  const hookProps = { ...props, authToken };

  const {isLoading, data, errors, handleChange, handleLOVChange, handleSubmit} = useMakePADForm(submitData, validateMakePADInfo, hookProps);

  return (
    <Fragment>
    {isLoading ? (
      <CustomLoadingScreen text="Loading Make a Payment" />
    ) : (
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12} zeroMinWidth>
            <Typography gutterBottom variant="subtitle1">
              Pay by Direct Debit
            </Typography>
            <Typography variant="body2" gutterBottom>
              Make a payment by debit from your bank account.
            </Typography>
            <br/>
          </Grid>
          <Grid item xs={12} sm={3} zeroMinWidth>
            <Typography
              variant="body2"
              noWrap
              sx={{paddingTop:"5px"}}
            >
              Payment reason:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} zeroMinWidth>
            <LookupValues
              accountid={accountid}
              name="payee"
              label=""
              value={data.payee}
              readOnlyMode={amountreadonly}
              authToken={authToken}
              onValueChange={handleLOVChange}
            />
          </Grid>
          <Grid item xs={12} sm={3} zeroMinWidth>
            <Typography
              variant="body2"
              noWrap
              sx={{paddingTop:"5px"}}
            >
              Description:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} zeroMinWidth>
            <TextField
              id="note"
              name="note"
              fullWidth
              value={data.note}
              onChange={handleChange}
              InputProps={{
                readOnly: amountreadonly,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} zeroMinWidth>
            <Typography
              variant="body2"
              noWrap
              sx={{paddingTop:"11px"}}
            >
              Payment options:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} zeroMinWidth>
            <Grid
              container
              item
              direction="row"
              zeroMinWidth
              sx={{marginTop:"6px"}}
            >
              <Typography
                variant="body2"
                noWrap
                sx={{
                  paddingRight: "5px",
                  paddingTop:"5px"
                }}
              >
                Debit amount:
              </Typography>
              <TextField
                required
                id="onetime_startamount"
                name="onetime_startamount"
                value={data.onetime_startamount}
                sx={{width: 115}}
                InputProps={{
                  readOnly: amountreadonly,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                inputProps={{style: {marginRight: "4px", textAlign:'right'}}}
                onChange={handleChange}
                error={!isUndefined(errors.onetime_startamount)}
                helperText={errors.onetime_startamount}
              />
              <Typography
                variant="body2"
                noWrap
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "5px",
                  paddingTop:"5px"
                }}
              >
                Payment date:
              </Typography>
              <TextField
                required
                id="onetime_startdate"
                name="onetime_startdate"
                value={data.onetime_startdate}
                disabled
                sx={{width: 115}}
                inputProps={{style: {marginRight: "4px", textAlign:'right'}}}
                FormHelperTextProps={{style: {textAlign:'right'}}}
                onChange={handleChange}
                error={!isUndefined(errors.onetime_startdate)}
                helperText={errors.onetime_startdate ? errors.onetime_startdate : "DD-MMM-YYYY"}
              />
            </Grid>
            <Grid
              item
              sx={{
                paddingBottom:"5px",
                paddingTop:"5px"
              }}
            />
          </Grid>
          <Grid container item sm={6} justifyContent="flex-start">
            <Button
              variant="contained"
              startIcon={<CancelIcon size="small"/>}
              color="secondary"
              onClick={(event) => {
                setOpenPADDialog(false);
                setCancelEdit(true);
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid container item sm={6} justifyContent="flex-end">
            <Button
              variant="contained"
              endIcon={<DoneIcon size="small"/>}
              type="submit"
              color="primary"
            >
              Make Payment
            </Button>
          </Grid>
        </Grid>
      </form>
    )}
    </Fragment>
    );
  }

  export default MakePAD;
