import { useState, useEffect } from "react";

// React Query
import { useQuery } from "@tanstack/react-query";

// AWS Amplify - API - Request Handler
import { handleGet } from "../utils/amplifyInstance";

const useUnreadMessageCount = (accountid, companyid, authToken) => {
  const [numUnread, setNumUnread] = useState(0);
  const [msgTimeOut, setMsgTimeOut] = useState(0);

  const fetchUnreadMessageCount = async () => {
    if (!accountid || !companyid || !authToken) {
      return;
    }

    try {
      const response = await handleGet({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/${companyid}/messages/unread`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const json = await body.json();
  
      const unreadMessageCount = json.items?.[0]?.unread ?? 0;
      setNumUnread(unreadMessageCount);
      return unreadMessageCount;

    } catch (error) {
      console.log(error);
      setNumUnread(0);
      throw error;
    }
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["unreadMessageCount", accountid, companyid, authToken],
    queryFn: fetchUnreadMessageCount,
    enabled: !!accountid && !!companyid && !!authToken,
  });

  useEffect(() => {
    if (!accountid || !companyid || !authToken) {
      return;
    }

    const intervalID = setInterval(() => {
      refetch();
      setMsgTimeOut(150000); // 2.5 minutes
    }, msgTimeOut);

    return () => clearInterval(intervalID);
  }, [accountid, companyid, authToken, msgTimeOut, refetch]);

  useEffect(() => {
    if (companyid) {
      setMsgTimeOut(0);
    }
  }, [companyid]);

  return { data, numUnread, error, isLoading };
};

export default useUnreadMessageCount;
