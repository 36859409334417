import React from 'react';

// Material-UI
import {
  Dialog,
  DialogContent
} from "@mui/material";

// Project Imports
import ChangePassword from "./ChangePassword";

function ChangePasswordDialog(props) {
  return (
    <div onClick={event => event.stopPropagation()}>
      <Dialog open={props.open} >
        <DialogContent>
          <ChangePassword {...props} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ChangePasswordDialog;