import { useState, useEffect } from 'react';

const useChangePassowrdForm = (submitValues, validate) => {
    const [values, setValues] = useState({oldPassword: "", newPassword: "", repeatPassword: "" });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            submitValues();
        }
    }, [errors, isSubmitting, submitValues]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    const handleChange = (event) => {
        event.persist();

        const {name, value} = event.target;
        setValues(values => ({...values, [name]: value}));
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    showPassword,
    handleClickShowPassword
  }
};

export default useChangePassowrdForm;