import React, { Fragment, useState, useEffect } from "react";

// Material-ui
import {
  Button,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

// Project imports
import UploadDropZone from "./UploadDropZone";
import TablePreview from "../../components/Table";
import CustomLoadingScreen from "../../../../ui-components/CustomLoadingScreen";

// Sub Components
import DownloadFileSample from "./DownloadFileSample";

// Steps
import SelectSheetFile from "../SelectSheetFile/SelectSheetFile";


const UploadFile = ({
  uploadFileData,
  setUploadFileData,
  workbookData,
  setWorkBookData,
  selectedSheet,
  setSelectedSheet,
  sheetNamesArray,
  rows,
  setRows,
  headerRowData,
  handleNext,
}) => {
  const [loading, setLoading] = useState(false);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  useEffect(() => {
    if (
      rows.length > 0 &&
      headerRowData.length > 0 &&
      Object.keys(uploadFileData).length > 0
    ) {
      setTableDataLoaded(true);
    } else {
      setTableDataLoaded(false);
    }
  }, [rows, headerRowData, uploadFileData]);

  return (
    <Fragment>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        UPLOAD FILE
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Please upload hours for the current pay period only.
      </Typography>
      {!Object.keys(uploadFileData).length > 0 && (
          <DownloadFileSample />
      )}
      {loading ? (
        <CustomLoadingScreen text="Loading Upload Table ..." />
      ) : (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {tableDataLoaded && sheetNamesArray.length > 0 ? (
                <Fragment>
                  <SelectSheetFile
                    workbookData={workbookData}
                    sheetNames={sheetNamesArray}
                    selectedSheet={selectedSheet}
                    setSelectedSheet={setSelectedSheet}
                  />
                  <Box sx={{ p: 1 }} />
                  <TablePreview
                    rows={rows}
                    setRows={setRows}
                    columns={headerRowData}
                  />
                </Fragment>
              ) : null}
            </Grid>
          </Grid>
        </Fragment>
      )}
      <Box sx={{ p: 1 }} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UploadDropZone
            setUploadFileData={setUploadFileData}
            setWorkBookData={setWorkBookData}
            setLoading={setLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              disabled={!Object.keys(uploadFileData).length > 0}
              sx={{ my: 1, ml: 1 }}
              type="submit"
              onClick={() => {
                handleNext();
              }}
            >
              Next
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UploadFile;
