import React from "react";
import ReactDOM from "react-dom/client";

// Sentry
import * as Sentry from "@sentry/react";

// Project Imports
import App from "./App.jsx";
import "./index.css";

Sentry.init({
  dsn: "https://f9dbeaee2a51041e8be3649c10a5e4d9@o4507625099165696.ingest.us.sentry.io/4507625101000704",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ["https://employer.iwafibp.ca"],
  // Performance Monitoring
  tracesSampleRate: 1.0, 
  // Session Replay
  replaysSessionSampleRate: 1.0, 
  replaysOnErrorSampleRate: 1.0, 
  environment: "production",
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
