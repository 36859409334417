// Materia UI
import { Box, Container, Divider } from "@mui/material";

// Helper Functions
import { toDecimal, toCurrency } from "../../../../../common/HelperFunctions";

// Project Imports
import ContributionGross from "./ContributionGross";
import ContributionItem from "./ContributionItem";
import ContributionGrandTotal from "./ContributionGrandTotal";
import CustomLoadingScreen from "../../../../../ui-components/CustomLoadingScreen";

const ContributionSummarySection = ({
  summaryData,
  totalHours,
  processedRows,
  isLoading,
}) => {
  const totalHoursPension = totalHours?.penhrs ? totalHours.penhrs : 0;
  const totalHoursLTD = totalHours?.ltdhrs ? totalHours.ltdhrs : 0;

  // Decimal Format for total Pension Plan Hours
  const totalPensionPlanHours = totalHoursPension
    ? toDecimal(totalHoursPension)
    : toDecimal(0);

  // Decimal Format for total LTD Plan Hours
  const totalLtdPlanHours = totalHoursLTD
    ? toDecimal(totalHoursLTD)
    : toDecimal(0);

  // Sum of total hours for pension and LTD
  const totalHoursSum =
    totalHoursPension + totalHoursLTD > 0
      ? toDecimal(totalHoursPension + totalHoursLTD)
      : toDecimal(0);

  return (
    <>
      {isLoading ? (
        <CustomLoadingScreen text="Loading Statement Summary" />
      ) : (
        <>
          {/* Create map for the  summaryData */}
          {summaryData.map((item) => (
            <Container maxWidth="lg" key={item.statementid}>
              <Box sx={{ m: 0.5 }} />
              <ContributionGross
                description={"Number of employees in the period"}
              />
              <ContributionItem
                title="Number of employees"
                value={processedRows ? processedRows : "0"}
              />
              <Box sx={{ m: 1 }} />
              <Divider />
              <Box sx={{ m: 0.5 }} />
              <ContributionGross
                description={"Total hours reported this period"}
              />
              <Box sx={{ m: 0.5 }} />
              <ContributionItem
                title="Total hours for pension plan"
                value={totalPensionPlanHours}
              />
              <ContributionItem
                title="Total hours for pension LTD plan"
                value={totalLtdPlanHours}
              />
              <Box display="flex" justifyContent="flex-end">
                <Box
                  border={0.2}
                  borderColor="primary.main"
                  width={106}
                  bgcolor="primary.main"
                />
              </Box>
              <ContributionGrandTotal amount={totalHoursSum} />
              <Box sx={{ m: 0.5 }} />
              <Divider />
              <Box sx={{ m: 0.5 }} />
              <ContributionGross
                description={"Total contributions for all the plans"}
              />
              <Box sx={{ m: 0.5 }} />
              <ContributionItem
                title="Total contributions for pension plan"
                value={
                  totalHoursPension * item?.pensionrate > 0
                    ? toCurrency(totalHoursPension * item?.pensionrate)
                    : toCurrency(0)
                }
              />
              <ContributionItem
                title="Total contributions for LTD plan"
                value={
                  totalHoursLTD * item?.ltdrate > 0
                    ? toCurrency(totalHoursLTD * item?.ltdrate)
                    : toCurrency(0)
                }
              />
              <ContributionItem
                title="Total contributions for H&S plan"
                value={
                  (totalHoursPension > 0 ? totalHoursPension : totalHoursLTD) *
                    item?.hsrate >
                  0
                    ? toCurrency(
                        (totalHoursPension > 0
                          ? totalHoursPension
                          : totalHoursLTD) * item?.hsrate
                      )
                    : toCurrency(0)
                }
              />
              <Box sx={{ m: 0.5 }} />
              <Box display="flex" justifyContent="flex-end">
                <Box
                  border={0.2}
                  borderColor="primary.main"
                  width={106}
                  bgcolor="primary.main"
                />
              </Box>
              <Box sx={{ m: 0.5 }} />
              <ContributionGrandTotal
                amount={
                  totalHoursPension * item?.pensionrate +
                    totalHoursLTD * item?.ltdrate +
                    (totalHoursPension > 0
                      ? totalHoursPension
                      : totalHoursLTD) *
                      item?.hsrate >
                  0
                    ? toCurrency(
                        totalHoursPension * item?.pensionrate +
                          totalHoursLTD * item?.ltdrate +
                          (totalHoursPension > 0
                            ? totalHoursPension
                            : totalHoursLTD) *
                            item?.hsrate
                      )
                    : toCurrency(0)
                }
              />
            </Container>
          ))}
        </>
      )}
    </>
  );
};

export default ContributionSummarySection;
