import React, {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
  useReducer,
} from "react";

// PropTypes
import PropTypes from "prop-types";

// Amplify Auth
import {
  signIn,
  signUp,
  signOut,
  getCurrentUser,
  confirmSignUp,
  confirmSignIn,
  resendSignUpCode,
  resetPassword,
  updatePassword,
  confirmResetPassword,
} from "aws-amplify/auth";

// ===========================|| AUTH CONTEXT ||=========================== //

const UserContext = createContext({});

const AuthContext = ({ children }) => {
  const [route, setRoute] = useState("unauthenticated");
  const [user, setUser] = useState(null);

  var ActionType;

  (function (ActionType) {
    ActionType["INITIALIZE"] = "INITIALIZE";
    ActionType["SIGN_IN"] = "SIGN_IN";
    ActionType["SIGN_OUT"] = "SIGN_OUT";
  })(ActionType || (ActionType = {}));

  const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
  };

  const handlers = {
    INITIALIZE: (state, action) => {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    },
    SIGN_IN: (state, action) => {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    },

    SIGN_OUT: (state) => ({
      ...state,
      isAuthenticated: false,
      user: null,
    }),
  };

  const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

  // Reducer - User
  const [state, dispatch] = useReducer(reducer, initialState);

  // Initialize  - Amplify v6
  const initialize = useCallback(async () => {
    try {
      const user = await getCurrentUser();

      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: true,
          user: {
            username: user.username,
          },
        },
      });
    } catch (error) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch, ActionType.INITIALIZE]);

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Handle Sign Out - Amplify v6
  const handleSignOut = useCallback(async () => {
    try {
      await signOut({ global: true });
      dispatch({ type: ActionType.SIGN_OUT });
    } catch (error) {
      console.log("error sign out: ", error);
    }
  }, [dispatch, ActionType.SIGN_OUT]);

  // Handle Resend Sign Up - Amplify v6
  const resendSignUp = useCallback(async (username) => {
    try {
      await resendSignUpCode(username);
    } catch (error) {
      console.log("error resending code ", error);
      throw new Error(`${error}`);
    }
  }, []);

  // Handle Sign In - Amplify v6
  const handleSignIn = useCallback(
    async (formData) => {
      try {
        const payload = {
          ...formData,
          options: {
            authFlowType: "CUSTOM_WITH_SRP",
          },
        };

        const user = await signIn(payload);

        if (
          user?.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
        ) {
          setRoute("confirmsignin");
          dispatch({
            type: ActionType.SIGN_IN,
            payload: { user },
          });
        } else if (
          user?.nextStep?.signInStep ===
          "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
        ) {
          setRoute("completenewpassword");
        } else if (user?.signInUserSession) {
          setRoute("authenticated");
        }
      } catch (err) {
        if (err.code === "UserNotConfirmedException") {
          resendSignUp(formData.username);
          setRoute("confirmsignin");
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
        } else if (err.code === "PasswordResetRequiredException") {
          setRoute("resetpassword");
        } else if (err.code === "NotAuthorizedException") {
          console.log("error sign in", err);
        } else if (err.code === "UserNotFoundException") {
          console.log("error sign in", err);
        } else {
          console.log("error sign in", err);
        }
        throw err;
      }
    },
    [dispatch, ActionType.SIGN_IN, resendSignUp]
  );

  // Handle Confirm SignIn - Amplify v6
  const handleConfirmSignIn = useCallback(async ({ challengeResponse }) => {
    try {
      const { isSignedIn, nextStep } = await confirmSignIn({
        challengeResponse,
      });

      if (isSignedIn) {
        setRoute("authenticated");
        return true;
      } else {
        console.log("Next step required:", nextStep.signInStep);
        throw new Error("Invalid verification code");
      }
    } catch (err) {
      console.log("error confirming sign in", err);
      throw new Error(`${err}`);
    }
  }, []);

  // Handle Sign Up - Amplify v6
  const completeNewPassword = useCallback(async (newPassword, user) => {
    try {
      const { isSignedIn, nextStep } = await confirmSignIn({
        challengeResponse: newPassword,
        options: {
          userAttributes: user.requiredAttributes,
        },
      });

      if (isSignedIn) {
        setRoute("authenticated");
        setUser(user);
      } else {
        console.log("Next step required:", nextStep.signInStep);
      }
    } catch (err) {
      console.log("error updating password", err);
    }
  }, []);

  // Handle Sign Up - Amplify v6
  const handleSignUp = useCallback(
    async ({ username, password, email, website, phone_number, validationData }) => {
      try {
        const { isSignUpComplete } = await signUp({
          username,
          password,
          options: {
            userAttributes: {
              email,
              phone_number,
              website,
            },
            validationData,
          },
        });

        if (isSignUpComplete) {
          setRoute("unauthenticated");
        } else {
          return;
        }
      } catch (error) {
        setRoute("unauthenticated");
        setUser(null);
        throw new Error(`${error}`);
      }
    },
    []
  );

  // Handle Confirm Sign Up - Amplify v6
  const handleConfirmSignUp = useCallback(
    async (username, confirmationCode) => {
      try {
        await confirmSignUp(username, confirmationCode);
      } catch (error) {
        console.log("error confirming sign up", error);
        throw new Error(`${error}`);
      }
    },
    []
  );

  // Handle Change Password - Amplify v6
  const handleChangePassword = useCallback(async (oldPassword, newPassword) => {
    try {
      await updatePassword(oldPassword, newPassword);
    } catch (error) {
      console.log("error changing password", error);
      throw new Error(`${error}`);
    }
  }, []);

  // Handle Reset Password - Amplify v6
  const handleResetPassword = useCallback(async (username) => {
    try {
      await resetPassword(username);
    } catch (error) {
      console.log("error forgot password", error);
      throw new Error(`${error}`);
    }
  }, []);

  // Handle Confirm Reset Password - Amplify v6
  const handleConfirmResetPassword = useCallback(
    async (username, newPassword, confirmationCode) => {
      try {
        await confirmResetPassword({ username, confirmationCode, newPassword });
      } catch (error) {
        console.log("error confirming reset password", error);
        if (error === "CodeMismatchException: Invalid verification code provided, please try again.") {
          throw new Error("Invalid verification code. Please check your email and try again.");
        } else if (error === "PasswordHistoryPolicyViolationException: Password has previously been used") {
          throw new Error("Password has previously been used. Please try a different password.");
        } else {
          throw new Error(`${error}`);
        }
      }
    },
    []
  );

  return (
    <UserContext.Provider
      value={{
        ...state,
        user,
        route,
        handleSignIn,
        handleConfirmSignIn,
        handleSignUp,
        handleConfirmSignUp,
        resendSignUp,
        completeNewPassword,
        handleChangePassword,
        handleResetPassword,
        handleConfirmResetPassword,
        handleSignOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

AuthContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => useContext(UserContext);

export default AuthContext;
