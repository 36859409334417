import React, { Fragment } from "react";

// Auth Context
import { useUser } from "../../../../context/AuthContext";

// Home 
import Home from "../../../home/Home";

// ===========================|| AUTHENTICATED - LOGIN |============================ //

const Authenticated = () => {
  const { route } = useUser();

  return (
    <Fragment>
        {route === "authenticated" && <Home />} 
    </Fragment>
  );
};

export default Authenticated;
