import React from "react";

// React Router
import { BrowserRouter } from "react-router-dom";

// React Query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// React Query Devtools
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Custom CSS
import "./App.css";

// AWS Amplify - UI React
import { Authenticator } from "@aws-amplify/ui-react";

// AWS Amplify
import { Amplify } from "aws-amplify";

// Notistack
import { SnackbarProvider } from "notistack";

// Material UI
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./theme/IWATheme";


// Routes
import Routes from "./routes";

// Context Provider
import AuthContext from "./context/AuthContext";

// Session Provider
import { SessionProvider } from "./context/AuthSession";

// AWS config
import awsConfig from "./aws-config";


// Styles
import {
  ContainerLayout,
  ContentLayout,
} from "./components/authentication/Layout";

// AWS Amplify Configuration
Amplify.configure(awsConfig);

// React Query Client
const queryClient = new QueryClient();

const App = () => {
  return (
    <ContainerLayout>
      <ContentLayout>
        <ThemeProvider theme={theme}>
          <AuthContext>
            <Authenticator.Provider>
              <SessionProvider>
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider maxSnack={3}>
                      <CssBaseline />
                      <BrowserRouter>
                        <Routes />
                      </BrowserRouter>
                    </SnackbarProvider>
                  <ReactQueryDevtools
                    initialIsOpen
                    position="right"
                    buttonPosition="bottom-right"
                  />
                </QueryClientProvider>
              </SessionProvider>
            </Authenticator.Provider>
          </AuthContext>
        </ThemeProvider>
      </ContentLayout>
    </ContainerLayout>
  );
};

export default App;
