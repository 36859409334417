import React from "react";

// Material UI
import { Box, Card } from "@mui/material";

// Helper Functions
import { toDecimal } from "../../../../../common/HelperFunctions";

const ContributionWidgetSummary = ({ title, total, unit, sx }) => {
  return (
    <Card
      sx={{
        p: 2,
        pl: 3,
        display: "flex",
        alignItems: "center",
        height: "120px",
        ...sx,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ color: "text.secondary", typography: "subtitle2" }}>
          {title}
        </Box>
        <Box sx={{ my: 1.5, typography: "h5" }}>
          {toDecimal(total)} <span style={{ fontSize: "0.8em" }}>{unit}</span>
        </Box>
      </Box>
    </Card>
  );
};

export default ContributionWidgetSummary;
