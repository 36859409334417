import React from "react";

// Material UI
import { Box, Tab, Card, CardHeader, Typography, Grid } from "@mui/material";

// Auth Context
import { useSession } from "../../../../../context/AuthSession";

// Hooks
import useSummaryTabs from "../../../hooks/useSummaryTabs";
import { useDiscardedTotalHours } from "../../../hooks/useDiscardedTotalHours";

// Project imports
import ContributionEmployeeItem from "./ContributionEmployeeItem";
import ContributionHoursInitial from "./ContributionHoursInitial";
import ContributionEmployeeDiscarded from "./ContributionEmployeeDiscarded";
import ContributionWidgetSummary from "./ContributionWidgetSummary";

// UI Components
import SummaryTabs from "../../../components/Tabs/SummaryTabs";
import InfoTooltip from "../../../components/Info/InfoTooltip";
import CustomLoadingScreen from "../../../../../ui-components/CustomLoadingScreen";

// Third party libraries
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const SUMMARY_TABS = [
  { value: "hours", label: "Total Hours" },
  { value: "employeeDetails", label: "Revised Hours" },
  { value: "employeeDiscarded", label: "Employee's discarded" },
];

const ContributionTabDetails = ({
  accountid,
  companyid,
  statementid,
  payPeriodStart,
  payPeriodEnd,
  totalHours,
  title,
  subtitle,
  list,
  discrepancyPenhrs,
  discrepancyLtdhrs,
  discrepancyTotal,
  discardedEmployees,
  isDiscrepRecordsLoading,
  error,
  ...other
}) => {
  // Auth Context
  const { authToken } = useSession();

  // Summary Tabs
  const tabs = useSummaryTabs("hours");

  // Employee's Discarded Total Hours = Hook
  const {
    totalDiscardedHoursPension,
    totalDiscardedHoursLTD,
    totalDiscardedHours,
    isDiscardedHoursLoading,
    isDiscardedError,
  } = useDiscardedTotalHours(accountid, companyid, authToken, statementid);

  const tabContents = {
    hours: (
      <div>
        <Box
          sx={{
            pt: 1,
            gap: 3,
            minWidth: 370,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ContributionHoursInitial
            version="initial"
            accountid={accountid}
            companyid={companyid}
            authToken={authToken}
            statementid={statementid}
            payPeriodStart={payPeriodStart}
            payPeriodEnd={payPeriodEnd}
            finalPenHrs={totalHours.penhrs}
            finalLtdHrs={totalHours.ltdhrs}
          />
        </Box>
      </div>
    ),
    employeeDetails: (
      <div>
        <>
          {isDiscardedHoursLoading ? (
            <CustomLoadingScreen text="Loading employee revised total hours ..." />
          ) : isDiscardedError ? (
            <Typography variant="subtitle">
              Error: {isDiscardedError}
            </Typography>
          ) : (
            <Box sx={{ m: 1 }}>
              {list.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <ContributionWidgetSummary
                      title="Total Pension Hours"
                      total={discrepancyPenhrs}
                      unit="hrs"
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderLeft: "5px solid #008522",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ContributionWidgetSummary
                      title="Total LTD Hours"
                      total={discrepancyLtdhrs}
                      unit="hrs"
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderLeft: "5px solid #008522",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ContributionWidgetSummary
                      title="Total Discrepancy Hours"
                      total={discrepancyTotal}
                      unit="hrs"
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderLeft: "5px solid #008522",
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </>
        <>
          {isDiscrepRecordsLoading ? (
            <Typography variant="subtitle">Loading...</Typography>
          ) : error ? (
            <Typography variant="subtitle">Error: {error}</Typography>
          ) : (
            <>
              <Box
                sx={{
                  p: 3,
                  gap: 3,
                  minWidth: 370,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#d5f2dc",
                }}
              >
                <Box
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <Typography
                    variant="subtitle"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#000",
                      p: 0.5,
                      mt: -1,
                    }}
                    noWrap
                  >
                    Employee Name
                  </Typography>

                  <div>
                    <Box
                      sx={{
                        mb: 1,
                        gap: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          mr: 0.5,
                          ml: 0.5,
                          borderRadius: 1,
                          p: 0.5,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Pension Hours
                        <InfoTooltip
                          initialHours={"Initial Pension Hours"}
                          finalHours={"Final Pension Hours"}
                        />
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          mr: 0.5,
                          ml: 0.5,
                          borderRadius: 1,
                          p: 0.5,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        LTD Hours
                        <InfoTooltip
                          initialHours={"Initial LTD Hours"}
                          finalHours={"Final LTD Hours"}
                        />
                      </Box>
                    </Box>
                  </div>
                </Box>
                {list.length === 0 ? (
                  <Typography variant="subtitle">No discrepancies detected.</Typography>
                ) : (
                  list.filter((item, index, self) => index === self.findIndex((t) => t.employeelistid === item.employeelistid)).map((item, index) => (
                    <ContributionEmployeeItem
                      key={item.employeelistid}
                      index={index}
                      item={item}
                    />
                  ))
                )}
              </Box>
            </>
          )}
        </>
      </div>
    ),
    employeeDiscarded: (
      <div>
        <>
          {isDiscardedHoursLoading ? (
            <CustomLoadingScreen text="Loading employee discarded Total Hours ..." />
          ) : isDiscardedError ? (
            <Typography variant="subtitle">
              Error: {isDiscardedError}
            </Typography>
          ) : (
            <Box sx={{ m: 1 }}>
              {discardedEmployees.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <ContributionWidgetSummary
                      title="Total Pension Hours"
                      total={totalDiscardedHoursPension}
                      unit="hrs"
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderLeft: "5px solid #008522",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ContributionWidgetSummary
                      title="Total LTD Hours"
                      total={totalDiscardedHoursLTD}
                      unit="hrs"
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderLeft: "5px solid #008522",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ContributionWidgetSummary
                      title="Total Discared Hours"
                      total={totalDiscardedHours}
                      unit="hrs"
                      sx={{
                        backgroundColor: "#f0f0f0",
                        borderLeft: "5px solid #008522",
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </>
        <>
          {isDiscrepRecordsLoading ? (
            <CustomLoadingScreen text="Loading discarded employees..." />
          ) : error ? (
            <Typography variant="subtitle">Error: {error}</Typography>
          ) : (
            <>
              <Box
                sx={{
                  p: 3,
                  gap: 3,
                  minWidth: 370,
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#d5f2dc",
                }}
              >
                <Box
                  sx={{
                    gap: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <Typography
                    variant="subtitle"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      color: "#000",
                      p: 0.5,
                      mt: -1,
                    }}
                    noWrap
                  >
                    Employee Name
                  </Typography>

                  <div>
                    <Box
                      sx={{
                        mb: 1,
                        gap: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          mr: 0.5,
                          ml: 0.5,
                          borderRadius: 1,
                          p: 0.5,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Pension Hours
                        <InfoTooltip
                          initialHours={"Initial Pension Hours"}
                          finalHours={"Final Pension Hours"}
                        />
                      </Box>
                      <Box
                        component="span"
                        sx={{
                          mr: 0,
                          ml: 0.5,
                          borderRadius: 1,
                          p: 0.5,
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        LTD Hours
                        <InfoTooltip
                          initialHours={"Initial LTD Hours"}
                          finalHours={"Final LTD Hours"}
                        />
                      </Box>
                    </Box>
                  </div>
                </Box>
                {discardedEmployees.length === 0 ? (
                  <Typography variant="subtitle">No discrepancies detected.</Typography>
                ) : (
                    discardedEmployees.filter((item, index, self) => index === self.findIndex((t) => t.employeelistid === item.employeelistid)).map((item, index) => (
                    <ContributionEmployeeDiscarded
                      key={item.employeelistid}
                      index={index}
                      item={item}
                    />
                  ))
                )}
              </Box>
            </>
          )}
        </>
      </div>
    ),
  };

  const tabRenderer = (
    <SummaryTabs
      value={tabs.value}
      onChange={tabs.handleChange}
      variant="fullWidth"
      tabSlotProps={{
        indicator: {
          borderRadius: 2,
          boxShadow: "rgba(0, 0, 0, 0.12)",
        },
        tab: { p: 2.5 },
      }}
      sx={{
        borderRadius: 3,
        backgroundColor: "#f5f5f5",
      }}
    >
      {SUMMARY_TABS.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={
            <Box
              sx={{
                mb: 0,
                gap: 0.5,
                display: "flex",
                fontWeight: "bold",
                alignItems: "center",
                typography: "subtitle",
                color: "#000",
              }}
            >
              {tab.label}
            </Box>
          }
        />
      ))}
    </SummaryTabs>
  );

  return (
    <Card {...other}>
      <CardHeader
        title={
          <Box
            sx={{
              gap: 1,
              display: "flex",
              alignItems: "center",
              typography: "h6",
              fontWeight: "bold",
            }}
          >
            {title}
          </Box>
        }
        subheader={subtitle}
        sx={{ mb: 0 }}
      />
      {tabRenderer}
      <SimpleBarReact style={{ maxHeight: 550 }}>
        {tabContents[tabs.value]}
      </SimpleBarReact>
    </Card>
  );
};

export default ContributionTabDetails;
