import React, { useState } from "react";

// Material UI
import Paper from "@mui/material/Paper";

// devexpress grid components
import { PagingState, CustomPaging } from "@devexpress/dx-react-grid";

// devexpress grid material-ui
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";

const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: "bold",
      fontSize: "13px",
    }}
  >
    {children}
  </TableHeaderRow.Content>
);

function hasEmptyObject(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === undefined || arr[i] === null || arr[i] === "") {
      arr[i] = { title: `Empty Column ${i}`, name: `Empty Column ${i}` };
    }
  }
  return arr;
}

const TablePreview = ({ rows, columns }) => {
  const filteredColumns = hasEmptyObject(columns);

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageSizes] = useState([25, 50, 100, 1000]);

  // Pagination Functions
  const getCurrentPageRows = () => {
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return rows.slice(start, end);
  };

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const displayRows = getCurrentPageRows();

  return (
    <Paper>
      <Grid rows={displayRows} columns={filteredColumns}>
        <Table />
        <TableHeaderRow contentComponent={TableHeaderContent} />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={changeCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={changePageSize}
        />
        <CustomPaging totalCount={Math.ceil(rows.length)} />
        <PagingPanel pageSizes={pageSizes} />
      </Grid>
    </Paper>
  );
};

export default TablePreview;
