import React from 'react';

// React Router
import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import MainRoutes from './MainRoutes';

// Error and Maintenance Pages
import Page403 from '../components/error-pages/403';
import MaintenancePage from '../components/maintenance-page/maintenance';

export default function EmployerAppRoutes() {
  return useRoutes([
    { path: '*', element: <Page403 /> },
    { path: '/maintenance', element: <MaintenancePage /> },
     AuthenticationRoutes, MainRoutes]);
}
