import React, { Fragment } from "react";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Hook
import useCompanyInformation from "../../hooks/useCompanyInformation";

// Material-UI
import { Box, Grid, TextField } from "@mui/material";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";
import ErrorBox from "../../ui-components/NotificationErrorBox";

function CompanyInformation(props) {
  const { authToken } = useSession();
  const { accountid, companyid, setOnlyLTD } = props;

  const { data, isLoading, error } = useCompanyInformation(
    accountid,
    companyid,
    authToken,
    setOnlyLTD
  );

  if (isLoading) {
    return <CustomLoadingScreen text="Loading Company Information" />;
  }

  if (error) {
    return <ErrorBox message="Failed to fetch company information" />;
  }

  return (
    <Fragment>
      {data && (
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={1}>
              <TextField
                fullWidth
                id="companynumber"
                name="companynumber"
                label="Number"
                value={data.companynumber || ""}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                id="companyname"
                name="companyname"
                label="Company"
                value={data.companyname || ""}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                id="divisionname"
                name="divisionname"
                label="Division"
                value={data.divisionname || ""}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                fullWidth
                id="unionlocal"
                name="Union Local"
                label="Union Local"
                value={data.unionlocal || ""}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                id="payrollschedule"
                name="payrollschedule"
                label="Payroll Schedule"
                value={data.payrollschedule || ""}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                id="hwplan"
                name="hwplan"
                label="H&W Plan"
                value={data.hwplan || ""}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
}

export default CompanyInformation;
