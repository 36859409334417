import React, { Fragment, useState, useMemo, useEffect, useCallback, useRef } from 'react';

// Material-ui
import { Box, Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Hooks
import useDeleteSelectedRows from './hooks/useDeleteSelectedRows';
import useDefaultWorkStatusEndDate from './hooks/useDefaultWorkStatusEndDate';
import useEmployeeValidationToggle from './hooks/useEmployeeValidationToggle';
import useEmployeeUpdateNameConfirmation from './hooks/useEmployeeUpdateNameConfirmation';
import useErrorDetection from './hooks/useErrorDetection';
import useWarningDetection from './hooks/useWarningDetection';

// Project Imports
import TableValidation from './TableValidation';
import ErrorDetectedCollapse from './components/ErrorDetectedCollapse';
import ErrorStatementPeriodCollapse from './components/ErrorStatementPeriodCollapse';
import EmployerNameColumnCard from './components/EmployeeNameColumnCard';
import DiscardSelectedRowButton from './components/DiscardSelectedRowButton';
import DefaultEndDateButton from './components/DefaultEndDateButton';
import SwitchErrorFilter from './components/SwitchErrorFilter';
import SwitchWarningFilter from './components/SwitchWarningFilter';

// UI Components
import GuideCard from '../../../../ui-components/GuideCard';

// Utils
import { fields } from '../../fields';
import { addErrorsAndRunHooks } from './utils/dataMutations';
import { generateColumns } from './utils/generateColumns';

// Error - Data Correction
const contentErrorItems = [
  {
    contentTitle: 'Data Correction',
    contentDescription:
      'Please correct the data indicated in red. Hover over the error for an explanation.',
  },
  {
    contentTitle: 'Data Not Required',
    contentDescription:
      ' If an entire row is red, this means the data is not required. Please delete by selecting the check box located on the left side of the row and click the button below: “Discard selected rows”.',
  },
];

// Warninig - Data Highlight
const contentWarningItems = [
  {
    contentTitle: 'Employee Status',
    contentDescription:
      'Rows that are highlighted in yellow are for employees with an invalid status end-date or no hours reported. Please change their status if they are not currently active or change their status end-date.',
  },
];

// Info - Instructions
const contentInfoItems = [
  {
    contentTitle: 'Errors Toggle',
    contentDescription:
      'To quickly access errors, use the toggle switch located below: “Show only rows with errors”.',
  },
  {
    contentTitle: 'Warnings Toggle',
    contentDescription:
      'To quickly access warnings, use the toggle switch located below: “Show only rows with warnings”.',
  },
  {
    contentTitle: 'Set Default End Date',
    contentDescription:
      'If you do not know the status end date, check box(es) on the left and click “Default end date” button at top right. This will automatically enter your billing statement end date.',
  },
];

// Main Component
const ValidationFile = ({
  validateData,
  accountid,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  statementRow,
  handleNext,
  handleBack,
  errorIndex,
  setSubmitData,
  setDiscardedRows,
  setDeletedRows,
}) => {
  // Get the previous data from the previous step and add errors and unique index.
  const [data, setData] = useState(
    useMemo(
      () => addErrorsAndRunHooks(validateData, fields),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  );

  // Theme
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const stackAlignment = isSmallScreen ? 'flex-start' : 'center';
  const stackDirection = isSmallScreen ? 'column' : 'row';

  // Scroll to the card ref
  const scrollToRef = useRef(null);

  // Selection tracking the current selected rows.
  const [selection, setSelection] = useState([]);

  // Employee List ID
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null);

  // Employee Full Name - Excel Data
  const [employeeFullName, setEmployeeFullName] = useState({
    employeeGivenName: '',
    employeeSurname: '',
  });

  // Employee Full Name - Database Data
  const [employeeDatabaseFullName, setEmployeeDatabaseFullName] = useState({
    employeeDatabaseGivenName: '',
    employeeDatabaseSurName: '',
  });

  // Filter by errors.
  const [filterByErrors, setFilterByErrors] = useState(false);

  // Filter by warnings.
  const [filterByWarnings, setFilterByWarnings] = useState(false);

  // Show collapse when there is an error in the data.
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);

  // Error Collapse Ref
  const errorCollapseRef = useRef(null);

  // Track switch changes for filters.
  const [switchChanges, setSwitchChanges] = useState(false);

  // Scroll to the card ref
  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView();
    }
  }, []);

  // Control Pagination filter by errors and warnings
  useEffect(() => {
    let cancel = false;

    const trackFilterStatus = () => {
      if (cancel) {
        return;
      }

      if (!filterByErrors && !filterByWarnings) {
        setSwitchChanges(false);
      } else {
        setSwitchChanges(filterByErrors || filterByWarnings);
      }
    };

    trackFilterStatus();

    return () => {
      cancel = true;
    };
  }, [filterByErrors, filterByWarnings]);

  // Update the Data with new errors.
  const updateData = useCallback(
    (rows) => {
      setData(addErrorsAndRunHooks(rows, fields));
    },
    [setData]
  );

  // Custom hook to delete selected rows from data.
  const { deleteSelectedRows } = useDeleteSelectedRows({
    data,
    selection,
    setData,
    setDeletedRows,
    setSelection,
    setCurrentEmployeeId,
    setEmployeeFullName,
  });

  // custom hook to detect errors in the data.
  const errorDetected = useErrorDetection(data);

  // custom hook to detect warnings in the data.
  const warningDetected = useWarningDetection(data);

  // Update Row Data when user changes the data in the table.
  const updateRow = useCallback(
    (rows, changedData) => {
      const changes = changedData?.indexes.reduce((acc, index) => {
        // when data is filted val !== actual index
        const realIndex = data.findIndex((value) => value.__index === rows[index].__index);
        acc[realIndex] = rows[index];
        return acc;
      }, {});
      const newData = Object.assign([], data, changes);
      updateData(newData);
    },
    [data, updateData]
  );

  // Workstatus that requires WorkstatusStart date and WorkstatusEnd date
  const requireStartAndEndDateWorkStatuses = ['S', 'W', 'L', 'I', 'ESA', 'E'];

  // Handle select default work status end date
  const { handleSelectDefaultWorkStatusEndDate } = useDefaultWorkStatusEndDate({
    data,
    selection,
    updateData,
    setSelection,
    requireStartAndEndDateWorkStatuses,
    statementRow,
  });

  // Columns Mapping
  const columns = useMemo(() => generateColumns(fields), []);

  // Table Filter
  const tableData = useMemo(() => {
    if (filterByErrors || filterByWarnings) {
      return data.filter((value) => {
        if (value?.__errors || value?.__warnings) {
          return (
            Object.values(value.__errors || {}).filter(
              (err) => filterByErrors && err.level === 'error'
            ).length +
            Object.values(value.__warnings || {}).filter(
              (warn) => filterByWarnings && warn.level === 'warning'
            ).length
          );
        }
        return false;
      });
    }
    return data;
  }, [data, filterByErrors, filterByWarnings]);

  // Handle Selection Change
  const handleSelectionChange = (newSelection) => {
    setSelection(newSelection);
  };

  // Function to scroll to the error collapse
  const scrollToErrorCollapse = () => {
    if (errorCollapseRef.current) {
      errorCollapseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Submit the data to the next step.
  const submitData = async () => {
    const calculatedData = data.reduce(
      (acc, value) => {
        const { __index, __errors, ...values } = value;
        acc.validData.push(values);
        return acc;
      },
      { validData: [] }
    );
    setSubmitData(calculatedData);
    setShowSubmitAlert(false);
  };

  // Submit the data to the next step.
  const onContinue = () => {
    const invalidData = data.find((value) => {
      if (value?.__errors) {
        return Object.values(value.__errors)?.filter((err) => err.level === 'error').length;
      }
      return false;
    });
    if (!invalidData) {
      submitData();
      setDiscardedRows(data.length > 0 ? data.length : 0);
      handleNext();
    } else {
      setShowSubmitAlert(true);
      setTimeout(scrollToErrorCollapse, 1000);
    }
  };

  // Custom hook to toggle employee validation.
  const { handleEmployeeValidationOpen, handleEmployeeValidationClose } =
    useEmployeeValidationToggle({
      setCurrentEmployeeId,
      setSelection,
      setEmployeeFullName,
      setEmployeeDatabaseFullName,
    });

  // Custom hook to update selected rows with new employee name.
  const { updateSelectedRows } = useEmployeeUpdateNameConfirmation({
    data,
    selection,
    updateRow,
    handleEmployeeValidationClose,
  });

  return (
    <Fragment>
      <Typography ref={scrollToRef} variant="h5" gutterBottom sx={{ m: 3 }}>
        VALIDATE DATA
      </Typography>
      <Box sx={{ pr: 3, pl: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GuideCard
              mainTitle="Instructions"
              subTitle="Please follow the instructions below."
              cardHeaderColor="#e6effe"
              cardIconColor="rgb(0, 120, 215)"
              cardContentIcon="info"
              contentItems={contentInfoItems}
            />
          </Grid>
          {errorDetected || warningDetected ? (
            <>
              {errorDetected && (
                <Grid
                  item
                  xl={warningDetected ? 6 : 12}
                  lg={warningDetected ? 6 : 12}
                  md={warningDetected ? 6 : 12}
                  sm={12}
                  xs={12}
                >
                  <GuideCard
                    mainTitle="Errors"
                    subTitle="Please review the items below."
                    cardHeaderColor="#FFF8F8"
                    cardIconColor="rgb(209, 65, 36)"
                    cardContentIcon="error"
                    heightSize={errorDetected && warningDetected ? '248px' : 'auto'}
                    contentItems={contentErrorItems}
                  />
                </Grid>
              )}
              {warningDetected && (
                <Grid
                  item
                  xl={errorDetected ? 6 : 12}
                  lg={errorDetected ? 6 : 12}
                  md={errorDetected ? 6 : 12}
                  sm={12}
                  xs={errorDetected ? 6 : 12}
                >
                  <GuideCard
                    mainTitle="Warnings"
                    subTitle="Please review the items below."
                    cardHeaderColor="#fcf2e9"
                    cardIconColor="rgb(255, 220, 76)"
                    cardContentIcon="warning"
                    heightSize={errorDetected && warningDetected ? '248px' : 'auto'}
                    contentItems={contentWarningItems}
                  />
                </Grid>
              )}
            </>
          ) : null}
        </Grid>
      </Box>
      <Box sx={{ p: 2 }} />
      <Stack
        alignItems={stackAlignment}
        justifyContent="flex-start"
        alignContent={stackAlignment}
        direction={stackDirection}
        spacing={1}
        sx={{ ml: 4 }}
      >
        <DiscardSelectedRowButton deleteSelectedRows={deleteSelectedRows} />
        <DefaultEndDateButton
          handleSelectDefaultWorkStatusEndDate={handleSelectDefaultWorkStatusEndDate}
        />
        <SwitchErrorFilter filterByErrors={filterByErrors} setFilterByErrors={setFilterByErrors} />
        <SwitchWarningFilter
          filterByWarnings={filterByWarnings}
          setFilterByWarnings={setFilterByWarnings}
        />
      </Stack>
      {errorIndex && <ErrorStatementPeriodCollapse />}
      <Box ref={errorCollapseRef} sx={{ p: showSubmitAlert ? 3 : 1 }}>
        <ErrorDetectedCollapse isOpen={showSubmitAlert} onClose={() => setShowSubmitAlert(false)} />
      </Box>
      <Box sx={{ p: 3 }}>
        <Stack spacing={3}>
          <TableValidation
            rows={tableData}
            columns={columns}
            accountid={accountid}
            onClaimEmployeeIds={onClaimEmployeeIds}
            totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
            statementrow={statementRow}
            onRowsChange={updateRow}
            selection={selection}
            switchChanges={switchChanges}
            handleSelectionChange={handleSelectionChange}
            onEmployeeValidationOpen={handleEmployeeValidationOpen}
            components={{
              noRowsFallback: (
                <Box display="flex" justifyContent="center" sx={{ marginTop: '32px' }}>
                  {filterByErrors ? 'No data containing errors' : 'No data found'}
                </Box>
              ),
            }}
          />
        </Stack>
      </Box>
      <EmployerNameColumnCard
        open={!!currentEmployeeId}
        selection={selection}
        employeelistid={currentEmployeeId || undefined}
        employeeNames={employeeFullName}
        employeeDatabaseFullName={employeeDatabaseFullName}
        condition={true}
        confirmation={updateSelectedRows}
        ignore={deleteSelectedRows}
        onClose={handleEmployeeValidationClose}
      />
      <Box sx={{ p: 2 }}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Button
              variant="contained"
              sx={{
                my: 3,
                ml: 1,
                bgcolor: 'grey',
                '&:hover': {
                  backgroundColor: 'grey',
                },
                '&:active': {
                  backgroundColor: 'grey',
                },
              }}
              onClick={handleBack}
            >
              {'Go Back'}
            </Button>
            <Button
              variant="contained"
              sx={{ my: 3, ml: 1 }}
              disabled={errorIndex !== null}
              type="submit"
              onClick={() => onContinue()}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default ValidationFile;
