import React, { useState, useEffect, Fragment } from "react";

// React-Router
import { useNavigate } from "react-router-dom";

// AWS Amplify - API - Request Handler
import { handleGet } from "../../utils/amplifyInstance";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Main Context
import { useMainContext } from "../../context/MainContext";

// Material-UI
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Tooltip,
  Input,
  InputAdornment,
  TextField
} from "@mui/material";

// Third Party
import {
  format,
  parseJSON
} from 'date-fns';

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";

// DevExtreme React Grid
import {
  SearchState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  DataTypeProvider
} from '@devexpress/dx-react-grid';

// DevExtreme React Grid Material-UI
import {
  Grid,
  VirtualTable,
  Toolbar,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';

// DevExtreme React Grid Material-UI - Custom Components
import {
  Plugin,
  Template,
  TemplatePlaceholder
} from '@devexpress/dx-react-core';

// Helper Functions
import { FocusableCell } from "../../common/HelperFunctions";

// Icons 
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Search from '@mui/icons-material/Search';

const getRowId = row => row.messageid;

const ToolbarSearch = ( {onValueChange, value} ) => {
  return (
    <Plugin dependencies={[
      { name: 'Toolbar' }
    ]}>
      <Template name="toolbarContent">
        {params => (
          <TemplatePlaceholder>
            {() => (
              <Tooltip title="Search">
                <span>
                <Input
                  onChange={e => onValueChange(e.target.value)}
                  value={value}
                  type="text"
                  placeholder="Search..."
                  startAdornment={(
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )}
                />
                </span>
              </Tooltip>
            )}
          </TemplatePlaceholder>
        )}
      </Template>
    </Plugin>
  );
}

const TableHeaderContent = ({ column, children, type, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: "bold"
    }}
  >
    {column.name === 'created' ? (
      type === 'inbox' ? 'Received' : 'Sent'
    ) : (
    <Fragment>
      {children}
    </Fragment>
    )}
  </TableHeaderRow.Content>
);

const StyledCell = styled(VirtualTable.Cell)(({ theme }) => ({
  [`&.TableCell-cell`]: {
    '&:first-of-type': {
      paddingLeft: '8px'
    }
  }
}));


const Cell = (props) => {
  if (props.column.name === "attachments") {
    return (
      <StyledCell {...props} >
        <Tooltip title="Attached File">
          {props.value === 0 ?
            (<Fragment/>
            ) : (
            <AttachFileIcon fontSize={"small"} />)
          }
        </Tooltip>
      </StyledCell>
    );
  }

  if (props.row.viewedon === "") {
    return (
      <FocusableCell {...props} >
        <b>{props.value}</b>
      </FocusableCell>
    );
  }

  return <FocusableCell {...props} />;
};

const DateFormatter = ({ value }) =>
  value ? (
    <TextField
      type="datetime"
      value={value ? format(parseJSON(value), "yyyy-MM-dd HH:mm:ss") : ""}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  ) : (
    ""
);
// ===========================|| MESSAGE LIST ||=========================== //

function MessagesList(props) {
  const { type } = props;
  const { authToken } = useSession();
  const { account, companyid } = useMainContext();

  const accountid = account.map((i) => i.accountid)[0];
  
  let navigate = useNavigate();
  const [columns] = useState([
    {name: 'attachments', title: ''},
    {name: 'note', title: 'Message(s)'},
    {name: 'created', title: 'Created'}
  ]);
  const [columnExtensions] = useState([
    {columnName: 'attachments', align: 'center', width:'40px'},
    {columnName: 'note', width: '1000px', wordWrapEnabled: true},
    {columnName: 'created', width:'150px'}
  ]);
  const [sortingStateColumnExtensions] = useState([
    {columnName: 'attachments', sortingEnabled: false}
  ]);
  const [searchValue, setSearchState] = useState('');

  const [messageList, setMessageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid || !type || !authToken) {
      return;
    }

    const fetchMessageList = async () => {
      try {
        // let response = await get("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/messages/" + type);
        let response = await handleGet({
          apiName: "EmployerApiGw",
          path: `/employer/${accountid}/${companyid}/messages/${type}`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();


        if (cancel) {
          return;
        }
        setMessageList(json.items);
      } catch (e) {
        console.log(e);
        setMessageList([]);
      }

      setIsLoading(false);
    };

    fetchMessageList();

    return () => {
      cancel = true;
    }
  }, [accountid, companyid, type, authToken]);

  const TableRow = ({ row, ...restProps }) => (
    <VirtualTable.Row
      {...restProps}
      onClick={() => navigate(`/messages/thread/${row.messageid}`, { replace: true })}
    />
  );

  const TableHeaderContentBase = (props) => {
    return (
      <TableHeaderContent
        {...props}
        type={type}
      />
    );
  }

  return (
  <Fragment>
    {isLoading ? (
    <CustomLoadingScreen  text="Loading your messages" />
    ) : ( messageList.length === 0 ? (
      <Typography variant="body2">
          Sorry, you don't have any messages in your {type} folder
        </Typography>
    ) : (
    <Fragment>
      <Paper >
        <Grid
          rows={messageList}
          columns={columns}
          getRowId={getRowId}
        >
          <DataTypeProvider
            for={["created"]}
            formatterComponent={DateFormatter}
          />
          <SearchState
            value={searchValue}
            onValueChange={setSearchState}
          />
          <IntegratedFiltering />
          <SortingState
            columnExtensions={sortingStateColumnExtensions}
          />
          <IntegratedSorting />
          <VirtualTable
            height={690}
            columnExtensions={columnExtensions}
            rowComponent={TableRow}
            cellComponent={Cell}
          />
          <TableHeaderRow
            showSortingControls
            contentComponent={TableHeaderContentBase}
          />
          <Toolbar />
          <ToolbarSearch
            value={searchValue}
            onValueChange={setSearchState}
          />
        </Grid>
      </Paper>
      <br />
    </Fragment>
    ))}
  </Fragment>
  );
}

export default MessagesList;