// Material-ui
import {
  Box,
  Typography
} from "@mui/material";


const ContributionHeader = ({ statementid, statementRow }) => {
  return (
    <>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            STATEMENT SUMMARY
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">
            <span style={{ fontWeight: "bold" }}>
              {" "}
              Billing Statement For :{" "}
            </span>{" "}
            <span style={{ fontWeight: "bold" }}>
              {statementid ? statementid : "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2">
            <span style={{ fontWeight: "bold" }}> Period Start Date: </span>{" "}
            {statementRow?.periodstart ? statementRow?.periodstart : "N/A"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={{ fontWeight: "bold" }}> Period End Date: </span>{" "}
            {statementRow?.periodend ? statementRow?.periodend : "N/A"}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ContributionHeader;
