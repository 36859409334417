import React from 'react';

// Material UI
import { Box, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

// Helper Functions
import { toDecimal } from '../../../../../common/HelperFunctions';

// UI Components
import DateRangeBox from '../../../../../ui-components/DateRangeBox';

const ItemInitial = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  backgroundColor: 'rgba(135, 206, 235, 0.5)',
  color: theme.palette.text.secondary,
}));

const ItemFinal = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  backgroundColor: 'rgba(60, 179, 113, 0.5)',
  color: theme.palette.text.secondary,
}));

// Hours Display Component
const HoursDisplay = ({ initialHours, finalHours, title }) => (
  <Box sx={{ width: 0.5 }}>
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
      <Box gridColumn="span 6">
        <Typography
          variant="body2"
          component="div"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
          }}
          gutterBottom
        >
          {title}
        </Typography>
      </Box>
      <Box gridColumn="span 6">
        <Typography
          variant="body2"
          component="div"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
          }}
          gutterBottom
        >
          Final Hours
        </Typography>
      </Box>
    </Box>
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
      <Box gridColumn="span 6">
        <ItemInitial>{toDecimal(initialHours) || '0'}</ItemInitial>
      </Box>
      <Box gridColumn="span 6">
        <ItemFinal>{finalHours || '0'}</ItemFinal>
      </Box>
    </Box>
  </Box>
);

const ContributionHoursSummary = ({
  item,
  payPeriodStart,
  payPeriodEnd,
  finalPenHrs,
  finalLtdHrs,
}) => {
  return (
    <>
      <Box sx={{ p: 1 }}>
      <Grid container spacing={3}>
        <Grid container alignItems="center" item xs={4}>
          <DateRangeBox 
            payPeriodStart={payPeriodStart}
            payPeriodEnd={payPeriodEnd}
            typographyColor="#000" 
            arrowColor="#008522" 
            arrowFontSize="1rem" 
          />
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={3} direction="row" justifyContent="center">
            <Grid item xs={6}>
              <HoursDisplay
                initialHours={item?.penhrs}
                finalHours={finalPenHrs}
                title="Initial Hours"
              />
            </Grid>
            <Grid item xs={6}>
              <HoursDisplay
                initialHours={item?.ltdhrs}
                finalHours={finalLtdHrs}
                title="Initial Hours"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Box>
    </>
  );
};

export default ContributionHoursSummary;
