import { Fragment } from "react";

// Material-UI
import { Button, Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ===========================|| RESET PASSWORD CONFIRMATION MESSAGE |============================ //

const AWSCognitoConfirmationMessage = ({ handleForgotPassword }) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Typography variant="h4">Password Reset Complete</Typography>
            </Grid>
            <Box sx={{ m: 1 }} />
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                Your password has been successfully reset. Please sign in
                to <br /> <span style={{ fontWeight: "bold" }}>EmployerConnect</span>{" "}
                with your new password.
              </Typography>
            </Grid>
            <Box sx={{ m: 1 }} />
            <Grid item xs={12}>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleForgotPassword}
                sx={{
                  backgroundColor: "#008521",
                  color: theme.palette.primary.contrastText,
                  "&:hover": { backgroundColor: "#008521" },
                }}
              >
                Login to EmployerConnect
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AWSCognitoConfirmationMessage;
