import React, { Fragment, useState, useEffect } from "react";

// Material UI
import { Box, Typography, Stack, Tooltip, SvgIcon } from "@mui/material";

// Material UI Icons
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// Column Types
import { ColumnType } from "../steps/MatchColumnsFile/MatchColumnsFile";

// Project Imports
import MatchedColumnSelect from "./Selects/MatchColumnSelect";

const TemplateColumn = ({ column, fields, onChange }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const isIgnored = column.type === ColumnType.ignored;
  const isChecked = column.type === ColumnType.matched || column.type === ColumnType.matchedCheckbox || column.type === ColumnType.matchedSelectOptions;
  const isEmpty = column.type === ColumnType.empty;
  const selectOptions = fields.map(({ label, key }) => ({ value: key, label }));
  
  useEffect(() => {
    const fetchSelectedValue = async () => {
      if (selectedValue === "") {
        const matchingOption = selectOptions.find(
          ({ value }) => "value" in column && column.value === value
        );
        if (matchingOption) {
          setSelectedValue(matchingOption.value);
        } else {
          setSelectedValue(""); 
        }
      }
    };

    if (isEmpty) {
      setSelectedValue(""); 
    } else {
      fetchSelectedValue();
    }
  }, [column, selectOptions, selectedValue, isEmpty]);


  return (
    <Fragment>
      <Box flexDirection="column" justifyContent="center" alignItems="center">
        {isIgnored ? (
          <Box minHeight={230} minWidth={300}>
            <Stack alignItems="flex-start" direction="row" spacing={1} sx={{ mt: 2 }}>
              <SvgIcon fontSize="medium" color="action">
                  <Tooltip title="Column Ignored">
                    <VisibilityOffOutlinedIcon />
                  </Tooltip>
                </SvgIcon>
              <Typography variant="body1" color="textPrimary">Column ignored</Typography>
            </Stack>
          </Box>
        ) : (
          <Fragment>
            <MatchedColumnSelect
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              name={column.header}
              columnIndex={column.index}
              onChange={onChange}
              options={selectOptions}
              isChecked={isChecked}
            />
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default TemplateColumn;
