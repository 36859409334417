import React, { useState, Fragment } from "react";

// Material-UI
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Grid,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  OutlinedInput,
} from "@mui/material";

// Custom Styles
import { customInput } from "../../../../theme/Typography";

// Project Imports
import { useUser } from "../../../../context/AuthContext";
import ErrorBox from "../../../../ui-components/NotificationErrorBox";

// Third-party
import * as Yup from "yup";
import { Formik } from "formik";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// ===========================|| CONFIRM RESET PASSWORD ||=========================== //

const AWSCognitoConfirmResetPassword = ({ handleNext, resetPasswordData }) => {
  // Theme
  const theme = useTheme();

  // User Context - Confirm Reset Password
  const { handleResetPassword, handleConfirmResetPassword } = useUser();

  // State - Password Visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Handle Click - Show Password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle Click - Show Confirm Password
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Handle Mouse Down - Password
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          code: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          code: Yup.string()
            .required("Code is required")
            .matches(/^[0-9]{6}$/, "Code must be 6 digits"),
          password: Yup.string().required("Password is required"),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
          ),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting }
        ) => {
          // Confirm Reset Password - Context Cognito Function
          try {
            // Reset Password - Context Cognito Function
            const resetPasswordDataAccountID = resetPasswordData.accountID.replace(/\s/g, "");
            const resetPasswordCode = values.code.replace(/\s/g, "");
            const resetPasswordValue = values.password.replace(/\s/g, "");
            // Call the forgotPasswordSubmit function
            await handleConfirmResetPassword(
              resetPasswordDataAccountID,
              resetPasswordValue,
              resetPasswordCode
            );
            setStatus({ success: true });
            setSubmitting(false);
            handleNext();
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Typography variant="h4" sx={{ mb: 3 }}>
                Reset Password
              </Typography>
            </Grid>
            {/* Confirmation Code Start */}
            <FormControl
              fullWidth
              error={Boolean(touched.code && errors.code)}
              sx={customInput}
            >
              <InputLabel htmlFor="outlined-adornment-code">Code</InputLabel>
              <OutlinedInput
                id="outlined-adornment-code"
                name="code"
                type="text"
                value={values.code}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Code"
              />
              {touched.code && errors.code && (
                <FormHelperText error id="standard-weight-helper-text-code">
                  {errors.code}
                </FormHelperText>
              )}
            </FormControl>
            {/* Confirmation Code End */}
            {/* Password Start */}
            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={customInput}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="New Password"
                autoComplete="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {touched.password && errors.password && (
                <FormHelperText error id="standard-weight-helper-text-password">
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            {/* Password End */}
            {/* Confirm Password Start */}
            <FormControl
              fullWidth
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              sx={customInput}
            >
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Confirm Password"
                autoComplete="confirmPassword"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-confirm-password"
                >
                  {errors.confirmPassword}
                </FormHelperText>
              )}
            </FormControl>
            <Box sx={{ mt: 1 }} />
            {errors.submit && <ErrorBox message={errors.submit} />}
            {/* Confirm Password End */}
            <Box sx={{ mt: 2 }} />
            <Button
              disableElevation
              fullWidth
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#008521",
                color: theme.palette.primary.contrastText,
                "&:hover": { backgroundColor: "#008521" },
              }}
            >
              Reset Password
            </Button>
            <Box sx={{ mt: 2 }} />
            <Button
              disableElevation
              fullWidth
              size="large"
              variant="outlined"
              onClick={() =>
                handleResetPassword({
                  username: resetPasswordData.accountID.replace(/\s/g, ""),
                })
              }
              sx={{
                color: "#008521",
                backgroundColor: "transparent",
                borderColor: "#008521",
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: "#008521",
                },
              }}
            >
              Resend Code
            </Button>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AWSCognitoConfirmResetPassword;
