import { useQuery } from "@tanstack/react-query";

// AWS Amplify - API - Request Handler
import { handleGet } from "../utils/amplifyInstance";

const useViewPadPastPayment = (accountid, companyid, authToken) => {
  const fetchViewPadPastPayment = async () => {
    if (!accountid || !companyid || !authToken) {
      return;
    }

    try {
      const response = await handleGet({
        apiName: "EmployerApiGw",
        path: "/employer/" + accountid + "/" + companyid + "/pastpayments",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const json = await body.json();

      return json.items || [];
    } catch (error) {
      console.log(error);
      throw new Error("Failed to fetch past payments");
    }
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["viewPadPastPayments", accountid, companyid, authToken],
    queryFn: fetchViewPadPastPayment,
    enabled: !!accountid && !!companyid && !!authToken,
    placeholderData: [],
  });

  return { data, error, isLoading };
};

export default useViewPadPastPayment;
