import React from "react";

// Material-UI
import { FormControlLabel, Switch } from "@mui/material";

const SwitchErrorFilter = ({ filterByErrors, setFilterByErrors }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={filterByErrors}
          size="large"
          onChange={() => setFilterByErrors(!filterByErrors)}
        />
      }
      label="Show only rows with errors"
    />
  );
};

export default SwitchErrorFilter;
