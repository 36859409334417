import { Fragment } from "react";

// Material-UI
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// ===========================|| REGISTRATION COMPLETE - LOGIN |============================ //

const RegisteredSuccess = ({
  handleBackToSignIn
}) => {
  const theme = useTheme();
  const benefits = [
    "View and update their company information",
    "View their account balance and history",
    "Download/upload billing statements and contribution reports",
    "Enter member hours and contributions",
    "Secure messaging with the contributions team",
  ];

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6">
                Your registration for EmployerConnect is now complete!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ m: 1 }} />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="h6">You now have access to:</Typography>
            <List>
              {benefits.map((benefit, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon color="success" />
                  </ListItemIcon>
                  <ListItemText primary={benefit} sx={{ color: "#000"}} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Box sx={{ m: 1 }} />
          <Grid item xs={12}>
            <Button
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleBackToSignIn}
              sx={{
                backgroundColor: "#008521",
                color: theme.palette.primary.contrastText,
                "&:hover": { backgroundColor: "#008521" },
              }}
            >
              Login to EmployerConnect
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default RegisteredSuccess;
