// React Query
import { useQuery } from "@tanstack/react-query";

// AWS Amplify - API - Request Handler
import { handleGet } from "../utils/amplifyInstance";

const useFetchLtdCompany = (accountid, companyid, authToken) => {
  const fetchLtdCompany = async () => {
    if (!accountid || !companyid || !authToken) {
      return;
    }

    try {
      const response = await handleGet({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/${parseInt(companyid)}/ltdcompany`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const json = await body.json();

      return json.items.length > 0 ? json.items[0].contributes : "N";
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["ltdCompany", accountid, companyid, authToken],
    queryFn: fetchLtdCompany,
    enabled: !!accountid && !!companyid && !!authToken,
    placeholderData: "N",
  });

  return { data, error, isLoading };
};

export default useFetchLtdCompany;
