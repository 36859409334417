import React, { Fragment } from "react";

// Material-ui
import {
  Box,
  Card,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  FormControlLabel,
  Typography,
  CardContent,
} from "@mui/material";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SelectSheetFile = ({ sheetNames, selectedSheet, setSelectedSheet }) => {
  return (
    <Fragment>
      <Typography variant="h5" gutterBottom sx={{ mb: 1 }}>
        Select the sheet to use
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ mb: 2 }}>
        If you have multiple sheets in your file, please select the correct one containing your contributions data.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={3}>
          <RadioGroup
            name="selectedSheet"
            value={selectedSheet}
            onChange={(event) => {
              setSelectedSheet(event.target.value);
            }}
          >
            {sheetNames.map((sheetName, index) => (
              <FormControlLabel
                key={index}
                value={index.toString()}
                control={<Radio {...a11yProps(index)} />}
                label={sheetName}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          {sheetNames.map((sheetName, index) => (
            <TabPanel
              key={index}
              value={selectedSheet.toString()}
              index={index.toString()}
            >
              <Card sx={{ border: "2px solid rgba(0, 133, 34, 0.3)" }}>
                <CardContent>
                  <Stack spacing={3}>
                    <Typography variant="body2">
                      Sheet Name : {sheetName}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SelectSheetFile;
