import React from "react";

// Material-UI
import {
  AppBar,
  Box,
  Toolbar,
  Tooltip,
} from "@mui/material";

// Project Imports
import ChangeCompanyMenu from '../../ui-components/ChangeCompanyMenu';

// Icons
import UserMenu from '../../ui-components/UserMenu';

function IWANavBar(props) {
  const { accountid, email, setCompanyId, signOut } = props;

  const EmployerConnectLogo = "/assets/images/erconn.png";

  return (
    <AppBar position="fixed">
      <Toolbar sx={{justifyContent: 'space-between'}}>
        <Tooltip title={props.drawerOpen ? "Hide Menu Drawer" : "Show Menu Drawer"}>
          <img
            src={EmployerConnectLogo}
            alt={"ER Conn"}
            style={{
              cursor: "pointer",
              marginLeft:"20px",
              marginTop: "7px",
              marginBottom: "7px",
              width: "100px",
              height: "50px"
            }}
            onClick={() => {
              props.setDrawerOpen(!props.drawerOpen);
            }}
          />
        </Tooltip>

        <Box sx={{ display: 'flex', flexDirection: 'row', marginRight:"16px"}} >
          <ChangeCompanyMenu
            accountid={accountid}
            setCompanyId={setCompanyId}
          />
          <UserMenu
            email={email}
            signOut={signOut}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default IWANavBar;