import React, { useState } from "react";

// Material-UI
import { Grid } from "@mui/material";

// Project Imports
import ContactTypes from "../contacts/ContactTypes";
import ContactInfo from "../contacts/ContactInfo";
import ContactPoints from "../contacts/ContactPoints";

function ContactsDetails(props) {
  const { accountid, companyid, roleid } = props;
  const [contactid, setContactId] = useState(null);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={2}>
        <ContactTypes
          accountid={accountid}
          companyid={companyid}
          setContactId={setContactId}
        />
      </Grid>
      <Grid container spacing={1} item xs={10}>
        <Grid item xs={12}>
          <ContactInfo
            accountid={accountid}
            companyid={companyid}
            roleid={roleid}
            contactid={contactid}
          />
        </Grid>
        <Grid item xs={12} >
          <ContactPoints
            accountid={accountid}
            companyid={companyid}
            roleid={roleid}
            contactid={contactid}
            pointsgroup={"EMAIL"}
          />
        </Grid>
        <Grid item xs={12} >
          <ContactPoints
            accountid={accountid}
            companyid={companyid}
            roleid={roleid}
            contactid={contactid}
            pointsgroup={"PHONE"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default (ContactsDetails);