import { useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet } from '../../../utils/amplifyInstance';

// Third-party
import { map, intersection, filter, includes } from 'lodash';

export const useComparisonExcelData = (
  finalExcelData,
  totalHours,
  accountid,
  companyid,
  authToken,
  statementid
) => {
  const [discrepancyRecords, setDiscrepancyRecords] = useState([]);
  const [discrepancyPenhrs, setDiscrepancyPenhrs] = useState(0);
  const [discrepancyLtdhrs, setDiscrepancyLtdhrs] = useState(0);
  const [discrepancyTotal, setDiscrepancyTotal] = useState(0);
  const [isDiscrepRecordsLoading, setIsDiscrepRecordsLoading] = useState(true);
  const [error, setError] = useState(null);

  const filterEmployeeDataByEmployeelistid = async (initialExcelData, finalExcelData) => {
    const initialIds = map(initialExcelData, 'employeelistid');
    const finalIds = map(finalExcelData, 'employeelistid');

    const commonIds = intersection(initialIds, finalIds);

    const filteredInitialData = filter(initialExcelData, (item) =>
      includes(commonIds, item.employeelistid)
    );
    const filteredFinalData = filter(finalExcelData, (item) =>
      includes(commonIds, item.employeelistid)
    );
    
    const discrepancies = filteredInitialData.reduce((acc, item) => {
      const correspondingFinalItem = filteredFinalData.find(
        (finalItem) => finalItem.employeelistid === item.employeelistid
      );
      if (
        !correspondingFinalItem ||
        item.penhrs !== correspondingFinalItem.penhrs ||
        item.ltdhrs !== correspondingFinalItem.ltdhrs
      ) {
        acc.push({
          employeelistid: item.employeelistid,
          Givennames: item.Givennames,
          Surname: item.Surname,
          initialPenhrs: item.penhrs < 0 ? 0 : item.penhrs,
          finalPenhrs: correspondingFinalItem ? correspondingFinalItem.penhrs : 'N/A',
          initialLtdhrs: item.ltdhrs < 0 ? 0 : item.ltdhrs,
          finalLtdhrs: correspondingFinalItem ? correspondingFinalItem.ltdhrs : 'N/A',
        });
      }
      return acc;
    }, []);

    return discrepancies;
  };

  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid || !authToken || !statementid || !finalExcelData) {
      return;
    }

    const fetchInitialData = async () => {
      try {
        const version = 'initial';

        const response = await handleGet({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${companyid}/excelfile/${version}/${statementid}`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        if (json.items.length > 0) {
          const employeediscrepancyData = await filterEmployeeDataByEmployeelistid(
            json.items[0],
            finalExcelData.validData
          );
          const sumOfPenhrsInit = employeediscrepancyData.reduce(
            (acc, item) => acc + Number(item.initialPenhrs || 0),
            0
          );
          const sumOfLtdhrsInit = employeediscrepancyData.reduce(
            (acc, item) => acc + Number(item.initialLtdhrs || 0),
            0
          );
          const sumOfPenhrsFinal = employeediscrepancyData.reduce(
            (acc, item) => acc + Number(item.finalPenhrs || 0),
            0
          );
          const sumOfLtdhrsFinal = employeediscrepancyData.reduce(
            (acc, item) => acc + Number(item.finalLtdhrs || 0),
            0
          );

          // Calculate total discrepancies for both penhrs and ltdhrs
          const totalSumOfPenhrs = parseFloat(sumOfPenhrsFinal - sumOfPenhrsInit);
          const totalSumOfLtdhrs = parseFloat(sumOfLtdhrsFinal - sumOfLtdhrsInit);

          // Get total hours from the statement
          const totalHoursPension = totalHours?.penhrs ? totalHours.penhrs : 0;
          const totalHoursLTD = totalHours?.ltdhrs ? totalHours.ltdhrs : 0;

          // Sum of total hours for pension and LTD
          const totalHoursSum =
            totalHoursPension + totalHoursLTD > 0 ? totalHoursPension + totalHoursLTD : 0;

          // Calculate the sum of totalSumOfPenhrs and totalSumOfLtdhrs
          const subTotalCombinedHours = parseFloat(totalSumOfPenhrs + totalSumOfLtdhrs);

          // Calculate total discrepancies for both penhrs and ltdhrs
          const totalSumOfDiscrepancy = totalHoursSum - subTotalCombinedHours;

          // Grant Total Discrepancy
          const grantTotalDiscrepancy = parseFloat(totalHoursSum - totalSumOfDiscrepancy);

          setDiscrepancyRecords(employeediscrepancyData);
          setDiscrepancyPenhrs(totalSumOfPenhrs);
          setDiscrepancyLtdhrs(totalSumOfLtdhrs);
          setDiscrepancyTotal(grantTotalDiscrepancy);
        } else {
          setDiscrepancyRecords([]);
          setDiscrepancyPenhrs(0);
          setDiscrepancyLtdhrs(0);
          setDiscrepancyTotal(0);
        }
      } catch (e) {
        console.log(e);
        setError(e);
        setDiscrepancyRecords([]);
        setDiscrepancyPenhrs(0);
        setDiscrepancyLtdhrs(0);
        setDiscrepancyTotal(0);
      }
      setIsDiscrepRecordsLoading(false);
    };

    fetchInitialData();

    return () => {
      cancel = true;
    };
  }, [accountid, companyid, statementid, authToken, finalExcelData, totalHours]);

  return {
    discrepancyPenhrs,
    discrepancyLtdhrs,
    discrepancyTotal,
    discrepancyRecords,
    isDiscrepRecordsLoading,
    error,
  };
};

export default useComparisonExcelData;
