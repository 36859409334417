// Check Unique Entries
export const uniqueEntries = (data, index) => {
    const uniqueMap = new Map();
    const uniqueEntries = [];
  
    data.forEach((row) => {
      const entry = row[index];
      if (entry && !uniqueMap.has(entry)) {
        uniqueMap.set(entry, true);
        uniqueEntries.push({ entry });
      }
    });
  
    return uniqueEntries;
  };
  




