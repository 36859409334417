import { useState, useEffect } from "react";

function useBillingOpenStatement(billingStatementOpen, statementStatus, statementId) {
  // State variables
  const [billingStatementEditable, setBillingStatementEditable] = useState("Y");

  useEffect(() => {
    let cancel = false;

    // Order from the newst to the olders by periodstart 

    if ((billingStatementOpen && billingStatementOpen.length <= 1) || statementStatus === "Closed") {
      return;
    }

    const applyBillingStatementValidation = async () => {
      try {
        // Order from the newest to the oldest by periodstart 
        const sortedBillingStatementOpen = [...billingStatementOpen].sort((a, b) => new Date(a.periodstart) - new Date(b.periodstart));

        sortedBillingStatementOpen.forEach((obj, index) => {
            obj.enabled = index === 0 ? 'Y' : 'N';
        });

        if (cancel) {
          return;
        }

        if (sortedBillingStatementOpen.length > 0) {
            const matchingStatementId = billingStatementOpen.find(obj => obj.statementId === statementId);
            setBillingStatementEditable(matchingStatementId.enabled);
        } else {
            setBillingStatementEditable("Y");
        }
      } catch (e) {
        console.log(e);
      }
    };

    applyBillingStatementValidation();

    return () => {
      cancel = true;
    };
  }, [billingStatementOpen, statementId, statementStatus]);

  return { billingStatementEditable };
}

export default useBillingOpenStatement;
