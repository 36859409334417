import { useState, useEffect } from "react";

// AWS Amplify - API - Request Handler
import { handleGet } from '../../../utils/amplifyInstance';

export const useInitialExcelHours = (
  accountid,
  companyid,
  authToken,
  version,
  statementid
) => {
  const [initialExcelHours, setInitialExcelHours] = useState([]);
  const [sumInitialExcelHours, setSumInitialExcelHours] = useState(0);
  const [isExcelHoursLoading, setIsExcelHoursLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid || !statementid || !authToken || !version) {
      return;
    }

    const fetchInitialExcelData = async () => {
      try {
        const response = await handleGet({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${companyid}/excelfile/${version}/${statementid}/totalhours`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        if (json.items.length > 0) {
          setInitialExcelHours(json.items);
          setSumInitialExcelHours(
            json.items.reduce((acc, { penhrs, ltdhrs }) => {
              return acc + penhrs + ltdhrs;
            }, 0)
          );
        }
      } catch (error) {
        console.error(error);
        setError(error);
        setInitialExcelHours([]);
        setSumInitialExcelHours(0);
      } finally {
        setIsExcelHoursLoading(false);
      }
    };

    fetchInitialExcelData();

    return () => {
      cancel = true;
    };
  }, [accountid, companyid, authToken, version, statementid]);

  return {
    initialExcelHours,
    sumInitialExcelHours,
    isExcelHoursLoading,
    error,
  };
};
