import React from 'react';

// react router dom
import { useNavigate } from 'react-router-dom';

// Material-UI
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Custom Styles
import { customInput } from '../../../../theme/Typography';

// Auth Context
import { useUser } from '../../../../context/AuthContext';

// Project Imports
import ErrorBox from '../../../../ui-components/NotificationErrorBox';

// Third-party
import * as Yup from 'yup';
import { Formik } from 'formik';

// ===========================|| AUTH - CODE VERIFICATION ||=========================== //

const CodeVerification = ({ handleBack, loginData }) => {
  // User Context
  const { handleConfirmSignIn } = useUser();

  // Navigation
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Box sx={{ maxWidth: '420px' }}>
          <Grid
            container
            direction={matchDownSM ? 'column' : 'row'}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Stack spacing={2}>
                <Typography
                  gutterBottom
                  variant={matchDownSM ? 'h5' : 'h6'}
                  sx={{
                    alignSelf: 'center',
                  }}
                >
                  Enter Verification Code
                </Typography>
                <Typography variant="subtitle1" align="center">
                   We have sent a code by text message or email to the account associated with {" "}
                   {loginData.accountID && loginData.accountID ? loginData.accountID : ''}.
                </Typography>
                <Typography variant="subtitle1" align="center">
                  Enter it below to confirm this sign-in.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  code: '',
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  code: Yup.string()
                    .required('Verification code is required')
                    .matches(/^[0-9]{6}$/, 'Verification code must be a 6-digit number'),
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                  try {
                    const code = values.code.trim().replace(/\s/g, '');
                    await handleConfirmSignIn({ challengeResponse: code });
                    setStatus({ success: true });
                    setSubmitting(false);
                    navigate('/', { replace: true });
                  } catch (error) {
                    setStatus({ success: false });
                    setErrors({ submit: error.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.code && errors.code)}
                      sx={customInput}
                    >
                      <InputLabel htmlFor="code">Verification Code</InputLabel>
                      <OutlinedInput
                        id="code"
                        name="code"
                        type="text"
                        value={values.code}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Verification Code"
                      />
                      {touched.code && errors.code && (
                        <FormHelperText error id="code-helper-text">
                          {touched.code && errors.code}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Box sx={{ mt: 1 }} />
                    {errors.submit && <ErrorBox message={errors.submit} />}
                    <Box sx={{ mt: 1 }} />
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: '#008521',
                        color: theme.palette.primary.contrastText,
                        '&:hover': { backgroundColor: '#008521' },
                      }}
                      disabled={isSubmitting}
                    >
                      Verify
                    </Button>
                    <Box sx={{ mt: 2 }} />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      textAlign={matchDownSM ? 'center' : 'inherit'}
                    >
                      Didn’t get the code? Check your spam inbox, or click below to button to go
                      back to sign in.
                    </Typography>
                    <Box sx={{ mt: 2 }} />
                    <Grid item xs={12}>
                      <Button
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        onClick={handleBack}
                        sx={{
                          color: '#008521',
                          backgroundColor: 'transparent',
                          borderColor: '#008521',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            borderColor: '#008521',
                          },
                        }}
                      >
                        Back to Sign In
                      </Button>
                      <Box sx={{ mt: 1 }} />
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CodeVerification;
