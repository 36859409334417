import { useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet } from '../utils/amplifyInstance';

// Date FNS
import {
  format,
  addBusinessDays
 } from 'date-fns'

// ===========================|| MAKE PAD FORM HOOK ||=========================== //

const useMakePADForm = (submitData, validate, props) => {
  const { accountid, companyid, statementid, authToken } = props;

  const [data, setData] = useState({
    statementid: statementid,
    note: "",
    outstanding: "",
    payee: "Billing Statement",
    frequency: "1",
    onetime_startdate: format(addBusinessDays(new Date(), 1), "dd-MMM-yyyy"),
    onetime_startamount: ""
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid || !statementid || !authToken) {
      return;
    }

    const fetchData = async () => {
      try {
        // let response = await get("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/outstanding/" + statementid);
        let response = await handleGet({
          apiName: "EmployerApiGw",
          path: `/employer/${accountid}/${companyid}/outstanding/${statementid}`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        response = await body.json();

        if (cancel) {
          return;
        }

        response = JSON.parse(
          JSON.stringify(response, (k, v) => {
            return v == null ? "" : v;
          })
        );

        if (response.items.length > 0) {
          setData(data => ({
            ...data,
            "note": response.items[0].note,
            "outstanding": response.items[0].outstanding,
            "onetime_startamount": response.items[0].outstanding
            })
          );
        }
      } catch (e) {
        console.log(e);
      }

      setIsLoading(false);
      setIsSubmitting(false);
    };

    fetchData();

    return () => {
      cancel = true;
    }
  }, [accountid, companyid, statementid, authToken]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setIsSubmitting(false);
      submitData();
    }
  }, [errors, isSubmitting, submitData]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setErrors(validate(data));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();

    var value = "";

    const target = event.target;
    const name = target.name;

    if (name === "frequency") {
      if (target.value === "1") {
        setData({
          ...data,
          "note": data.note,
          "onetime_startamount": data.outstanding,
          "onetime_startdate": format(addBusinessDays(new Date(), 1), "dd-MMM-yyyy")
        });
      }
    }

    value = target.value;
    setData(data => ({ ...data, [name]: value }));
  };

  const handleLOVChange = (name, value) => {
    setData({...data, [name]: value });
  };

  return {
    isLoading,
    handleChange,
    handleLOVChange,
    handleSubmit,
    data,
    errors
  }
};

export default useMakePADForm;