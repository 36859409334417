import { useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet } from '../../../utils/amplifyInstance';

export const useDiscardedEmployees = (accountid, companyid, authToken, statementid) => {
  const [discardedEmployees, setDiscardedEmployees] = useState([]);
  const [isDiscardedEmployeesLoading, setIsDiscardedEmployeesLoading] = useState(true);
  const [isDiscardedEmployeesError, setIsDiscardedEmployeesError] = useState(false);

  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid || !authToken || !statementid) {
      return;
    }

    const fetchDiscardedEmployees = async () => {
      try {
        const response = await handleGet({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${companyid}/jsondoc/discard/${statementid}`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        if (json.items.length > 0) {
          setDiscardedEmployees(json.items);
        }
      } catch (error) {
        console.error(error);
        setIsDiscardedEmployeesError(error);
      } finally {
        setIsDiscardedEmployeesLoading(false);
      }
    };

    fetchDiscardedEmployees();

    return () => {
      cancel = true;
    };
  }, [accountid, companyid, authToken, statementid]);

  return {
    discardedEmployees,
    isDiscardedEmployeesLoading,
    isDiscardedEmployeesError,
  };
};
