// Material ui
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Dialog,
  Typography,
} from "@mui/material";

// Icons
import WarningIcon from "@mui/icons-material/Warning";

const EmployerNameColumnCard = (props) => {
  const {
    employeeNames,
    employeeDatabaseFullName,
    open = false,
    confirmation,
    ignore,
    condition,
    onClose,
  } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        aria-describedby="employeeModalDescription"
      >
        <Card sx={{ backgroundColor: "#f6f8e1", borderRadius: "10px" }}>
          <CardContent>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                display: "flex",
                p: 3,
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "error.lightest",
                  color: "error.main",
                }}
              >
                <SvgIcon>
                  <WarningIcon />
                </SvgIcon>
              </Avatar>
              <div>
                <Typography variant="h5" id="employeeModalTitle">
                  Employee name verification
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 1, fontSize: "1rem" }}
                  variant="body2"
                  id="employeeModalDescription"
                >
                  You provided an employee name which is different from our
                  records. Please verify if this is the same person by checking
                  your employee records.
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 1, fontSize: "1rem" }}
                  variant="body2"
                >
                  If the employees named below are different people, click
                  “discard” you will have the opportunity to fix this record
                  before you submit your report.
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 1, fontSize: "1rem" }}
                  variant="body2"
                >
                  If the employees named below are the same person, click
                  “confirm.”
                </Typography>
                <Box sx={{ mt: 2 }} />
                <Typography
                  color="primary.main"
                  sx={{ mt: 1 }}
                  variant="subtitle2"
                >
                  File input employee name:
                </Typography>
                <Typography
                  color="secondary.main"
                  sx={{ mt: 1 }}
                  variant="body2"
                >
                  {employeeNames?.employeeGivenName}{" "}
                  {employeeNames?.employeeSurname}
                </Typography>
                <Typography
                  color="primary.main"
                  sx={{ mt: 1 }}
                  variant="subtitle2"
                >
                  Our records employee name:
                </Typography>
                <Typography
                  color="secondary.main"
                  sx={{ mt: 1 }}
                  variant="body2"
                >
                  {employeeDatabaseFullName?.employeeDatabaseGivenName}{" "}
                  {employeeDatabaseFullName?.employeeDatabaseSurName}
                </Typography>
              </div>
            </Stack>
            <Box
              variant="outlined"
              size="medium"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pb: 3,
                px: 3,
              }}
            >
              {condition && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={ignore}
                  color="inherit"
                  sx={{ mr: 2 }}
                >
                  Discard
                </Button>
              )}
              <Button
                variant="contained"
                size="medium"
                onClick={() =>
                  confirmation(
                    employeeDatabaseFullName?.employeeDatabaseGivenName ||
                      undefined,
                    employeeDatabaseFullName?.employeeDatabaseSurName ||
                      undefined
                  )
                }
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                Confirm
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
};

export default EmployerNameColumnCard;
