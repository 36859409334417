import { uniqueEntries } from "./uniqueDataEntries";

export const setColumn = (oldColumn, field, data) => {
    switch (field?.fieldType.type) {
      case 'select':
        return {
          ...oldColumn,
          type: 'matchedSelect',
          value: field.key,
          matchedOptions: uniqueEntries(data || [], oldColumn.index),
        };
      case 'checkbox':
        return {
          index: oldColumn.index,
          type: 'matchedCheckbox',
          value: field.key,
          header: oldColumn.header,
        };
      case 'input':
        return {
          index: oldColumn.index,
          type: 'matched',
          value: field.key,
          header: oldColumn.header,
        };
      default:
        return {
          index: oldColumn.index,
          header: oldColumn.header,
          type: 'empty',
        };
    }
  };