import React, {Fragment} from "react";
import {
    TableCell,
    IconButton,
    Tooltip
} from "@mui/material";
import {
  Plugin,
  Template,
  TemplateConnector,
  Getter
} from "@devexpress/dx-react-core";
import { 
    Table,
    TableHeaderRow,
    TableEditRow
} from "@devexpress/dx-react-grid-material-ui";
import Delete from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function TableEditColumnPlugIn(props) {
    return (
        <Fragment>
            <Plugin>
                <Getter
                    name="tableColumns"
                    computed={({ tableColumns }) => {
                        const result = tableColumns.slice();
                        result.splice(0, 0, { key: "actions", type: "actions", align: 'center', width: 65 });
                        return result;    
                    }}
                />
                <Template
                    name="tableCell"
                    predicate={({ tableColumn, tableRow }) =>
                        tableColumn.type === "actions" && tableRow.type === Table.ROW_TYPE
                    }
                >
                    {params => (                    
                        <TemplateConnector>
                        {(getters, { startEditRows, deleteRows, commitDeletedRows }) => (
                            <TableCell padding="none">
                                <Tooltip title="Edit Row">
                                    <span>
                                        <IconButton
                                            disabled={params.tableRow.row.correspondencetype === "WORK EMAIL" || (props.roleid !== 1 ? true : false)}
                                            size="small"
                                            onClick={() => {
                                                const rowIds = [params.tableRow.rowId];
                                                startEditRows({ rowIds });
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>                                
                                <Tooltip title="Delete Row">
                                    <span>
                                        <IconButton
                                            disabled={params.tableRow.row.correspondencetype === "WORK EMAIL" || (props.roleid !== 1 ? true : false)}
                                            size="small"
                                            onClick={() => {
                                                if (window.confirm('Are you sure you want to delete this row?')) {
                                                    const rowIds = [params.tableRow.rowId];
                                                    deleteRows({ rowIds });
                                                    commitDeletedRows({ rowIds });
                                                }
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </TableCell>
                        )}
                        </TemplateConnector>
                    )}
                </Template>

                <Template
                    name="tableCell"
                    predicate={({ tableColumn, tableRow }) =>
                        tableColumn.type === "actions" &&
                        (tableRow.type === TableEditRow.EDIT_ROW_TYPE ||
                        tableRow.type === TableEditRow.ADDED_ROW_TYPE)
                    }
                >
                    {params => (                    
                        <TemplateConnector>
                        {(getters, { stopEditRows, cancelChangedRows, cancelAddedRows, commitAddedRows, commitChangedRows }) => (
                            <TableCell padding="none">
                                <Tooltip title="Save Change">
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => {  
                                            const rowIds = [params.tableRow.rowId];
                                            if (params.tableRow.type === TableEditRow.ADDED_ROW_TYPE) {
                                                commitAddedRows({ rowIds });
                                                props.setIsNewRow(false);
                                            } else {
                                                commitChangedRows({ rowIds });
                                                if (params.tableRow.row.edits.length === 0) {
                                                    stopEditRows({ rowIds });
                                                }
                                            }
                                        }}
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                </Tooltip>                                
                                <Tooltip title="Cancel Change">
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={() => {
                                            const rowIds = [params.tableRow.rowId];
                                            if (params.tableRow.type === TableEditRow.ADDED_ROW_TYPE) {
                                                cancelAddedRows({ rowIds });
                                                props.setIsNewRow(false);
                                            } else {
                                                cancelChangedRows({ rowIds });
                                            }
                                            stopEditRows({ rowIds });
                                        }}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        )}
                        </TemplateConnector>
                    )}                    
                </Template>

                <Template
                    name="tableCell"
                    predicate={({ tableColumn, tableRow }) =>
                        tableColumn.type === "actions" &&
                        tableRow.type === TableHeaderRow.ROW_TYPE
                    }
                >
                    {params => (
                        <TemplateConnector>
                            {(getters, { addRow }) => (
                                <TableCell align="center" padding="none">
                                    <Tooltip title="Add Row">
                                        <span>
                                            <IconButton
                                                disabled={props.addButtonDisabled || (props.roleid !== 1 ? true : false)}
                                                size="small"
                                                onClick={() => {
                                                    props.setIsNewRow(true);
                                                    addRow();
                                                }}
                                            >
                                                <PlaylistAddIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            )}
                        </TemplateConnector>
                    )}
                </Template>
            </Plugin>
        </Fragment>
    );
}

export default TableEditColumnPlugIn;