import React, { Fragment, useState } from "react";

// React Router Dom
import { Link } from "react-router-dom";

// Auth Session 
import { useSession } from "../../context/AuthSession";

// Hooks 
import useUnreadMessageCount from "../../hooks/useUnreadMessageCount";
import useFetchPadAgreementById from "../../hooks/useFetchPadAgreementById";

// Material-UI
import { useTheme } from "@mui/material/styles";
import {
  Badge,
  Toolbar,
  Drawer,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

// Project Imports
import MakePADDialog from "../payment-agreement/MakePADDialog";

// Third Party
import { useSnackbar } from "notistack";

// Icons
import CreateIcon from "@mui/icons-material/Create";
import FolderIcon from "@mui/icons-material/Folder";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import InboxIcon from "@mui/icons-material/Inbox";
import BookIcon from "@mui/icons-material/LibraryBooks";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import DescriptionIcon from "@mui/icons-material/Description";
import PhoneIcon from "@mui/icons-material/Phone";
import SentIcon from "@mui/icons-material/Send";
import GroupIcon from "@mui/icons-material/Group";
import CloseIcon from "@mui/icons-material/Close";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";

function IWASideBar(props) {
  // Auth Session and Props
  const { authToken } = useSession();
  const { accountid, companyid, roleid } = props;

  // Theme & Snackbar
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Logo & Drawer Width
  const IWALogo = "/assets/images/logo.png";
  const drawerWidth = 220;

  // State for Drawer & PAD Dialog
  const [open, setOpen] = useState(false);
  const [openPADDialog, setOpenPADDialog] = useState(false);

  // Hook to fetch the number of unread messages for the company 
  const { numUnread } = useUnreadMessageCount(accountid, companyid, authToken);

  // Hook to fetch the PAD Agreement ID for the company
  const { padAgreementId } = useFetchPadAgreementById(accountid, companyid, authToken);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const action = (key) => (
    <Fragment>
      <IconButton
        onClick={() => {
          closeSnackbar(key);
        }}
        size="large"
      >
        <CloseIcon fontSize={"small"} />
      </IconButton>
    </Fragment>
  );

  return (
    <Drawer
      variant="persistent"
      open={props.draweropen}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar sx={{ height: "64px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flexShrink: 0,
          justifyContent: "space-between",
          overflow: "auto",
          [theme.breakpoints.only("md")]: {
            paddingTop: "12px",
          },
          [theme.breakpoints.only("lg")]: {
            paddingTop: "18px",
          },
          [theme.breakpoints.up("xl")]: {
            paddingTop: "18px",
          },
        }}
      >
        <Box>
          <List>
            <ListItem button component={Link} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/messages/inbox">
              <ListItemIcon>
                <Badge badgeContent={numUnread} color="secondary" showZero>
                  <InboxIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </ListItem>
            <ListItem button component={Link} to="/messages/sent">
              <ListItemIcon>
                <SentIcon />
              </ListItemIcon>
              <ListItemText primary="Sent" />
            </ListItem>
            <ListItem button component={Link} to="/messages/new">
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="New Message" />
            </ListItem>
            <ListItem button component={Link} to="/myfiles">
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary="My Files" />
            </ListItem>
            <ListItem button component={Link} to="/employee-list">
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Employee List" />
            </ListItem>
            <ListItem button component={Link} to="/billing-statement">
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Billing Statement" />
            </ListItem>
            <ListItem button component={Link} to="/account-summary">
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Account Summary" />
            </ListItem>
            <ListItem divider={!open} button onClick={handleClick}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Bill Payments" />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List>
                <ListItem
                  disabled={roleid === 2}
                  button
                  onClick={(e) => {
                    if (padAgreementId) {
                      e.preventDefault();
                      setOpenPADDialog(true);
                    } else {
                      enqueueSnackbar(
                        "Sign up to pre-authorized debits and conveniently pay through EmployerConnect. Please contact the Plan Office for more Information.",
                        {
                          preventDuplicate: true,
                          persist: true,
                          variant: "info",
                          action,
                        }
                      );
                    }
                  }}
                >
                  <ListItemIcon sx={{ paddingLeft: "8px" }}>
                    <SettingsApplicationsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Make a Payment" />
                </ListItem>
                <ListItem divider={open} button component={Link} to="/view-pad">
                  <ListItemIcon sx={{ paddingLeft: "8px" }}>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="View Past Payments" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              component="a"
              href="https://www.iwafibp.ca/employer-connect-instructions-&-guides"
              target="_blank"
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Employer Connect Instructions" />
            </ListItem>
            <ListItem
              button
              component="a"
              href="https://www.iwafibp.ca/manuals-and-guides"
              target="_blank"
            >
              <ListItemIcon>
                <ImportContactsIcon />
              </ListItemIcon>
              <ListItemText primary="Manuals and Guides" />
            </ListItem>
            <ListItem
              button
              component="a"
              href="http://www.iwafibp.ca/employer-forms"
              target="_blank"
            >
              <ListItemIcon>
                <FactCheckIcon />
              </ListItemIcon>
              <ListItemText primary="Forms" />
            </ListItem>
            <ListItem
              divider={true}
              button
              component="a"
              href="http://www.iwafibp.ca/contact-us"
              target="_blank"
            >
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </ListItem>
          </List>
        </Box>
        <Box>
          <List>
            <ListItem>
              <a
                href="http://www.iwafibp.ca/employers"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IWALogo} alt={"IWA Plan Office Logo"} style={{ height: "60px" }} />
              </a>
            </ListItem>
          </List>
        </Box>
      </Box>
      <MakePADDialog
        open={openPADDialog}
        setOpenPADDialog={setOpenPADDialog}
        accountid={props.accountid}
        companyid={props.companyid}
        statementid={-1}
        amountreadonly={false}
        padagreementid={padAgreementId}
      />
    </Drawer>
  );
}

export default IWASideBar;
