import { get, put, del, post } from "aws-amplify/api";

// Get request handler for Amplify API
export const handleGet = async ({
  apiName,
  path,
  headers = {},
  queryParams = {},
}) => {
  try {
    const response = await get({
      apiName,
      path,
      options: {
        headers,
        queryParams,
      },
    }).response;

    return response;
  } catch (error) {
    console.error("Error during GET request:", error);
    throw error;
  }
};

// Post request handler for Amplify API
export const handlePost = async ({
  apiName,
  path,
  headers = {},
  body = "",
  queryParams = {},
}) => {
  try {
    const response = await post({
      apiName,
      path,
      options: {
        headers,
        body,
        queryParams,
      },
    }).response;

    return response;
  } catch (error) {
    console.error("Error during POST request:", error);
    throw error;
  }
};

// Put request handler for Amplify API
export const handlePut = async ({
  apiName,
  path,
  headers = {},
  body = "",
  queryParams = {},
}) => {
  try {
    const response = await put({
      apiName,
      path,
      options: {
        headers,
        body,
        queryParams,
      },
    }).response;

    return response;
  } catch (error) {
    console.error("Error during PUT request:", error);
    throw error;
  }
};

// Delete request handler for Amplify API
export const handleDelete = async ({
  apiName,
  path,
  headers = {},
  queryParams = {},
}) => {
  try {
    const response = await del({
      apiName,
      path,
      options: {
        headers,
        queryParams,
      },
    }).response;

    return response;
  } catch (error) {
    console.error("Error during DELETE request:", error);
    throw error;
  }
};
