import React from 'react';

// Material UI
import { Box, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

// Helper Functions
import { toDecimal } from '../../../../../common/HelperFunctions';

const ContributionEmployeeDiscarded = ({ item, index }) => {
  const ItemInitial = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: 'rgba(135, 206, 235, 0.5)',
    color: theme.palette.text.secondary,
  }));

  const ItemFinal = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    backgroundColor: 'rgba(60, 179, 113, 0.5)',
    color: theme.palette.text.secondary,
  }));

  // Hours Display Component
  const HoursHeader = ({ title }) => (
    <Box sx={{ p: 0.5 }}>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 6">
          <Typography
            variant="body2"
            component="div"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
            }}
            gutterBottom
          >
            {title}
          </Typography>
        </Box>
        <Box gridColumn="span 6">
          <Typography
            variant="body2"
            component="div"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
            }}
            gutterBottom
          >
            Final Hours
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid container alignItems="center" item xs={4}>
          <Typography variant="subtitle" sx={{ textTransform: 'capitalize', mt: 1.2 }} noWrap>
            {item.givennames || ''} {item.surname || ''}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={3} direction="row" justifyContent="center">
            <Grid item xs={6}>
              {index === 0 && <HoursHeader title="Initial Hours" />}
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 6">
                  <ItemInitial>{toDecimal(item?.penhrs) || '0'}</ItemInitial>
                </Box>
                <Box gridColumn="span 6">
                  <ItemFinal>{item?.finalPenhrs || '0'}</ItemFinal>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              {index === 0 && <HoursHeader title="Initial Hours" />}
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 6">
                  <ItemInitial>{toDecimal(item?.ltdhrs) || '0'}</ItemInitial>
                </Box>
                <Box gridColumn="span 6">
                  <ItemFinal>{item?.finalLtdhrs || '0'}</ItemFinal>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContributionEmployeeDiscarded;
