import { alpha, createTheme } from "@mui/material/styles";

const primaryColor = "#008522";
const secondaryColor = "#d14124";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: 14,
        },
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          width: "fit-content",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "16px 20px 16px 20px",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&.Editor-root": {
            marginTop: "2px !important",
            marginBottom: "2px !important",
          },
          "& .Editor-input": {
            fontSize: "0.875rem !important",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "3px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          marginTop: "8px",
          marginBottom: "0px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(primaryColor, 0.3),
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "38px",
        },
        head: {
          backgroundColor: alpha(primaryColor, 0.3),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: "0px",
          paddingBottom: "0px",
          "&.TableEditCell-cell": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        },
        head: {
          fontSize: "0.75rem",
          color: "rgba(0,0,0,0.54)",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: "0px",
          paddingBottom: "0px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        gutters: {
          paddingLeft: "12px",
          paddingRight: "10px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          "@media": {
            minHeight: "auto",
            paddingLeft: "4px",
            paddingRight: "4px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: "11px",
        },
      },
    },
  },
  palette: {
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
  },
  typography: {
    useNextVariants: true,
  },
  zIndex: {
    appBar: 1201,
    drawer: 1200,
  },
});

export default theme;
