import React, { useState, Fragment } from "react";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Main Context
import { useMainContext } from "../../context/MainContext";

// Hook
import useCompanySummary from "../../hooks/useCompanySummary";

// Material-UI
import { Paper, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

// Helper Functions
import { currencyFormat } from "../../common/HelperFunctions";

// DevExtreme React Grid
import {
  SummaryState,
  IntegratedSummary,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";

// DevExtreme React Grid Material-UI
import {
  Grid,
  Table,
  VirtualTable,
  TableHeaderRow,
  TableSummaryRow,
} from "@devexpress/dx-react-grid-material-ui";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";
import ErrorBox from "../../ui-components/NotificationErrorBox";

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={currencyFormat} {...props} />
);

const classes = {
  tableStriped: "tableStriped",
};

const StyledTable = styled(VirtualTable.Table)(({ theme }) => ({
  [`&.tableStriped`]: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      marginRight: "28px",
    },
  },
}));

const TableComponent = ({ ...restProps }) => (
  <StyledTable {...restProps} className={classes.tableStriped} />
);

const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: "bold",
    }}
  >
    {children}
  </TableHeaderRow.Content>
);

const Aging = ({ asat, data }) => {
  const [columns] = useState([
    { title: "Plan", name: "plantype" },
    { title: "0-30", name: "to30" },
    { title: "30-60", name: "to60" },
    { title: "60-90", name: "to90" },
    { title: "Over 90", name: "over90" },
    { title: "Total", name: "age" },
  ]);
  const [columnExtensions] = useState([
    { columnName: "plantype", width: "100px" },
    { columnName: "to30", align: "right", width: "175px" },
    { columnName: "to60", align: "right", width: "175px" },
    { columnName: "to90", align: "right", width: "175px" },
    { columnName: "over90", align: "right", width: "175px" },
    { columnName: "age", align: "right", width: "175px" },
  ]);
  const [columnSummaries] = useState([
    { columnName: "to30", type: "sum" },
    { columnName: "to60", type: "sum" },
    { columnName: "to90", type: "sum" },
    { columnName: "over90", type: "sum" },
    { columnName: "age", type: "sum" },
  ]);
  const [currencyColumns] = useState(["to30", "to60", "to90", "over90", "age"]);

  return (
    <Paper>
      <Typography
        sx={{
          fontWeight: "bold",
          padding: "2px",
        }}
      >
        Aging Receivables at {asat}
      </Typography>
      <Grid rows={data} columns={columns}>
        <CurrencyTypeProvider for={currencyColumns} />
        <SummaryState totalItems={columnSummaries} />
        <IntegratedSummary />
        <Table
          tableComponent={TableComponent}
          columnExtensions={columnExtensions}
        />
        <TableHeaderRow contentComponent={TableHeaderContent} />
        <TableSummaryRow />
      </Grid>
    </Paper>
  );
};

const NonAging = ({ asat, data }) => {
  const [columns] = useState([
    { title: "Receivable", name: "code" },
    { title: "Amount", name: "outstanding" },
  ]);
  const [columnExtensions] = useState([
    { columnName: "code", width: "100px" },
    { columnName: "outstanding", align: "right", width: "175px" },
  ]);
  const [columnSummaries] = useState([
    { columnName: "outstanding", type: "sum" },
  ]);
  const [currencyColumns] = useState(["outstanding"]);

  return (
    <Fragment>
      {data.length > 0 ? (
        <Fragment>
          <Paper>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: "2px",
              }}
            >
              Non-Aging Receivables at {asat}
            </Typography>
            <Grid rows={data} columns={columns}>
              <CurrencyTypeProvider for={currencyColumns} />
              <SummaryState totalItems={columnSummaries} />
              <IntegratedSummary />
              <Table
                tableComponent={TableComponent}
                columnExtensions={columnExtensions}
              />
              <TableHeaderRow contentComponent={TableHeaderContent} />
              <TableSummaryRow />
            </Grid>
          </Paper>
          <br />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const Details = ({ asat, data }) => {
  const [columns] = useState([
    { title: "Plan", name: "plantype" },
    { title: "Type", name: "code" },
    { title: "Start", name: "payperiodstart" },
    { title: "End", name: "payperiodend" },
    { title: "Age", name: "age" },
    { title: "Receivable", name: "receivable" },
    { title: "Outstanding", name: "outstanding" },
  ]);
  const [columnExtensions] = useState([
    { columnName: "plantype", width: "100px" },
    { columnName: "code", align: "center", width: "100px" },
    { columnName: "payperiodstart", align: "right", width: "130px" },
    { columnName: "payperiodend", align: "right", width: "130px" },
    { columnName: "age", align: "right", width: "130px" },
    { columnName: "receivable", align: "right", width: "175px" },
    { columnName: "outstanding", align: "right", width: "175px" },
  ]);
  const [columnSummaries] = useState([
    { columnName: "receivable", type: "sum" },
    { columnName: "outstanding", type: "sum" },
  ]);
  const [currencyColumns] = useState(["receivable", "outstanding"]);

  return (
    <Fragment>
      {data.length > 0 ? (
        <Paper>
          <Typography
            sx={{
              fontWeight: "bold",
              padding: "2px",
            }}
          >
            Outstanding Account Balance Details at {asat}
          </Typography>
          <Grid rows={data} columns={columns}>
            <CurrencyTypeProvider for={currencyColumns} />
            <SummaryState totalItems={columnSummaries} />
            <IntegratedSummary />
            <VirtualTable
              height={304}
              tableComponent={TableComponent}
              columnExtensions={columnExtensions}
            />
            <TableHeaderRow contentComponent={TableHeaderContent} />
            <TableSummaryRow />
          </Grid>
        </Paper>
      ) : null}
    </Fragment>
  );
};

function AccountSummary() {
  const { authToken } = useSession();
  const { account, companyid } = useMainContext();
  const accountid = account.map((i) => i.accountid)[0];

  // Hook - Fetch Company Summary
  const { data, error, isLoading } = useCompanySummary(
    accountid,
    companyid,
    authToken
  );

  if (isLoading) {
    return <CustomLoadingScreen text="Loading Account Summary" />;
  }

  if (error) {
    return (
      <ErrorBox message="Error fetching account summary. Please try again later." />
    );
  }

  return (
    <Fragment>
      {data && (
        <Fragment>
          {data.length === 0 ? (
            <Typography variant="h5">
              Your account summary will be available on the next billing
              statement.
            </Typography>
          ) : (
            <Fragment>
              <Aging
                asat={data.map((row) => row.asat)}
                data={data
                  .map((row) => row.receivableage)
                  .reduce((x, y) => x.concat(y), [])}
              />
              <br />
              <NonAging
                asat={data.map((row) => row.asat)}
                data={data
                  .map((row) => row.outstandingdetails)
                  .reduce((x, y) => x.concat(y), [])}
              />
              <Details
                asat={data.map((row) => row.asat)}
                data={data
                  .map((row) => row.receivabledetails)
                  .reduce((x, y) => x.concat(y), [])}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

export default AccountSummary;
