import * as XLSX from 'xlsx';

export const extractWorkbookData = (workbook, sheetName, selectedSheet) => {
    const worksheet = workbook.Sheets[sheetName || workbook.SheetNames[selectedSheet]];
    const data = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      blankrows: false,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    return data;
};