import React, { useState, useEffect, Fragment } from "react";

// AWS Amplify - Storage
import { list } from 'aws-amplify/storage';

// Material-UI
import {
    Paper,
    Typography,
    TableCell,
    IconButton,
    Tooltip,
    Input,
    InputAdornment
  } from "@mui/material";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";

// Helper Functions
import {
  pad,
  DownloadFile
} from "../../common/HelperFunctions";

// DevExtreme React Grid
import {
  Plugin,
  Template,
  TemplatePlaceholder,
  Getter
} from '@devexpress/dx-react-core';

// DevExtreme React Grid Material-UI
import {
  SearchState,
  SortingState,
  IntegratedFiltering,
  IntegratedSorting
} from '@devexpress/dx-react-grid';

// DevExtreme React Grid Material-UI Components
import {
  Grid,
  Toolbar,
  Table,
  VirtualTable,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';

// Icons
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Search from '@mui/icons-material/Search';

const ToolbarSearch = ( {onValueChange, value} ) => {
  return (
    <Plugin dependencies={[
      { name: 'Toolbar' }
    ]}>
      <Template name="toolbarContent">
        {params => (
          <TemplatePlaceholder>
            {() => (
              <Tooltip title="Search">
                <span>
                <Input
                  onChange={e => onValueChange(e.target.value)}
                  value={value}
                  type="text"
                  placeholder="Search..."
                  startAdornment={(
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )}
                />
                </span>
              </Tooltip>
            )}
          </TemplatePlaceholder>
        )}
      </Template>
    </Plugin>
  );
}

const TableActionsColumn = (props) => {
  return (
    <Fragment>
      <Plugin>
          <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                  const result = tableColumns.slice();
                  result.splice(0, 0, { key: "actions", type: "actions", align: 'center', width: 65 });
                  return result;
              }}
          />

          <Template
              name="tableCell"
              predicate={({ tableColumn, tableRow }) =>
              tableColumn.type === "actions" && tableRow.type === Table.ROW_TYPE
            }
          >
              {params => (
                  <TemplatePlaceholder>
                      {() => (
                          <TableCell align="center" padding="none">
                              <Tooltip title="Download">
                                  <IconButton size="small"
                                      onClick={() => {
                                        DownloadFile(params.tableRow.row.key, null);
                                      }}
                                  >
                                      <CloudDownloadIcon />
                                  </IconButton>
                              </Tooltip>
                          </TableCell>
                      )}
                  </TemplatePlaceholder>
              )}
          </Template>
          <Template
              name="tableCell"
              predicate={({ tableColumn, tableRow }) =>
                  tableColumn.type === "actions" && tableRow.type === TableHeaderRow.ROW_TYPE
              }
          >
              <TableCell
                  align="center"
                  padding="none"
              >
                  <b style={{color: "rgba(0,0,0,0.54)", fontSize: "0.75rem"}}>
                    Actions
                  </b>
              </TableCell>
          </Template>
      </Plugin>
    </Fragment>
  );
}

const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: "bold"
    }}
  >
    {children}
  </TableHeaderRow.Content>
);

function PublicFileList(props) {
    const [columns] = useState([
      {name: 'key', title: "File Name", getCellValue: row => (row.key.split("/").pop())},
      {title: "Date uploaded", name: "lastModified", getCellValue: row => (
        row.lastModified.getUTCFullYear() +
        '-' + pad(row.lastModified.getUTCMonth() + 1) +
        '-' + pad(row.lastModified.getUTCDate()) +
        ' ' + pad(row.lastModified.getUTCHours()) +
        ':' + pad(row.lastModified.getUTCMinutes()) +
        ':' + pad(row.lastModified.getUTCSeconds())
      )},
      {title: "Size (Bytes)", name: "size"},
    ]);
    const [searchValue, setSearchState] = useState('');
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fetchFiles = async () => {
        var options = {
          level: "public"
        };

        var fileList = await list('manuals/', options);
        fileList = fileList.items.filter(file => {
          return file.key.split("/").pop() !== "";
        });

        setFiles(fileList);
        setIsLoading(false);
      };

      fetchFiles();
    }, []);

    return (
    <Fragment>
      {isLoading ? (
      <CustomLoadingScreen  text="Loading manuals and guides" />
      ) : ( files.length === 0 ? (
          <Typography variant="h6">No files found.</Typography>
      ) : (
      <Fragment>
      <Paper >
        <Grid
          rows={files}
          columns={columns}
        >
          <SearchState
            value={searchValue}
            onValueChange={setSearchState}
          />
          <SortingState />
          <IntegratedSorting />
          <IntegratedFiltering />
          <VirtualTable
            height={650}
          />
          <TableHeaderRow
            contentComponent={TableHeaderContent}
            showSortingControls
          />
          <TableActionsColumn
            {...props}
          />
          <Toolbar />
          <ToolbarSearch
            value={searchValue}
            onValueChange={setSearchState}
          />
        </Grid>

      </Paper>
      </Fragment>
      ))}
    </Fragment>
    );
  }

  export default (PublicFileList);
