import { useQuery } from "@tanstack/react-query";

// AWS Amplify - API - Request Handler
import { handleGet } from "../utils/amplifyInstance";

const useContactTypes = (accountid, companyid, authToken) => {
  const fetchContactTypes = async () => {
    if (!accountid || !companyid || !authToken) {
      return;
    }

    try {
      const response = await handleGet({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/${companyid}/contacttypes`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const json = await body.json();
      
      return json.items || [];
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["contactTypes", accountid, companyid, authToken],
    queryFn: fetchContactTypes,
    enabled: !!accountid && !!companyid && !!authToken,
    placeholderData: [],
  });

  return { data, error, isLoading };
};

export default useContactTypes;
