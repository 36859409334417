import { useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handlePost } from '../../../utils/amplifyInstance';

// Third-party
import { useSnackbar } from 'notistack';

export const useInitialExcelData = (data, data2, accountid, companyid, authToken, statementid) => {
  const [enable, setEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!enable) {
      return;
    }

    let cancel = false;

    const postInitialExcelData = async () => {
      if (!data || !accountid || !companyid || !authToken || !statementid) {
        return;
      }

      try {
        const version = 'initial';

        // Filter out empty
        const filteredData = data.filter(
          (item) => item.employeelistid !== '' && item.CompanyNumber !== undefined
        );

        // Add data2 to filteredData if it exists and the array is not empty
        if (data2.length > 0) {
          const filteredData2 = data2.filter(
            (item) => item.employeelistid !== '' && item.CompanyNumber !== undefined
          );
          filteredData.push(...filteredData2);
        }
        // Improved URL construction with template literals
        const response = await handlePost({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${companyid}/excelfile/${version}/${statementid}`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: filteredData,
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        if (json.result === -1) {
          enqueueSnackbar(json.message, { variant: 'error' });
          setEnable(false);
        } else {
          enqueueSnackbar('Successfully stored initial file data.', {
            variant: 'success',
          });
          setEnable(false);
        }
      } catch (error) {
        console.error(error);
        const errorMessage = error.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setEnable(false);
      }
      setIsLoading(false);
    };

    postInitialExcelData();

    return () => {
      cancel = true;
    };
  }, [enable, data, data2, accountid, companyid, authToken, statementid, enqueueSnackbar]);

  return { enable, setEnable, isLoading };
};
