import React, { useState } from 'react';

// Material-UI
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// Icons 
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

const ErrorBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: "#f5bcbc",
  color: "#650003",
  position: 'relative',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const ErrorBox = ({ message }) => {
  const [open, setOpen] = useState(true);

  if (!open) {
    return null;
  }

  return (
    <ErrorBoxContainer>
      <Box display="flex" alignItems="center">
        <ErrorIcon style={{ color: '#650003', marginRight: "15px" }} />
        <Typography variant="body1">{message}</Typography>
      </Box>
      <CloseIconButton
        size="small"
        onClick={() => setOpen(false)}
      >
        <CloseIcon />
      </CloseIconButton>
    </ErrorBoxContainer>
  );
};

export default ErrorBox;