const datePatterns = [
    'd-MMM-yyyy',    // e.g., 3-Feb-2023
    'd-MMM-yy',      // e.g., 3-Feb-23
    'dd-MMM-yyyy',   // e.g., 12-Jan-2022
    'dd-MMM-yy',     // e.g., 03-Feb-23
    'd-MM-yyyy',     // e.g., 3-01-2022
    'd-MM-yy',       // e.g., 3-01-22
    'dd-MM-yyyy',    // e.g., 12-01-2022
    'dd-MM-yy',      // e.g., 12-01-22
    'd/M/yyyy',      // e.g., 3/2/2023
    'd/MM/yyyy',     // e.g., 3/01/2023
    'd/M/yy',        // e.g., 3/2/23
    'd/MM/yy',       // e.g., 3/01/23
    'dd-MMM-yyyy',   // e.g., 12-Jan-2022
    'dd-MMM-yy',     // e.g., 03-Feb-23
    'dd-MM-yyyy',    // e.g., 12-01-2022
    'dd-MM-yy',      // e.g., 12-01-22
    'dd/MM/yyyy',    // e.g., 12/01/2022
    'dd/MM/yy',      // e.g., 12/01/22
    'MM-dd-yyyy',    // e.g., 01-12-2022
    'MM-dd-yy',      // e.g., 01-12-22
    'MM/dd/yyyy',    // e.g., 01/12/2022
    'MM/dd/yy',      // e.g., 01/12/22
    'yyyy-MM-dd',    // e.g., 2022-01-12
    'yy-MM-dd',      // e.g., 22-01-12
    'yyyy/MM/dd',    // e.g., 2022/01/12
    'yy/MM/dd',      // e.g., 22/01/12
    'dd MMM yyyy',   // e.g., 12 Jan 2022
    'MMM dd, yyyy',  // e.g., Jan 12, 2022
    'yyyy MMM dd',   // e.g., 2022 Jan 12
    'yy MMM dd',     // e.g., 22 Jan 12
  ];

export default datePatterns;
  