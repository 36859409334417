import React, { useState, Fragment } from "react";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Hook
import usePlanDetails from "../../hooks/usePlanDetails";

// Material-UI
import { TextField } from "@mui/material";

// DevExtreme React Grid
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

// DevExtreme React Grid - Data Type Provider
import { DataTypeProvider } from "@devexpress/dx-react-grid";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";
import ErrorBox from "../../ui-components/NotificationErrorBox";

const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: "bold",
    }}
  >
    {children}
  </TableHeaderRow.Content>
);

const DateFormatter = ({ value }) =>
  value ? (
    <TextField
      type="date"
      value={value}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  ) : (
    ""
  );

// ===========================|| PLAN DETAILS  ||=========================== //

function PlanDetails(props) {
  const { authToken } = useSession();
  const { accountid, companyid } = props;

  const [columns] = useState([
    { title: "Plan Type", name: "plantype" },
    { title: "Plan Status", name: "status" },
    { title: "Plan Status Effective", name: "statuseffective" },
    { title: "Company Contribution Rate", name: "companyrate" },
    { title: "Employee Contribution Rate", name: "employeerate" },
    { title: "Rate Effective", name: "rateeffective" },
    { title: "Contract Expiry", name: "ratexpires" },
  ]);
  const [columnExtensions] = useState([
    { columnName: "companyrate", align: "right" },
    { columnName: "employeerate", align: "right" },
  ]);

  // Hook - Fetch Plan Details
  const { data, error, isLoading } = usePlanDetails(
    accountid,
    companyid,
    authToken
  );

  const TableRow = ({ row, ...restProps }) => {
    return (
      <VirtualTable.Row
        {...restProps}
        sx={{
          height: "0px",
        }}
      />
    );
  };

  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingScreen text="Loading Plan Information" />
      ) : error ? (
        <ErrorBox message="Error fetching Plan Information" />
      ) : (
        <Grid rows={data} columns={columns}>
          <DataTypeProvider
            for={["statuseffective", "rateeffective", "ratexpires"]}
            formatterComponent={DateFormatter}
          />
          <VirtualTable
            height="auto"
            columnExtensions={columnExtensions}
            rowComponent={TableRow}
          />
          <TableHeaderRow contentComponent={TableHeaderContent} />
        </Grid>
      )}
    </Fragment>
  );
}

export default PlanDetails;
