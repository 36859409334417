import React, { useState, useEffect, Fragment } from "react";

// Main Context
import { useMainContext } from "../../context/MainContext";

// Material UI
import { styled } from "@mui/material/styles";
import {
  Button,
  Paper,
  Box,
  Stack,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Helper Functions
import {
  UploadFileMultipleAttachments,
  PostMessage,
  validateFileType,
  validateFileSize,
} from "../../common/HelperFunctions";

// Icons
import AttachmentIcon from "@mui/icons-material/Attachment";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CloseIcon from "@mui/icons-material/Close";


// Third Party Library
import { format } from "date-fns";
import { useSnackbar } from "notistack";

// ===========================|| MESSAGE CREATE ||=========================== //

function MessageCreate() {
  const replyTo = null;
  const { authToken } = useSession();
  const { account, companyid } = useMainContext();
  const accountid = account.map((i) => i.accountid)[0];
  const cognito_identity_uuid = account.map((i) => i.cognito_identity_uuid)[0];

  const { enqueueSnackbar } = useSnackbar();

  const [note, setNote] = useState("");
  const [noteLength, setNoteLength] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);

  let upload = React.createRef();

  const HandleSubmit = async (e) => {
    try {
      let result = null;
      if (selectedFiles) {
        result = await UploadFileMultipleAttachments(selectedFiles, companyid, cognito_identity_uuid);
      }

      var attachmentInfo = [];
      if (result) {
        result.map((r) => {
          // check if the cognito_identity_uuid value is not null
          if (cognito_identity_uuid) {
            attachmentInfo.push({
              filelink: r,
            });
            enqueueSnackbar("File Uploaded successfully.", {
              variant: "success",
            });
          } else {
            // handle the case where cognito_identity_uuid is null
            enqueueSnackbar(
              "Upload failed, Please try to upload the file again!",
              { preventDuplicate: true, variant: "error" }
            );
          }
          return null;
        });
      }

      var message = {
        parentid: replyTo,
        viewedon: format(new Date(), "yyyyMMddHHmmss"),
        ownerid: parseInt(companyid),
        ownertypeid: 2,
        author: accountid.toString(),
        note: note,
        sender: "ERCONTACT",
        publicmessageid: null,
        attachments: attachmentInfo,
      };

      PostMessage(accountid, message, authToken)
        .then((response) => {
          enqueueSnackbar("Message sent successfully!", { variant: "success" });

          setNote("");
          setNoteLength(0);
        })
        .catch((error) => {
          enqueueSnackbar("Message failed: " + error, { variant: "error" });
        });
    } catch (e) {
      enqueueSnackbar("File Upload failed!" + e.message, { variant: "error" });
    }
  };

  // useEffect Hook that removes stops duplicate files to be displayed
  useEffect(() => {
    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);
  }, [selectedFiles]);

  // fileDrop method that access all the files which are being dragged and sends to handleFiles method
  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;

    if (files.length) {
      let filteredArray = [];
      for (var index = 0; index < files.length; index++) {
        let exists = false;
        for (var i = 0; i < selectedFiles.length; i++) {
          if (selectedFiles[i].name === files[index].name) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          filteredArray.push(files[index]);
        }
      }
      handleFiles(filteredArray);
    }
  };

  const filesSelected = (e) => {
    const files = e.target.files;
    if (files.length) {
      let filteredArray = [];
      for (var index = 0; index < files.length; index++) {
        let exists = false;
        for (var i = 0; i < selectedFiles.length; i++) {
          if (selectedFiles[i].name === files[index].name) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          filteredArray.push(files[index]);
        }
      }
      handleFiles(filteredArray);
    }
  };

  // handleFiles method that receives all the files being dragged
  // validates if the files are of correct type
  // sets the selectedFiles state if valid or sets stack error Message state variable
  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i]) && validateFileSize(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else if (!validateFileType("messagecreate", files[i])) {
        enqueueSnackbar("Attachment file type not allowed!", {
          variant: "error",
        });
      } else if (!validateFileSize(files[i])) {
        enqueueSnackbar("Attachment file size exceeds 5 MBytes!", {
          variant: "error",
        });
      } else {
        files[i]["invalid"] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        // enqueueSnackbar("Attachment file unsupported!", { variant: "error" });
        setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
      }
    }
  };

  // method that holds the valid allowed file types and checks if each of the file is valid or not
  const validateFile = (file) => {
    const validTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf",
      "application/zip",
      "image/tiff",
      "text/plain",
      "text/csv",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  // method that receives the file name and handles the deletion of the file from all state variables.
  const removeFile = (name) => {
    const index = validFiles.findIndex((e) => e.name === name);
    const index2 = selectedFiles.findIndex((e) => e.name === name);
    const index3 = unsupportedFiles.findIndex((e) => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  };

  // methods to prevent default browser bahavior on dragOver, dragEnter and dragLeave
  const preventDefault = (e) => {
    e.preventDefault();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const HandleNoteChange = (event) => {
    setNote(event.target.value);
    setNoteLength(event.target.value.length || 0);
  };

  // Attachment Custom Style
  const Attachment = styled(Paper)(({ theme }) => ({
    padding: "5px",
    textAlign: "left",
    backgroundColor: "transparent",
  }));

  return (
    <Fragment>
      <br />
      <input
        type="file"
        multiple
        value={""}
        style={{ display: "none" }}
        ref={(ref) => (upload = ref)}
        onChange={(e) => {
          filesSelected(e);
        }}
      />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            label="Enter your message (2000 characters max)"
            value={note}
            onChange={HandleNoteChange}
            multiline
            inputProps={{ maxLength: 2000, fontSize: "18px" }}
          />
        </Grid>
        <Box sx={{ p: 1 }} />
        <Grid item xs={12}>
          Chars: {noteLength}
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            size="small"
            variant="outlined"
            onClick={HandleSubmit}
            disabled={note.length <= 0}
            startIcon={
              replyTo == null ? (
                <SendIcon fontSize={"small"} />
              ) : (
                <ReplyIcon fontSize={"small"} />
              )
            }
          >
            {replyTo == null ? "Send" : "Reply"}
          </Button>
        </Grid>
        <Box sx={{ p: 3 }} />
        <Grid container spacing={1} justify="flex-start">
          <Grid item xs={12} sm={12} md={6} lg={12}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                borderWidth: 2,
                borderRadius: 0,
                borderColor: "#008522",
                borderStyle: "dashed",
                backgroundColor: "#fafafa",
                color: "#000000",
                outline: "none",
                transition: "border .24s ease-in-out",
              }}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
            >
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item>
                  <AttachmentIcon
                    sx={{
                      color: "#008522",
                      width: "59.1px",
                      height: "59.1px",
                      opacity: 0.2,
                      transform: "rotate(90deg)",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      textAlign: "left",
                      color: "#000",
                      fontFamily: `'PT Sans Narrow', sans-serif`,
                      fontSize: "16px",
                    }}
                  >
                    DRAG AND DROP ANY ATTACHMENTS OR SELECT FILES TO UPLOAD
                  </Box>
                  <Typography variant="allowFormatAttachment">
                    Allowed formats: PDF, JPG, DOC, DOCX, XLS, XLSX, PNG, TXT,
                    RTF, ZIP
                  </Typography>
                  <br />
                  <Typography variant="maximumSizeAttachment">
                    Maximum size: 5MB
                  </Typography>
                  <input
                    type="file"
                    multiple
                    value={""}
                    style={{ display: "none" }}
                    ref={(ref) => (upload = ref)}
                    onChange={(e) => {
                      filesSelected(e);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#008522",
                    }}
                  >
                    <Typography
                      variant="SelectFileButtonText"
                      onClick={(e) => {
                        upload.value = null;
                        upload.click();
                      }}
                    >
                      SELECT A FILE
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ p: 1 }} />
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            {validFiles.map((data, index) => (
              <Stack
                key={index}
                component={Paper}
                spacing={{ xs: 0, md: 1 }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  "&.MuiPaper-root": {
                    border: "none",
                  },
                }}
              >
                <Attachment>
                  <Button
                    size="large"
                    variant="text"
                    startIcon={
                      <UploadFileOutlinedIcon
                        fontSize={"medium"}
                        sx={{
                          color: "#2D2E2F",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                    }
                    sx={{ textTransform: "none" }}
                  >
                    <Typography variant="AttachmentNameText">
                      {data.name}
                    </Typography>
                    <CloseIcon
                      fontSize="small"
                      onClick={() => removeFile(data.name)}
                      sx={{
                        color: "#000",
                        paddingTop: "5px",
                        paddingLeft: "5px",
                      }}
                    />
                  </Button>
                </Attachment>
              </Stack>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default MessageCreate;
