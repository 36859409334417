
// Error code constant for AliasExistsException  
// Description : This exception is thrown when a user tries to confirm the account with an email or phone number that has already been used by another user.
export const ErrCodeAliasExistsException = "AliasExistsException";

// Error code constant for CodeDeliveryFailureException 
// Description : This exception is thrown when a verification code fails to deliver successfully.
export const ErrCodeCodeDeliveryFailureException = "CodeDeliveryFailureException";

// Error code constant for CodeMismatchException 
// Description : This exception is thrown if the provided code does not match what the server was expecting.
export const ErrCodeCodeMismatchException = "CodeMismatchException";

// Error code constant for ConcurrentModificationException 
// Description : This exception is thrown if two or more modifications are happening concurrently.
export const ErrCodeConcurrentModificationException = "ConcurrentModificationException";

// Error code constant for DuplicateProviderException
// Description : This exception is thrown if the provider you are trying to create already exists.
export const ErrCodeDuplicateProviderException = "DuplicateProviderException";

// Error code constant for EnableSoftwareTokenMFAException
// Description : This exception is thrown when there is an error with enabling software token MFA.
export const ErrCodeEnableSoftwareTokenMFAException = "EnableSoftwareTokenMFAException";

// Error code constant for ExpiredCodeException
// Description : This exception is thrown if a code has expired.
export const ErrCodeExpiredCodeException = "ExpiredCodeException";

// Error code constant for ForbiddenException
// Description : This exception is thrown when a user is not authorized to access the requested resource.
export const ErrCodeForbiddenException = "ForbiddenException";

// Error code constant for GroupExistsException
// Description : This exception is thrown when the group name is already in use.
export const ErrCodeGroupExistsException = "GroupExistsException";

// Error code constant for InternalErrorException
// Description : This exception is thrown when Amazon Cognito encounters an internal error.
export const ErrCodeInternalErrorException = "InternalErrorException";

// Error code constant for InvalidEmailRoleAccessPolicyException
// Description : This exception is thrown when Amazon Cognito is not allowed to use your email identity. HTTP status code: 400.
export const ErrCodeInvalidEmailRoleAccessPolicyException = "InvalidEmailRoleAccessPolicyException";

// Error code constant for InvalidLambdaResponseException
// Description : This exception is thrown when the Amazon Cognito service encounters an invalid AWS Lambda response.
export const ErrCodeInvalidLambdaResponseException = "InvalidLambdaResponseException";

// Error code constant for InvalidOAuthFlowException
// Description : This exception is thrown when the specified OAuth flow is invalid.
export const ErrCodeInvalidOAuthFlowException = "InvalidOAuthFlowException";

// Error code constant for InvalidParameterException
// Description : This exception is thrown when the Amazon Cognito service encounters an invalid parameter.
export const ErrCodeInvalidParameterException = "InvalidParameterException";

// Error code constant for InvalidPasswordException
// Description : This exception is thrown when the password is invalid.
export const ErrCodeInvalidPasswordException = "InvalidPasswordException";

// Error code constant for InvalidSmsRoleAccessPolicyException
// Description : This exception is thrown when the Amazon Cognito service encounters an invalid SMS role access policy.
export const ErrCodeInvalidSmsRoleAccessPolicyException = "InvalidSmsRoleAccessPolicyException";

// Error code constant for InvalidSmsRoleTrustRelationshipException
// Description : This exception is thrown when the trust relationship is invalid for the role provided for SMS configuration.
export const ErrCodeInvalidSmsRoleTrustRelationshipException = "InvalidSmsRoleTrustRelationshipException";

// Error code constant for InvalidUserPoolConfigurationException
// Description : This exception is thrown when the user pool configuration is invalid.
export const ErrCodeInvalidUserPoolConfigurationException = "InvalidUserPoolConfigurationException";

// Error code constant for LimitExceededException
// Description : This exception is thrown when a limit is exceeded.
export const ErrCodeLimitExceededException = "LimitExceededException";

// Error code constant for MFAMethodNotFoundException
// Description : This exception is thrown when Amazon Cognito cannot find a multi-factor authentication (MFA) method.
export const ErrCodeMFAMethodNotFoundException = "MFAMethodNotFoundException";

// Error code constant for NotAuthorizedException
// Description : This exception is thrown when a user is not authorized.
export const ErrCodeNotAuthorizedException = "NotAuthorizedException";

// Error code constant for PasswordResetRequiredException
// Description : This exception is thrown when a password reset is required.
export const ErrCodePasswordResetRequiredException = "PasswordResetRequiredException";

// Error code constant for PreconditionNotMetException
// Description : This exception is thrown when a precondition is not met.
export const ErrCodePreconditionNotMetException = "PreconditionNotMetException";

// Error code constant for ResourceNotFoundException
// Description : This exception is thrown when the Amazon Cognito service cannot find the requested resource.
export const ErrCodeResourceNotFoundException = "ResourceNotFoundException";

// Error code constant for ScopeDoesNotExistException
// Description : This exception is thrown when the specified scope does not exist.
export const ErrCodeScopeDoesNotExistException = "ScopeDoesNotExistException";

// Error code constant for SoftwareTokenMFANotFoundException
// Description : This exception is thrown when the software token multi-factor authentication (MFA) is not enabled for the user pool.
export const ErrCodeSoftwareTokenMFANotFoundException = "SoftwareTokenMFANotFoundException";

// Error code constant for TooManyFailedAttemptsException
// Description : This exception is thrown when the user has made too many failed attempts for a given action (e.g., sign in).
export const ErrCodeTooManyFailedAttemptsException = "TooManyFailedAttemptsException";

// Error code constant for TooManyRequestsException
// Description : This exception is thrown when the request is throttled.
export const ErrCodeTooManyRequestsException = "TooManyRequestsException";

// Error code constant for UnauthorizedException
// Description : This exception is thrown when the user is not authorized.
export const ErrCodeUnauthorizedException = "UnauthorizedException";

// Error code constant for UnexpectedLambdaException
// Description : This exception is thrown when the Amazon Cognito service encounters an unexpected exception with the AWS Lambda service.
export const ErrCodeUnexpectedLambdaException = "UnexpectedLambdaException";

// Error code constant for UnsupportedIdentityProviderException
// Description : This exception is thrown when the identity provider is not supported.
export const ErrCodeUnsupportedIdentityProviderException = "UnsupportedIdentityProviderException";

// Error code constant for UnsupportedOperationException
// Description : This exception is thrown when the requested operation is not supported.
export const ErrCodeUnsupportedOperationException = "UnsupportedOperationException";

// Error code constant for UnsupportedTokenTypeException
// Description : This exception is thrown when the token type is not supported.
export const ErrCodeUnsupportedTokenTypeException = "UnsupportedTokenTypeException";

// Error code constant for UnsupportedUserStateException
// Description : This exception is thrown when the user is in an unsupported state.
export const ErrCodeUnsupportedUserStateException = "UnsupportedUserStateException";

// Error code constant for UserImportInProgressException
// Description : This exception is thrown when you are trying to import a user but the user's import job is not complete.
export const ErrCodeUserImportInProgressException = "UserImportInProgressException";

// Error code constant for UserLambdaValidationException
// Description : This exception is thrown when a user's request is not valid.
export const ErrCodeUserLambdaValidationException = "UserLambdaValidationException";

// Error code constant for UserNotConfirmedException
// Description : This exception is thrown when a user is not confirmed successfully.
export const ErrCodeUserNotConfirmedException = "UserNotConfirmedException";

// Error code constant for UserNotFoundException
// Description : This exception is thrown when a user is not found.
export const ErrCodeUserNotFoundException = "UserNotFoundException";

// Error code constant for UserPoolAddOnNotEnabledException
// Description : This exception is thrown when user pool add-ons are not enabled.
export const ErrCodeUserPoolAddOnNotEnabledException = "UserPoolAddOnNotEnabledException";

// Error code constant for UserPoolTaggingException
// Description : This exception is thrown when the request failed because the user pool tag was invalid.
export const ErrCodeUserPoolTaggingException = "UserPoolTaggingException";

// Error code constant for UsernameExistsException 
// Description : This exception is thrown when a username is already in use.
export const ErrCodeUsernameExistsException = "UsernameExistsException";


// Function to identify the error code and return the code name 
export async function ExceptionErrorCode(error) {
    const errorString = error.toString();
    const regex = /^([^:]+):/;
    const match = errorString.match(regex);

    if (match) {
        const errorCode = match[1];
        switch (errorCode) {
            case "AliasExistsException":
                return ErrCodeAliasExistsException;
            case "CodeDeliveryFailureException":
                return ErrCodeCodeDeliveryFailureException;
            case "CodeMismatchException":
                return ErrCodeCodeMismatchException;
            case "ConcurrentModificationException":
                return ErrCodeConcurrentModificationException;
            case "DuplicateProviderException":
                return ErrCodeDuplicateProviderException;
            case "EnableSoftwareTokenMFAException":
                return ErrCodeEnableSoftwareTokenMFAException;
            case "ExpiredCodeException":
                return ErrCodeExpiredCodeException;
            case "ForbiddenException":
                return ErrCodeForbiddenException;
            case "GroupExistsException":
                return ErrCodeGroupExistsException;
            case "InternalErrorException":
                return ErrCodeInternalErrorException;
            case "InvalidEmailRoleAccessPolicyException":
                return ErrCodeInvalidEmailRoleAccessPolicyException;
            case "InvalidLambdaResponseException":
                return ErrCodeInvalidLambdaResponseException;
            case "InvalidOAuthFlowException":
                return ErrCodeInvalidOAuthFlowException;
            case "InvalidParameterException":
                return ErrCodeInvalidParameterException;
            case "InvalidPasswordException":
                return ErrCodeInvalidPasswordException;
            case "InvalidSmsRoleAccessPolicyException":
                return ErrCodeInvalidSmsRoleAccessPolicyException;
            case "InvalidSmsRoleTrustRelationshipException":
                return ErrCodeInvalidSmsRoleTrustRelationshipException;
            case "InvalidUserPoolConfigurationException":
                return ErrCodeInvalidUserPoolConfigurationException;
            case "LimitExceededException":
                return ErrCodeLimitExceededException;
            case "MFAMethodNotFoundException":
                return ErrCodeMFAMethodNotFoundException;
            case "NotAuthorizedException":
                return ErrCodeNotAuthorizedException;
            case "PasswordResetRequiredException":
                return ErrCodePasswordResetRequiredException;
            case "PreconditionNotMetException":
                return ErrCodePreconditionNotMetException;
            case "ResourceNotFoundException":
                return ErrCodeResourceNotFoundException;
            case "ScopeDoesNotExistException":
                return ErrCodeScopeDoesNotExistException;
            case "SoftwareTokenMFANotFoundException":
                return ErrCodeSoftwareTokenMFANotFoundException;
            case "TooManyFailedAttemptsException":
                return ErrCodeTooManyFailedAttemptsException;
            case "TooManyRequestsException":
                return ErrCodeTooManyRequestsException;
            case "UnauthorizedException":
                return ErrCodeUnauthorizedException;
            case "UnexpectedLambdaException":
                return ErrCodeUnexpectedLambdaException;
            case "UnsupportedIdentityProviderException":
                return ErrCodeUnsupportedIdentityProviderException;
            case "UnsupportedOperationException":
                return ErrCodeUnsupportedOperationException;
            case "UnsupportedTokenTypeException":
                return ErrCodeUnsupportedTokenTypeException;
            case "UnsupportedUserStateException":
                return ErrCodeUnsupportedUserStateException;
            case "UserImportInProgressException":
                return ErrCodeUserImportInProgressException;
            case "UserLambdaValidationException":
                return ErrCodeUserLambdaValidationException;
            case "UserNotConfirmedException":
                return ErrCodeUserNotConfirmedException;
            case "UserNotFoundException":
                return ErrCodeUserNotFoundException;
            case "UserPoolAddOnNotEnabledException":
                return ErrCodeUserPoolAddOnNotEnabledException;
            case "UserPoolTaggingException":
                return ErrCodeUserPoolTaggingException;
            case "UsernameExistsException":
                return ErrCodeUsernameExistsException;
            default:
                return "Unknown error.";
        }
    } else {
        return error;
    }
}


