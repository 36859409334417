import React, { useState, Fragment } from 'react';

// Material-UI
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  OutlinedInput,
} from '@mui/material';

// Custom Styles
import { customInput } from '../../../../theme/Typography';

// Project Imports
import { useUser } from '../../../../context/AuthContext';
import ErrorBox from '../../../../ui-components/NotificationErrorBox';

// Helper functions
import { validateCellNumber, formatPhoneNumber } from '../../../../common/HelperFunctions';

// Third-party
import * as Yup from 'yup';
import { Formik } from 'formik';

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// ===========================|| REGISTRATION FORM ||=========================== //

const AwsCognitoRegister = ({ handleNext, setRegisterData }) => {
  // Theme
  const theme = useTheme();

  const { handleSignUp } = useUser();

  // Password Visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Cell Number Value
  const [inputPhoneValue, setInputValue] = useState('');

  // Cell Number handle format
  const changePhoneNumber = (value) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    setInputValue(formattedPhoneNumber);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Fragment>
      <Box sx={{ maxWidth: 400, margin: '0 auto' }}>
        <Formik
          initialValues={{
            accountID: '',
            password: '',
            confirmPassword: '',
            email: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            accountID: Yup.string()
              .required('Account ID is required')
              .min(8, 'Your Account ID needs to be 8 digits number')
              .max(8, 'Your Account ID needs to be 8 digits number'),
            password: Yup.string().required('Password is required'),
            confirmPassword: Yup.string()
              .required('Confirm Password is required')
              .oneOf([Yup.ref('password'), null], 'Confirm Password must match password'),
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            cellNumber: Yup.string()
              .matches(/^\S*$/, 'Spaces are not allowed')
              .matches(/^\d{3}\.\d{3}\.\d{4}$/, 'Must be a valid phone number in the format 250.123.4567'),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            const phoneNumber = inputPhoneValue !== "" ? inputPhoneValue.replace(/\s+/g, '') : inputPhoneValue;
            const phoneNumberValidation = validateCellNumber(phoneNumber);
            try {
              await handleSignUp({
                username: values.accountID.trim(),
                password: values.password.trim(),
                email: values.email.trim(),
                phone_number: phoneNumberValidation,
                website: process.env.VITE_REACT_APP_STAGE,
              });
              setRegisterData({
                accountID: values.accountID,
                email: values.email,
              });
              handleNext();
              setStatus({ success: true });
              setSubmitting(false);
            } catch (error) {
              console.error(error);
              setStatus({ success: false });
              setErrors({ submit: error.message });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                error={Boolean(touched.accountID && errors.accountID)}
                sx={customInput}
              >
                <InputLabel htmlFor="accountID">Account ID</InputLabel>
                <OutlinedInput
                  id="accountID"
                  name="accountID"
                  text="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.accountID}
                  placeholder="Account ID"
                />
                {touched.accountID && errors.accountID && (
                  <FormHelperText error>{errors.accountID}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={customInput}
              >
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  placeholder="Password"
                  autoComplete="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.password && errors.password && (
                  <FormHelperText error>{errors.password}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                sx={customInput}
              >
                <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                <OutlinedInput
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  placeholder="Confirm Password"
                  autoComplete="confirmPassword"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText error>{errors.confirmPassword}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={customInput}
              >
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  name="email"
                  type="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  placeholder="Email"
                />
                {touched.email && errors.email && (
                  <FormHelperText error>{errors.email}</FormHelperText>
                )}
              </FormControl>
              {/* Cell Number Start */}
              <FormControl
                fullWidth
                error={Boolean(touched.cellNumber && errors.cellNumber)}
                sx={customInput}
              >
                <InputLabel htmlFor="cellNumber">Cell Number</InputLabel>
                <OutlinedInput
                  id="cellNumber"
                  name="cellNumber"
                  type="phone"
                  value={inputPhoneValue !== "" ? inputPhoneValue.replace(/\s+/g, '') : inputPhoneValue}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    changePhoneNumber(e.target.value);
                  }}
                  placeholder="Cell Number"
                />
                {touched.cellNumber && errors.cellNumber && (
                  <FormHelperText error>{errors.cellNumber}</FormHelperText>
                )}
              </FormControl>
              <Box display="flex" justifyContent="flex-start">
                <Typography variant="body2">
                  Your mobile number is used for additional security on your account.
                </Typography>
              </Box>
              {/* Cell Number End */}
              <Box sx={{ m: 1 }} />
              {errors.submit && <ErrorBox message={errors.submit} />}
              <Box sx={{ mt: 2 }}>
                <Button
                  disableElevation
                  fullWidth
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: '#008521',
                    color: theme.palette.primary.contrastText,
                    '&:hover': { backgroundColor: '#008521' },
                  }}
                >
                  Create Account
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Fragment>
  );
};

export default AwsCognitoRegister;
