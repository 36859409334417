import React, { Fragment } from "react";

// Material UI
import { styled } from "@mui/material/styles";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  Select,
  MenuItem,
  Typography,
  ListItemIcon,
  SvgIcon,
  Stack,
  Tooltip,
} from "@mui/material";

// Icons
import CheckCircle from "@mui/icons-material/CheckCircle";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import InfoIcon from "@mui/icons-material/Info";

// Third-party
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  column: yup.string().required("Please select a column"),
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #80bdff",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#008522",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const MatchedColumnSelect = ({
  name,
  options,
  columnIndex,
  selectedValue,
  setSelectedValue,
  onChange,
  isChecked,
}) => {
  const formik = useFormik({
    initialValues: {
      column: selectedValue,
    },
    validationSchema,
    onSubmit: (values) => {
      setSelectedValue(values.column);
    },
  });

  const handleColumnChange = (event) => {
    onChange(event.target.value, columnIndex);
  };

  const handleChangeAndColumnChange = (event) => {
    setSelectedValue(event.target.value);
    handleColumnChange(event);
  };

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <Select
                  labelId="column-select-label"
                  id="column"
                  name="column"
                  value={selectedValue}
                  onChange={handleChangeAndColumnChange}
                  label={name}
                  input={<BootstrapInput />}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}{" "}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.column && formik.errors.column && (
                  <FormHelperText error>{formik.errors.column}</FormHelperText>
                )}
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ListItemIcon>
              {isChecked && <CheckCircle color="primary" />}
              <Typography variant="body1" color="textPrimary" ml={1}>
                {selectedValue === ""
                  ? "Please match a column to the selected field."
                  : `Matched to the ${name} field.`}
              </Typography>
            </ListItemIcon>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <SvgIcon fontSize="medium" color="action">
                <Tooltip title="Column Name">
                  <InfoIcon />
                </Tooltip>
              </SvgIcon>
              <Typography variant="body1" color="textPrimary">
                {selectedValue === ""
                  ? "Rows are available, but no column has been selected."
                  : "100% of your rows have a value for this column."}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <SvgIcon fontSize="medium" color="action">
                <Tooltip title="Column Name">
                  {selectedValue === "" ? (
                    <UnpublishedOutlinedIcon color="error" />
                  ) : (
                    <CheckOutlinedIcon color="primary" />
                  )}
                </Tooltip>
              </SvgIcon>
              <Typography variant="body1" color="textPrimary">
                {selectedValue === ""
                  ? "No values are align with the column seleted."
                  : `All values aligns with the ${name} field.`}
              </Typography>
            </Stack>
          </Grid>
          <Box sx={{ p: 3.8 }} />
        </Grid>
      </form>
    </Fragment>
  );
};

export default MatchedColumnSelect;
