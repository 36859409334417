import { useMemo } from 'react';

const useWarningDetection = (data) => {
  return useMemo(() => {
    return data.find((value) => {
      if (value?.__warnings) {
        return Object.values(value.__warnings)?.filter((warn) => warn.level === 'warning').length;
      }
      return false;
    });
  }, [data]);
};

export default useWarningDetection;
