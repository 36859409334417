import React, { useEffect, useCallback, Fragment } from 'react';

// React Router
import { Outlet, useNavigate } from 'react-router-dom';

// Material-UI
import { Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

// Auth Context
import { useUser } from '../../context/AuthContext';

// Session Context
import { useSession } from '../../context/AuthSession';

// Main Context
import { useMainContext } from '../../context/MainContext';

// Sentry
import { ErrorBoundary } from '@sentry/react';

// Project Imports
import AdminDialog from '../../ui-components/AdminDialog';
import CustomLoadingScreen from '../../ui-components/CustomLoadingScreen';

// NavBar
import IWANavBar from '../navbar/IWANavBar';

// SideBar
import IWASideBar from '../sidebar/IWASideBar';

// Company Information
import CompanyInformation from '../company-information/CompanyInformation';

const drawerWidth = 220;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? `${drawerWidth}px` : '0',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const IdleSessionLogout = ({ signOut }) => {
  const signoutTime = 1000 * 60 * 15;
  let logoutTimeout;

  const logout = () => {
    signOut();
  };

  const setTimeouts = () => {
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (logoutTimeout) {
      clearTimeout(logoutTimeout);
    }
  };

  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  });

  return null;
};

const Home = () => {
  const { authToken } = useSession();

  const { handleSignOut } = useUser();

  const navigate = useNavigate();

  const {
    account,
    accountid,
    setAccountId,
    companyid,
    setCompanyId,
    drawerOpen,
    setDrawerOpen,
    isLoading,
    isValid,
    openDialog,
    setOnlyLTD,
  } = useMainContext();

  const authSignOut = useCallback(async () => {
    try {
      await handleSignOut();
      navigate('/login', { replace: true });
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, [handleSignOut, navigate]);

  // Check is the Auth Token is valid
  useEffect(() => {
    let cancel = false;

    const checkAuthTokenAccount = async () => {
      if (!accountid) {
        return;
      }

      try {
        if (!authToken) {
          authSignOut();
          return;
        }

        if (cancel) {
          return;
        }
      } catch (e) {
        console.error('Error checking auth token:', e);
      }

      return () => {
        cancel = true;
      };
    };

    checkAuthTokenAccount();
  }, [accountid, authToken, authSignOut]);

  return (
    <Fragment>
      {openDialog ? (
        <AdminDialog open={openDialog} isValid={isValid} setAccountId={setAccountId} />
      ) : isLoading ? (
        <CustomLoadingScreen text="Loading Account Information" />
      ) : (
        <Fragment>
          <IdleSessionLogout signOut={handleSignOut} />
          <IWANavBar
            accountid={account.map((i) => i.accountid)[0]}
            email={account.map((i) => i.email)}
            setCompanyId={setCompanyId}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            signOut={handleSignOut}
          />

          <IWASideBar
            accountid={account.map((i) => i.accountid)[0]}
            companyid={companyid}
            roleid={account.map((i) => i.roleid)[0]}
            draweropen={drawerOpen}
          />
          <Main open={drawerOpen}>
            <Toolbar sx={{ height: '72px' }} />
            <ErrorBoundary fallback={'An error has occurred'}>
              <CompanyInformation
                accountid={account.map((i) => i.accountid)[0]}
                companyid={companyid}
                setOnlyLTD={setOnlyLTD}
              />
            </ErrorBoundary>
            <br />
            <ErrorBoundary fallback={'An error has occurred'}>
              <Outlet />
            </ErrorBoundary>
            <br />
          </Main>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
