// Project Imports
import React from 'react';
import Authentication from "../components/authentication/amplify/index";

// ===========================|| AUTHENTICATION ROUTES ||=========================== //

const AuthenticationRoutes = {
    path: '/login',
    element: (
        <Authentication />
    ),
};

export default AuthenticationRoutes;