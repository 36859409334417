import React, { Fragment } from "react";

// Material UI
import { Grid } from "@mui/material";

const ColumnGrid = ({ columns, userColumn, templateColumn }) => {
  return (
    <Fragment>
      <Grid container spacing={3} sx={{ m: 0.5 }}>
        {/* User Columns */}
        <Grid item xs={6}>
          {columns.map((column, index) => (
            <div key={column.header + index}>{userColumn(column)}</div>
          ))}
        </Grid>

        {/* Template Columns */}
        <Grid item xs={6}>
          {columns.map((column, index) => (
            <div key={column.header + index}>{templateColumn(column)}</div>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ColumnGrid;