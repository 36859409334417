import { useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet } from '../utils/amplifyInstance';

// Auth Session
import { useSession } from '../context/AuthSession';

const useContactInfoForm = (submitData, validate, props, reload) => {
  const { authToken } = useSession();
  const { accountid, companyid, contactid } = props;
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      if (!accountid || !companyid || !contactid || !authToken) {
        return;
      }

      try {
        // var response = await get("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/contact/" + contactid);
        var response = await handleGet({
          apiName: "EmployerApiGw",
          path: `/employer/${accountid}/${companyid}/contact/${contactid}`,
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        response = JSON.parse(
          JSON.stringify(json, (k, v) => {
            return v == null ? "" : v;
          })
        );

        setData(response.items[0]);
      } catch (e) {
        console.log(e);
        setData({});
      }

      setErrors({});
      setIsSubmitting(false);
      setIsLoading(false);
    };

    fetchData();

    return () => {
      cancel = true;
      sessionStorage.removeItem('contacttypes');
    }
  }, [reload, accountid, companyid, contactid, authToken]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setIsSubmitting(false);
      submitData();
    }
  }, [errors, isSubmitting, submitData]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setErrors(validate(data));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();

    var value = "";

    const target = event.target;
    const name = target.name;
    if (name === "surname" || name === "givennames" || name === "attn"
      || name === "address1" || name === "address2" || name === "city" || name === "postalcode") {
        value = target.value.toUpperCase();
    } else if (target.type === 'checkbox') {
      value = target.checked ? "Y" : "N";
    } else {
      value = target.value;
    }

    setData(data => ({ ...data, [name]: value }));
  };

  const handleLOVChange = (name, value) => {
    setData({...data, [name]: value });
  };

  return {
    isLoading,
    handleChange,
    handleLOVChange,
    handleSubmit,
    data,
    errors
  }
};

export default useContactInfoForm;