import React from 'react';

// AWS Amplify
import { updatePassword } from 'aws-amplify/auth';

// Material-UI
import { Typography, Tooltip, IconButton, InputAdornment, TextField } from '@mui/material';

import { validatePassword } from '../../common/ValidationRules';
import useChangePasswordForm from '../../common/useChangePasswordForm';
import { isUndefined } from '../../common/HelperFunctions';

// Notistack
import { useSnackbar } from 'notistack';

// Icons
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function ChangePassword(props) {
  const { setOpenDialog } = props;
  const { enqueueSnackbar } = useSnackbar();

  // Submit Values function - Change Password
  const submitValues = async () => {
    try {
      await updatePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      enqueueSnackbar('Password changed successfully!', { variant: 'success' });
    } catch (err) {
      if (err.message === 'Incorrect username or password.') {
        return;
      } else {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    } finally {
      setOpenDialog(false);
    }
  };

  const { values, errors, handleChange, handleSubmit, showPassword, handleClickShowPassword } =
    useChangePasswordForm(submitValues, validatePassword);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Typography variant="subtitle2">
        <b>Change your password</b>
      </Typography>
      <TextField
        fullWidth
        autoComplete="current-password"
        label="Old Password"
        onChange={handleChange}
        name="oldPassword"
        type="password"
        value={values.oldPassword}
        error={!isUndefined(errors.oldPassword)}
        helperText={errors.oldPassword}
      />
      <br />
      <br />
      <TextField
        fullWidth
        autoComplete="new-password"
        label="New Password"
        onChange={handleChange}
        name="newPassword"
        value={values.newPassword}
        error={!isUndefined(errors.newPassword)}
        helperText={errors.newPassword}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} size="large">
                {showPassword ? (
                  <VisibilityIcon fontSize={'small'} />
                ) : (
                  <VisibilityOffIcon fontSize={'small'} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <br />
      <br />
      <TextField
        fullWidth
        autoComplete="new-password"
        label="Repeat Password"
        onChange={handleChange}
        name="repeatPassword"
        type={showPassword ? 'text' : 'password'}
        value={values.repeatPassword}
        error={!isUndefined(errors.repeatPassword)}
        helperText={errors.repeatPassword}
      />
      <br />
      <br />
      <br />
      <div align="right">
        <Tooltip title="Save Changes">
          <IconButton type="submit" color="primary" size="large">
            <SaveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel Changes">
          <IconButton
            color="secondary"
            onClick={(event) => {
              setOpenDialog(false);
            }}
            size="large"
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </div>
    </form>
  );
}

export default ChangePassword;
