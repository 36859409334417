import React, { useState, useCallback, useEffect } from "react";

// AWS Amplify - API - Request Handler
import { handleGet, handlePost } from "../../../../utils/amplifyInstance";

// Auth Session
import { useSession } from '../../../../context/AuthSession';

// Material UI
import {
  Button,
  Box,
  Card,
  CircularProgress,
  Stack,
  TableContainer,
  Typography,
  CardHeader,
  Divider,
} from "@mui/material";

// Fields
import { fields } from "../../fields";

// Hooks
import useUnmatchedRequiredFields from "../../hooks/useUnmatchedRequiredFields";

// Projects Import
import ColumnGrid from "./components/ColumnGrid";
import EmployerTableColumn from "./components/EmployerTableColumn";
import TemplateColumn from "../../components/TemplateColumn";
import UnmatchedFieldsCollapse from "../../components/Collapse/UnmatchedFieldsCollapse";
import InfoBox from "../../components/Info/InfoBox";

// Utils
import { getMatchedColumns } from "./utils/getMatchedColumns";
import { setColumn } from "./utils/setColumn";
import { normalizeTableData } from "./utils/normalizeTableData";

// Third-party
import { useSnackbar } from "notistack";

// Define Column Type
export const ColumnType = {
  empty: "empty",
  ignored: "ignored",
  matched: "matched",
  matchedCheckbox: "matchedCheckbox",
  matchedSelect: "matchedSelect",
};

// Explanations for the user
const descriptionList = [
  "Please ensure your data matches the EmployerConnect column headers by making a selection in the drop-down menus.",
  "If a column header is empty, please ignore it by clicking X in the right corner of the green band.",
  "If a column header does not match any items in the drop-down list, please ignore it by clicking X in the right corner of the green band.",
  "If required column(s) are either mismatched, empty or ignored, please fix by selecting the correct column name(s) from the dropdown list..",
];

const MatchColumnsFile = ({
  handleNext,
  rows,
  accountid,
  statementRow,
  companyid,
  headerRowData,
  employeeData,
  employeeValidationData,
  setValidateData,
  setEmployeeActiveCheckList,
}) => {
  // Auth Context
  const { authToken } = useSession();

  // State Variables
  const dataExample = employeeData.slice(0, 3);
  const [newValidateData, setNewValidateData] = useState([]);
  const [isMatchingDataLoading, setIsMatchingDataLoading] = useState(false);
  const [showUnmatchedFieldsAlert, setShowUnmatchedFieldsAlert] = useState(false);
  const [isValidateStatement, setIsValidateStatement] = useState(false);
  const [isProcessComplete, setProcessComplete] = useState(false);
  const [companyNumber, setCompanyNumber] = useState({});

  const autoMapHeaders = true;
  const autoMapDistance = 1;

  // Notistack
  const { enqueueSnackbar } = useSnackbar();

  // Initial Columns
  const initialColumns = headerRowData.map((column, index) => ({
    type: ColumnType.empty,
    index,
    header: column.title || "",
  }));

  // Columns State
  const [columns, setColumns] = useState(initialColumns);

  // On Change Handler for the Template Column Select
  const onChange = useCallback(
    (value, columnIndex) => {
      const field = fields.find((field) => field.key === value);
      const existingFieldIndex = columns.findIndex(
        (column) => "value" in column && column.value === field.key
      );

      setColumns((prevColumns) =>
        prevColumns.map((column, index) => {
          if (columnIndex === index) {
            return setColumn(column, field, rows);
          } else if (index === existingFieldIndex) {
            enqueueSnackbar(
              "Another column unselected. Columns cannot duplicate",
              {
                variant: "warning",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }
            );
            return setColumn(column);
          }
          return column;
        })
      );
    },
    [columns, rows, enqueueSnackbar]
  );

  // Ignore Column Handler
  const setIgnoreColumn = (column) => ({
    ...column,
    type: ColumnType.ignored,
  });

  // Ignore Column Handler
  const handleOnIgnore = useCallback(
    (columnIndex) => {
      setColumns((prevColumns) =>
        prevColumns.map((column, index) =>
          columnIndex === index ? setIgnoreColumn(column) : column
        )
      );
    },
    [setColumns]
  );

  // Revert Ignore Column Handler
  const onRevertIgnore = useCallback(
    (columnIndex) => {
      setColumns((prevColumns) =>
        prevColumns.map((column, index) =>
          index === columnIndex ? { ...column, type: ColumnType.empty } : column
        )
      );
    },
    [setColumns]
  );

  // Get Unmatched Required Fields
  const unmatchedRequiredFields = useUnmatchedRequiredFields(fields, columns);

  // Handle Next useCallback
  const handleValidateStep = useCallback(() => {
    handleNext();
  }, [handleNext]);

  // Check if the process is complete
  useEffect(() => {
    let cancel = false;

    const trackOperationStatus = () => {
      if (cancel) {
        return;
      }

      if (!isProcessComplete) {
        return;
      } else if (isProcessComplete && isValidateStatement) {
        handleValidateStep();
      } else {
        setIsMatchingDataLoading(false);
      }
    };

    trackOperationStatus();

    return () => {
      cancel = true;
    };
  }, [isProcessComplete, isValidateStatement, handleValidateStep]);

  // Fetch Company Number - EmployerConnect API
  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      if (!accountid || !companyid || !authToken) {
        return;
      }

      try {
        var response = await handleGet({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${companyid}/name`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        setCompanyNumber(json.items[0]);
      } catch (e) {
        console.log(e);
        setCompanyNumber({});
      }
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, [accountid, companyid, authToken]);

  //  Fetch Terminated Member Data - EmployerConnect API
  const fetchTerminatedMember = useCallback(
    async (item, data) => {
      if (!accountid || !statementRow.statementid || !item.SIN) {
        return;
      }
      try {
        const init = {
          body: { SIN: item.SIN },
        };

        const response = await handlePost({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${statementRow.statementid}/terminated-employees`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: init.body,
        });
        
        const { body } = response;
        const json = await body.json();

        if (json.result.length > 0) {
          const requiredFields = [
            "employeelistid",
            "givennames",
            "surname",
            "hasltdhrs",
            "haspenhrs",
          ];
          const finalData = data.map((item) => {
            const employee = json.result.find((e) => e.sin === item.SIN);
            if (employee) {
              requiredFields.forEach((field) => {
                if (employee[field] !== undefined) {
                  item[field] = employee[field];
                } else {
                  item[field] = null;
                }
              });
            }
            return item;
          });
          setValidateData(finalData);
          setProcessComplete(true);
        } else {
          setValidateData(data);
          setProcessComplete(true);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [accountid, authToken, statementRow.statementid, setValidateData]
  );

  // Get Default Values
  const getDefaultValues = useCallback((periodStart) => {
    return {
      employeelistid: "",
      employeestatusid: "",
      employmenttype: "",
      givennames: "",
      surname: "",
      enddate: "N",
      hasltdhrs: "1",
      haspenhrs: "1",
      curr_workstatusstartdate: `${periodStart}`,
      edits: [],
      warnings: [],
    };
  }, []);

  // Get Employees Not Included in the Data
  const getEmployeesNotInData = useCallback(
    (data, employeeValidationData, statementRow) => {
      return employeeValidationData
        .filter((employee) => !data.some((item) => item.SIN === employee.sin))
        .map((employee) => ({
          ...employee,
          CompanyNumber: `${companyNumber.companynumber}`,
          StatementId: `${statementRow?.statementid}`,
          PeriodStart: `${statementRow?.periodstart}`,
          PeriodEnd: `${statementRow?.periodend}`,
          Givennames: employee.givennames,
          Surname: employee.surname,
          SIN: employee.sin,
        }));
    },
    [companyNumber]
  );

  // Filter by Active Employees
  const filterEmployees = useCallback((employees) => {
    return employees.filter(
      (item) =>
        item.workstatus === "A" && (item.ltdhrs === 0 || item.penhrs === 0)
    );
  }, []);

  // Map the data to the employeeValidationData DB Comparation
  const mapDataToEmployeeValidationData = useCallback(
    (data, employeeValidationData, defaultValues) => {
      return data.map((item) => {
        const employee = employeeValidationData.find((e) => e.sin === item.SIN);
        Object.entries(defaultValues).forEach(([field, defaultValue]) => {
          item[field] =
            employee && employee[field] !== undefined
              ? employee[field]
              : defaultValue;
        });
        return item;
      });
    },
    []
  );

  // Validate the data
  useEffect(() => {
    let cancel = false;

    const fetchCurrentData = async () => {
      if (
        newValidateData.length === 0 ||
        !Array.isArray(employeeValidationData) ||
        employeeValidationData.length === 0 ||
        !statementRow
      ) {
        return;
      }

      try {
        // Get default values based on the period start
        const defaultValues = getDefaultValues(statementRow.periodstart);

        // Get employees not in data
        const employeesNotInData = getEmployeesNotInData(
          newValidateData,
          employeeValidationData,
          statementRow
        );

        // Filter the employees
        const filteredEmployees = filterEmployees(employeesNotInData);

        // Set the employee active checklist
        if (filteredEmployees) {
          setEmployeeActiveCheckList(filteredEmployees);
        } else {
          setEmployeeActiveCheckList([]);
        }

        if (cancel) {
          return;
        }

        // Map data to employee validation data
        const finalData = mapDataToEmployeeValidationData(
          newValidateData,
          employeeValidationData,
          defaultValues
        );

        // filter the object date with employeelistid === ""
        const filteredData = finalData.filter(
          (item) =>
            item.employeelistid === "" && item.CompanyNumber !== undefined
        );
        if (filteredData.length > 0) {
          filteredData.forEach((item) =>
            fetchTerminatedMember(item, finalData)
          );
        } else {
          setValidateData(finalData);
          setProcessComplete(true);
          // handleValidateStep();
        }
      } catch (e) {
        console.log(e);
      }
    };

    // Call the function
    fetchCurrentData();

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeValidationData, newValidateData, statementRow]);

  // Process the data to remove the sin space and -
  const processData = useCallback(
    (data) => {
      if (!data) {
        throw new Error("No data provided");
      }

      return data.map((item) => ({
        ...item,
        SIN: item.SIN ? item.SIN.trim().replace(/-/g, "").replace(/\s+/g, "") : "",
        workstatus: item.workstatus ? item.workstatus.trim().toUpperCase() : undefined,
        StatementId: item.StatementId || `${statementRow.statementid}`,
      }));
    },
    [statementRow.statementid]
  );

  // On Continue Handler Function
  const onContinue = useCallback(
    (data) => {
      const newData = processData(data);
      setNewValidateData(newData);
      return newData;
    },
    [processData]
  );

  // Validation to check statement id, period start and end date
  useEffect(() => {
    let cancel = false;

    const validateStatementId = async () => {
      const { statementid, periodstart, periodend } = statementRow;

      if (
        newValidateData.length === 0 ||
        !companyNumber ||
        !statementid ||
        !periodstart ||
        !periodend
      ) {
        return;
      }

      try {
        const firstFiveRecords = newValidateData.slice(0, 5);

        const isInvalid = (value) =>
          value !== undefined && value !== null && value !== "";

        const hasInvalidData = firstFiveRecords.some(
          ({ CompanyNumber, StatementId, PeriodStart, PeriodEnd }) =>
            isInvalid(CompanyNumber) ||
            isInvalid(StatementId) ||
            isInvalid(PeriodStart) ||
            isInvalid(PeriodEnd)
        );

        if (!hasInvalidData || cancel) {
          return;
        }

        // check if period start and period end are valid dates, otherwise convert them to date. We are expecting the date to be in the format of YYYY-MM-DD
        const isValidDate = (date) => {
          const datePattern = /^\d{4}-\d{2}-\d{2}$/;
          return datePattern.test(date);
        };

        // // firstFiveRecords the period start and period end to date
        firstFiveRecords.forEach((record) => {
          if (!isValidDate(record.PeriodStart)) {
            record.PeriodStart = new Date(record.PeriodStart)
              .toISOString()
              .slice(0, 10);
          }
          if (!isValidDate(record.PeriodEnd)) {
            record.PeriodEnd = new Date(record.PeriodEnd)
              .toISOString()
              .slice(0, 10);
          }
        });

        // check if the statement id, period start and period end match the corresponding data in the file you are trying to upload
        const isValid = firstFiveRecords.some(
          ({ CompanyNumber, StatementId, PeriodStart, PeriodEnd }) =>
            parseInt(CompanyNumber, 10) === parseInt(companyNumber.companynumber.replace("-", ""), 10) &&
            parseInt(StatementId, 10) === statementid &&
            PeriodStart === periodstart &&
            PeriodEnd === periodend
        );

        if (!isValid) {
          enqueueSnackbar(
            "The Company Number, Statement ID, Period Start, and Period End values do not match the corresponding data in the file you are trying to upload. Please review your file and try again.",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }
          );
          setIsValidateStatement(false);
        } else {
          setIsValidateStatement(true);
        }
      } catch (e) {
        console.log(e);
      }
    };

    validateStatementId();

    return () => {
      cancel = true;
    };
  }, [statementRow, newValidateData, companyNumber, enqueueSnackbar]);

  // Continue Handler Wizard Form
  const handleOnContinue = useCallback(async () => {
    if (unmatchedRequiredFields.length > 0) {
      setShowUnmatchedFieldsAlert(true);
    } else {
      setIsMatchingDataLoading(true);
      await onContinue(
        normalizeTableData(columns, employeeData, fields),
        employeeData,
        columns
      );
    }
  }, [unmatchedRequiredFields.length, onContinue, columns, employeeData]);

  // disable the continue button if there are unmatched required fields
  const handleContinueWithTableData = useCallback(async () => {
    setShowUnmatchedFieldsAlert(false);
    setIsMatchingDataLoading(true);
    await onContinue(
      normalizeTableData(columns, employeeData, fields),
      employeeData,
      columns
    );
  }, [onContinue, columns, employeeData]);

  useEffect(() => {
    if (autoMapHeaders) {
      setColumns(getMatchedColumns(columns, fields, rows, autoMapDistance));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        MATCH COLUMNS
      </Typography>
      <InfoBox description={descriptionList} />
      <UnmatchedFieldsCollapse
        isOpen={showUnmatchedFieldsAlert}
        onClose={() => setShowUnmatchedFieldsAlert(false)}
        fields={unmatchedRequiredFields}
        onConfirm={handleContinueWithTableData}
      />
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "neutral.800" : "neutral.100",
          p: 0,
        }}
      >
        <Card>
          <CardHeader title="" />
          <Divider />
          <TableContainer style={{ width: "auto" }}>
            <ColumnGrid
              columns={columns}
              userColumn={(column) => (
                <EmployerTableColumn
                  column={column}
                  handleOnIgnore={handleOnIgnore}
                  onRevertIgnore={onRevertIgnore}
                  entries={dataExample.map((row) => row[column.index])}
                />
              )}
              templateColumn={(column) => (
                <TemplateColumn
                  column={column}
                  fields={fields}
                  onChange={onChange}
                />
              )}
            />
          </TableContainer>
        </Card>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{ marginTop: 6, marginLeft: 1 }}
              onClick={handleOnContinue}
              disabled={isMatchingDataLoading}
            >
              {isMatchingDataLoading ? <CircularProgress size={24} /> : "Next"}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default MatchColumnsFile;
