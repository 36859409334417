import React from 'react';

// Material UI
import { AppBar, Button, Box, Toolbar, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Third Party Library
import { useSnackbar } from 'notistack';

const ContainerResponsiveBox = styled('div')({
  maxWidth: '100%',
  paddingLeft: '10px',
  paddingRight: '10px',

  '@media (min-width: 544px)': {
    paddingLeft: '10px',
    paddingRight: '10px',
  },

  '@media (min-width: 768px)': {
    paddingLeft: '30px',
    paddingRight: '30px',
  },

  '@media (min-width: 992px)': {
    paddingLeft: '45px',
    paddingRight: '45px',
  },

  '@media (min-width: 1200px)': {
    paddingLeft: '80px',
    paddingRight: '80px',
  },

  '@media (min-width: 1500px)': {
    paddingLeft: '100px',
    paddingRight: '100px',
  },

  '@media (min-width: 1920px)': {
    maxWidth: '1920px',
  },
});

const MaintenancePage = () => {
  // Logo Image
  const EmployerConnectLogo = '/assets/images/erconn.png';

  // Employer Image Banner
  const EmployerImageBanner = '/assets/images/employee_banner.jpg';

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  const handleClickBackToPublicWebsite = async (e) => {
    e.preventDefault();
    try {
      window.location.href = 'https://www.iwafibp.ca/';
    } catch (error) {
      enqueueSnackbar('An error occurred while redirecting to the public website.', {
        variant: 'error',
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        position: 'relative',
        minWidth: '100%',
        minHeight: '100vh',
        fontWeight: 300,
        lineHeight: 1.6,
        letterSpacing: '0.5px',
        fontSize: '18px',
        color: '#000',
      }}
    >
      <ContainerResponsiveBox>
        <AppBar position="fixed">
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img
              src={EmployerConnectLogo}
              alt={'ER Conn'}
              style={{
                cursor: 'pointer',
                marginLeft: '20px',
                marginTop: '7px',
                marginBottom: '7px',
                width: '100px',
                height: '50px',
              }}
            />
          </Toolbar>
        </AppBar>
        <ContainerResponsiveBox>
          <Box
            sx={{
              marginTop: {
                xs: '75px',
                sm: '80px',
                md: '80px',
                lg: '90px',
                xl: '90px',
              },
              backgroundImage: `url(${EmployerImageBanner})`,
              position: 'relative',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50% 50%',
              width: '100%',
              height: {
                xs: '550px',
                sm: '650px',
                md: '650px',
                lg: '750px',
                xl: '750px',
              },
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={8}
                xl={6}
                lg={6}
                sm={12}
                sx={{
                  ml: {
                    lg: 1,
                    md: 1,
                  },
                }}
              >
                <ContainerResponsiveBox
                  style={{
                    backgroundColor: '#fff',
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: '120px',
                      paddingBottom: '80px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: {
                          xs: '1.5rem',
                          sm: '1.5rem',
                          md: '2rem',
                          lg: '2rem',
                          xl: '2rem',
                        },
                        textTransform: 'uppercase',
                        margin: 0,
                        padding: 0,
                        display: 'inline-block',
                        position: 'relative',
                      }}
                    >
                      Sorry for the inconvenience
                    </Typography>
                    <Box sx={{ p: 1 }} />
                    <Typography
                      variant="h6"
                      component="span"
                      sx={{
                        marginTop: '3rem',
                        marginBottom: '3rem',
                        fontWeight: 700,
                      }}
                    >
                      We take advantage of this time to improve our service.
                    </Typography>
                    <Box sx={{ p: 1 }} />
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: 700,
                      }}
                      onClick={handleClickBackToPublicWebsite}
                    >
                      Return to IWAFIBP Website
                    </Button>
                  </Box>
                </ContainerResponsiveBox>
              </Grid>
            </Grid>
          </Box>
        </ContainerResponsiveBox>
      </ContainerResponsiveBox>
    </Box>
  );
};

export default MaintenancePage;
