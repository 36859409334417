import React, { useState } from "react";

// Material - UI
import { Box, Stack } from "@mui/material";

// Project Imports
import AwsCognitoLogin from "../login/AwsCognitoLogin";
import CodeVerification from "../code-verification/CodeVerification";
import Authenticated from "../authenticated/Authenticated";

const getStepAuth = (step, handleNext, handleBack, loginData, setLoginData) => {
  switch (step) {
    case 0:
      return (
        <AwsCognitoLogin
          handleNext={handleNext}
          handleBack={handleBack}
          loginData={loginData}
          setLoginData={setLoginData}
        />
      );
    case 1:
      return (
        <CodeVerification
          handleBack={handleBack}
          loginData={loginData}
        />
      );
    case 2:
      return <Authenticated />;
    default:
      return "Unknown step";
  }
};

// ===========================|| AUTHENTICATION WIZARD ||============================ //

const AuthLogin = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loginData, setLoginData] = useState({ email: "", password: "" });

  // handle next step
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  // handle back step
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Stack spacing={3}>
      <Box sx={{ p: 0.5 }} />
      {getStepAuth(activeStep, handleNext, handleBack, loginData, setLoginData)}
    </Stack>
  );
};

export default AuthLogin;
