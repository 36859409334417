import React, { useState, useEffect, Fragment } from "react";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Hooks
import useContactTypes from "../../hooks/useContactTypes";

// Material-UI
import { List, ListItem, ListItemText } from "@mui/material";

// Project Components
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";
import ErrorBox from "../../ui-components/NotificationErrorBox";

function ContactsTypes(props) {
  const { authToken } = useSession();
  const { accountid, companyid, setContactId } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Hook - Fetch Contact Types
  const {
    data: contacttypes,
    error,
    isLoading,
  } = useContactTypes(accountid, companyid, authToken);

  useEffect(() => {
    if (contacttypes.length !== 0) {
      setContactId(contacttypes.map((i) => i.contactid)[0].toString());
    }
  }, [contacttypes, setContactId]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [accountid, companyid, setContactId]);

  if (isLoading) {
    <CustomLoadingScreen text="Fetching Contact Types" />;
  }

  if (error) {
    <ErrorBox message="Error fetching Contact Types" />;
  }

  return (
    <Fragment>
      {contacttypes && (
        <List
          dense={true}
          sx={{
            overflowY: "scroll",
          }}
        >
          {contacttypes.map((row, index) => {
            return (
              <ListItem
                button
                key={index}
                selected={selectedIndex === index}
                onClick={() => {
                  setSelectedIndex(index);
                  setContactId(row.contactid);
                }}
              >
                <ListItemText
                  primary={row.contacttype}
                  secondary={row.contactname}
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </Fragment>
  );
}

export default ContactsTypes;
