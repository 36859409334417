import React, { Fragment, useState } from "react";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Main Context
import { useMainContext } from "../../context/MainContext";

// Hooks
import useViewPadPastPayment from "../../hooks/useViewPadPastPayment";

// Material-UI
import { Paper, Box, Typography, TextField } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

// Helper Functions
import { currencyFormat } from "../../common/HelperFunctions";

// DevExpress Grid
import { DataTypeProvider } from "@devexpress/dx-react-grid";

// DevExtreme React Grid Material-UI
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={currencyFormat} {...props} />
);

const classes = {
  tableStriped: "tableStriped",
};

const StyledTable = styled(VirtualTable.Table)(({ theme }) => ({
  [`&.tableStriped`]: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      marginRight: "28px",
    },
  },
}));

const TableComponent = ({ ...restProps }) => (
  <StyledTable {...restProps} className={classes.tableStriped} />
);

const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: "bold",
    }}
  >
    {children}
  </TableHeaderRow.Content>
);

const DateFormatter = ({ value }) =>
  value ? (
    <TextField
      type="date"
      value={value}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  ) : (
    ""
  );

const PastPayments = (props) => {
  const { authToken } = useSession();
  const { accountid, companyid } = props;

  const [columns] = useState([
    { title: "Payment Date", name: "paymentdate" },
    { title: "Reason", name: "reason" },
    { title: "Amount", name: "amount" },
    { title: "Status", name: "status" },
  ]);
  const [columnExtensions] = useState([
    { columnName: "paymentdate", align: "left", width: "110px" },
    { columnName: "reason", align: "left", width: "175px" },
    { columnName: "amount", align: "right", width: "120px" },
    { columnName: "status", align: "right", width: "100px" },
  ]);
  const [currencyColumns] = useState(["amount"]);

  // Hook - Fetch Past Payments
  const { data, isLoading } = useViewPadPastPayment(
    accountid,
    companyid,
    authToken
  );

  if (isLoading) {
    <CustomLoadingScreen text="Loading PAD Payments" />;
  }

  return (
    <Fragment>
      <Box width={530}>
        <Typography
          sx={{
            fontWeight: "bold",
            padding: "2px",
          }}
        >
          Past Payments
        </Typography>
        <Paper>
          <Grid rows={data} columns={columns}>
            <CurrencyTypeProvider for={currencyColumns} />
            <DataTypeProvider
              for={["paymentdate"]}
              formatterComponent={DateFormatter}
            />

            <VirtualTable
              height={532}
              tableComponent={TableComponent}
              columnExtensions={columnExtensions}
            />
            <TableHeaderRow contentComponent={TableHeaderContent} />
          </Grid>
        </Paper>
      </Box>
    </Fragment>
  );
};

function ViewPAD() {
  const { account, companyid } = useMainContext();
  const accountid = account.map((i) => i.accountid)[0];

  return <PastPayments accountid={accountid} companyid={companyid} />;
}

export default ViewPAD;
