// Material-ui
import {
  Box,
  Button,
  Badge,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
  Stack,
  Divider,
} from '@mui/material';

// Auth Session
import { useSession } from '../../../../../../context/AuthSession';

// Hook
import { useComparisonExcelData } from '../../../../hooks/useComparisonExcelData';
import { useDiscardedEmployees } from '../../../../hooks/useDiscardedEmployees';

// Store
import { useTrackFinalDiscardedRows } from '../../../../store/useFileParsingStore';

// Project Imports
import ContributionSummarySection from '../../components/ContributionSummarySection';
import ContributionHeader from '../../components/ContributionHeader';
import ContributionTabMain from '../../components/ContributionTabMain';
import ContributionTabDetails from '../../components/ContributionTabDetails';

const FileParsingSummaryComplete = ({
  summaryData,
  accountid,
  statementid,
  statementRow,
  totalHours,
  discardedRows,
  numOfProcessedRows,
  processedRows,
  recordedRows,
  isLoading,
  submitData,
  disableButtonAfterSubmit,
  onSubmit,
  handleClose,
}) => {
  // Auth Context
  const { authToken } = useSession();

  // Track the number of discarded rows
  const { finalDiscardedRows } = useTrackFinalDiscardedRows();

  // Hook - Compare initial and final employee hours and display the difference.
  const {
    discrepancyPenhrs,
    discrepancyLtdhrs,
    discrepancyTotal,
    discrepancyRecords,
    isDiscrepRecordsLoading,
    error,
  } = useComparisonExcelData(
    submitData,
    totalHours,
    accountid,
    statementRow.companyid,
    authToken,
    statementid
  );

  // Hook - Discarded Employees Data
  const { discardedEmployees, isDiscardedEmployeesError } = useDiscardedEmployees(
    accountid,
    statementRow.companyid,
    authToken,
    statementid
  );

  const tabContents = {
    statementSummary: (
      <div>
        <Card
          sx={{
            borderRadius: 0,
            p: 3,
            backgroundColor: '#d5f2dc',
          }}
        >
          <ContributionHeader statementid={statementid} statementRow={statementRow} />
          <Divider />
          <CardContent sx={{ p: 0, mb: 0 }}>
            <Container maxWidth="lg">
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
                  Processed Rows
                </Typography>
                <IconButton aria-label="view">
                  <Badge
                    max={1000}
                    badgeContent={discardedRows + numOfProcessedRows + recordedRows}
                    color="primary"
                    fontSize="small"
                    sx={{ pl: 0.5, pr: 1.5 }}
                    showZero
                  />
                </IconButton>
                <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
                  Discarded Rows
                </Typography>
                <IconButton aria-label="view">
                  <Badge
                    badgeContent={finalDiscardedRows}
                    max={1000}
                    color="error"
                    fontSize="small"
                    sx={{ pl: 0.5, pr: 1.5 }}
                    showZero
                  />
                </IconButton>
              </Box>
            </Container>
            <Divider />
            <ContributionSummarySection
              summaryData={summaryData}
              totalHours={totalHours}
              processedRows={processedRows}
              isLoading={isLoading}
            />
          </CardContent>
        </Card>
      </div>
    ),
    hoursAndEmployeesDetails: (
      <div>
        <Container maxWidth="lg">
          <ContributionTabDetails
            accountid={accountid}
            companyid={statementRow.companyid}
            statementid={statementid}
            payPeriodStart={statementRow.periodstart}
            payPeriodEnd={statementRow.periodend}
            totalHours={totalHours}
            title="HOURS AND EMPLOYEES DETAILS"
            subtitle="Summary of hours and employees"
            list={discrepancyRecords}
            discrepancyPenhrs={discrepancyPenhrs}
            discrepancyLtdhrs={discrepancyLtdhrs}
            discrepancyTotal={discrepancyTotal}
            discardedEmployees={discardedEmployees}
            isDiscrepRecordsLoading={isDiscrepRecordsLoading}
            error={error || isDiscardedEmployeesError}
          />
        </Container>
      </div>
    ),
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
      <Box sx={{ m: 2 }} />
      <Typography variant="body1" gutterBottom sx={{ m: 1 }}>
        {disableButtonAfterSubmit
          ? 'Thank you for your submission. You will receive confirmation in your Employer Connect inbox.'
          : 'Please confirm that you are ready to submit the TA hours for the selected statement'}
      </Typography>
      <ContributionTabMain tabContents={tabContents} />
      <Container maxWidth="lg" disableGutters>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="outlined"
                sx={{ my: 3 }}
                onClick={() => handleClose()}
              >
                {disableButtonAfterSubmit ? 'Close' : 'Cancel'}
              </Button>
              {!disableButtonAfterSubmit && (
                <Button
                  variant="contained"
                  sx={{ my: 3, ml: 1 }}
                  type="submit"
                  onClick={() => onSubmit(submitData?.validData)}
                >
                  Confirm
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default FileParsingSummaryComplete;
