import React, { useState, useMemo, useCallback, useRef } from 'react';

// Material-ui
import { Box, Button, Stack } from '@mui/material';

// Hooks
import {
  useTrackCurrentEmployeeID,
  useTrackCurrentEmployeeFullName,
  useTrackCurrentEmployeeFullNameDB,
  useTrackFinalDiscardedRows,
} from '../../../store/useFileParsingStore';

// project imports
import TableValidation from '../../ValidationFile/TableValidation';
import EmployerNameColumnCard from '../../ValidationFile/components/EmployeeNameColumnCard';
import ErrorDetectedCollapse from '../../ValidationFile/components/ErrorDetectedCollapse';

// Utils
import { fields } from '../../../fields';
import { addErrorsAndRunHooks } from '../../ValidationFile/utils/dataMutations';
import { generateColumns } from '../../ValidationFile/utils/generateColumns';

// Third party
import { useSnackbar } from 'notistack';

const TableVerification = ({
  submitData,
  setSubmitData,
  deletedRows,
  accountid,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  statementRow,
  setRecordedRows,
  handleNext,
  submitDiscardedRows,
}) => {
  // Get the previous data from the previous step and add errors and unique index.
  const [data, setData] = useState(
    useMemo(
      () => addErrorsAndRunHooks(deletedRows, fields),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  );

  // Notistack
  const { enqueueSnackbar } = useSnackbar();

  // Columns Mapping
  const columns = useMemo(() => generateColumns(fields), []);

  // restore selection
  const [restoreSelection, setRestoreSelection] = useState([]);

  // Show collapse when there is an error in the data.
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);

  // Error Collapse Ref
  const errorCollapseRef = useRef(null);

  // Track the number of discarded rows
  const { setFinalDiscardedRows } = useTrackFinalDiscardedRows();

  // Track Employee Information
  const { currentEmployeeId, setCurrentEmployeeId } = useTrackCurrentEmployeeID();
  const { employeeFullName, setEmployeeFullName } = useTrackCurrentEmployeeFullName();
  const { employeeDatabaseFullName, setEmployeeDatabaseFullName } =
    useTrackCurrentEmployeeFullNameDB();

  // Update the Data with new errors.
  const updateData = useCallback(
    (rows) => {
      setData(addErrorsAndRunHooks(rows, fields));
    },
    [setData]
  );

  // Update Row Data when user changes the data in the table.
  const updateRow = useCallback(
    (rows, changedData) => {
      const changes = changedData?.indexes.reduce((acc, index) => {
        // when data is filted val !== actual index
        const realIndex = data.findIndex((value) => value.__index === rows[index].__index);
        acc[realIndex] = rows[index];
        return acc;
      }, {});
      const newData = Object.assign([], data, changes);
      updateData(newData);
    },
    [data, updateData]
  );

  // Handle Selection Change
  const handleSelectionChange = (newSelection) => {
    setRestoreSelection(newSelection);
  };

  // Function to scroll to the error collapse
  const scrollToErrorCollapse = () => {
    if (errorCollapseRef.current) {
      errorCollapseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Submit Restore Data
  const submitRestoreData = async () => {
    if (restoreSelection.length === 0) {
      enqueueSnackbar('No data selected for restoration', {
        variant: 'warning',
      });
      setShowSubmitAlert(false);
      return;
    }

    const restoreData = data
      .filter((item) => restoreSelection.includes(item.__index))
      .reduce((acc, value) => {
        const { __index, __errors, ...values } = value;
        acc.push(values);
        return acc;
      }, []);

    const nonRestoreData = data
      .filter((item) => !restoreSelection.includes(item.__index))
      .reduce((acc, value) => {
        const { __index, __errors, ...values } = value;
        acc.push(values);
        return acc;
      }, []);

    const mergedData = [...submitData.validData, ...restoreData];
    setRecordedRows(restoreData.length > 0 ? restoreData.length : 0);
    setSubmitData({ validData: mergedData });
    if (nonRestoreData.length > 0) {
      setFinalDiscardedRows(nonRestoreData.length);
      submitDiscardedRows(nonRestoreData);
    }
    enqueueSnackbar('The data has been restored successfully', {
      variant: 'success',
    });
    handleNext();
    setShowSubmitAlert(false);
  };

  // Submit Data
  const onRestore = () => {
    const selectedData = data.filter((value) => restoreSelection.includes(value.__index));
    const invalidData = selectedData.find((value) => {
      if (value?.__errors) {
        return Object.values(value.__errors)?.filter((err) => err.level === 'error').length;
      }
      return false;
    });
    if (!invalidData) {
      submitRestoreData();
    } else {
      setShowSubmitAlert(true);
      setTimeout(scrollToErrorCollapse, 1000);
    }
  };

  // Open Employee Validation
  const handleEmployeeValidationOpen = useCallback(
    (
      employeelistid,
      employeeIndex,
      employeeGivenName,
      employeeSurname,
      employeeDatabaseGivenName,
      employeeDatabaseSurName
    ) => {
      setCurrentEmployeeId(employeelistid);
      setRestoreSelection([employeeIndex]);
      setEmployeeFullName({ employeeGivenName, employeeSurname });
      setEmployeeDatabaseFullName({
        employeeDatabaseGivenName,
        employeeDatabaseSurName,
      });
    },
    [setCurrentEmployeeId, setEmployeeFullName, setEmployeeDatabaseFullName]
  );

  // Close Employee Validation
  const handleEmployeeValidationClose = useCallback(() => {
    setCurrentEmployeeId(null);
    setRestoreSelection([]);
    setEmployeeFullName({ employeeGivenName: '', employeeSurname: '' });
  }, [setCurrentEmployeeId, setEmployeeFullName]);

  // Employee Name Confirmation and Update
  const updateSelectedRows = (newEmployeeGivenames, newEmployeeSurname) => {
    const employeeRowIndex = data
      .filter((row) => restoreSelection.includes(row.__index))
      .map((row) => data.indexOf(row));

    const updatedData = data.map((row) => {
      if (restoreSelection.includes(row.__index)) {
        return {
          ...row,
          Givennames: newEmployeeGivenames,
          Surname: newEmployeeSurname,
        };
      } else {
        return row;
      }
    });
    updateRow(updatedData, { columns: {}, indexes: [employeeRowIndex] });
    handleEmployeeValidationClose();
    return;
  };

  // Handle Report Discarded Rows and Continue
  const handleReportDiscardRowAndProceed = () => {
    const nonRestoreData = data
      .filter((item) => !restoreSelection.includes(item.__index))
      .reduce((acc, value) => {
        const { __index, __errors, ...values } = value;
        acc.push(values);
        return acc;
      }, []);
    setFinalDiscardedRows(nonRestoreData.length);
    submitDiscardedRows(nonRestoreData);
    handleNext();
  };

  return (
    <>
      <ErrorDetectedCollapse
        isOpen={showSubmitAlert}
        onClose={() => setShowSubmitAlert(false)}
      />
      <Box ref={errorCollapseRef} sx={{ m: 2 }} />
      <TableValidation
        rows={data}
        columns={columns}
        accountid={accountid}
        onClaimEmployeeIds={onClaimEmployeeIds}
        totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
        statementrow={statementRow}
        onRowsChange={updateRow}
        selection={restoreSelection}
        handleSelectionChange={handleSelectionChange}
        onEmployeeValidationOpen={handleEmployeeValidationOpen}
      />
      <EmployerNameColumnCard
        open={!!currentEmployeeId}
        selection={restoreSelection}
        employeelistid={currentEmployeeId || undefined}
        employeeNames={employeeFullName}
        employeeDatabaseFullName={employeeDatabaseFullName}
        condition={false}
        confirmation={updateSelectedRows}
        onClose={handleEmployeeValidationClose}
      />
      <Box sx={{ m: 3 }} />
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            variant="contained"
            onClick={onRestore}
            sx={{
              bgcolor: 'grey',
              '&:hover': {
                backgroundColor: 'grey',
              },
              '&:active': {
                backgroundColor: 'grey',
              },
            }}
          >
            {'Restore discarded data'}
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={handleReportDiscardRowAndProceed}
            type="submit"
          >
            {'Continue without restoring'}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default TableVerification;
