import React, { Fragment } from "react";

// Material-ui
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";

// Project Imports
import EmployeeInfo from "../../../employee-list/EmployeeInfo";

const NewCardEmployeeData = (props) => {
  const {
    accountid,
    companyid,
    roleid,
    setRefresh,
    employeeNewSinNumber,
    selectedCardId,
    handleCardToggle,
  } = props;

  const ArrowLeftImg = "../assets/icons/arrow-left.png";
  
  return (
    <Fragment>
      {employeeNewSinNumber.map((employee, index) => (
        <Card
          key={index}
          sx={{
            backgroundColor: "#f9f9f9",
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          <CardHeader
            title={
              <Typography variant="subtitle">
                {employee?.givennames ? employee.givennames : ""}{" "}
                {employee?.surname ? employee.surname : ""} -{" "}
                {employee?.sin
                  ? employee.sin.replace(/\d(?=\d{3})/g, "*")
                  : "N/A"}
              </Typography>
            }
            action={
              <IconButton
                sx={{
                  marginTop: "0.5rem",
                  transform: selectedCardId === index ? "rotate(270deg)" : "rotate(0deg)",
                }}
                aria-label="toggle"
                onClick={() => handleCardToggle(index)}
              >
                {selectedCardId === index ? (
                  <Box component="img" src={ArrowLeftImg} width={32} height={32} />
                ) : (
                  <Box component="img" src={ArrowLeftImg} width={32} height={32}  />
                )}
              </IconButton>
            }
            sx={{
              p: 3,
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          />
          <Collapse in={selectedCardId === index} timeout="auto" unmountOnExit>
            <Typography variant="body1" gutterBottom sx={{ mr: 3, ml: 3 }}>
              <strong> You must save your changes </strong> by clicking the
              green save button at the bottom of the information card. <br />
            </Typography>
            <Box sx={{ p: 3 }}>
              <EmployeeInfo
                accountid={accountid}
                companyid={companyid}
                roleid={roleid}
                employeelistid={employee.employeelistid}
                employeeExists={employee.employeelistid ? true : false}
                setOpenDialog={() => handleCardToggle(null)}
                setRefresh={setRefresh}
              />
            </Box>
          </Collapse>
        </Card>
      ))}
    </Fragment>
  );
};

export default NewCardEmployeeData;
