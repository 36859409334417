import React, { useState } from "react";

// PropTypes validation
import PropTypes from "prop-types";

// Material - UI
import { Box, Stack } from "@mui/material";

// Project Imports
import AwsCognitoResetPassword from "../reset-password/AWSCognitoResetPassword";
import AwsCognitoConfirmResetPassword from "../reset-password/AWSCognitoConfirmResetPassword";
import AwsCognitoConfirmationMessage from "../reset-password/AWSCognitoConfirmationMessage";

const getStepAuth = (
  step,
  handleNext,
  handleBack,
  handleForgotPassword,
  resetPasswordData,
  setResetPasswordData,
  setConfirmationCodeData
) => {
  switch (step) {
    case 0:
      return (
        <AwsCognitoResetPassword
          handleNext={handleNext}
          resetPasswordData={resetPasswordData}
          setResetPasswordData={setResetPasswordData}
          handleForgotPassword={handleForgotPassword}
        />
      );
    case 1:
      return (
        <AwsCognitoConfirmResetPassword
          handleNext={handleNext}
          handleBack={handleBack}
          resetPasswordData={resetPasswordData}
          setConfirmationCodeData={setConfirmationCodeData}
        />
      );
    case 2:
      return (
        <AwsCognitoConfirmationMessage
          handleForgotPassword={handleForgotPassword}
        />
      );
    default:
      return "Unknown step";
  }
};

// ===========================|| RESET PASSWORD WIZARD ||============================ //
const AuthResetPassword = ({ handleForgotPassword }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [resetPasswordData, setResetPasswordData] = useState({ accountID: "" });
  const [confirmationCodeData, setConfirmationCodeData] = useState({
    code: "",
    newPassword: "",
    confirmPassword: "",
  });

  // handle next step
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  // handle back step
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Stack spacing={3}>
      <Box sx={{ p: 0.5 }} />
      {getStepAuth(
        activeStep,
        handleNext,
        handleBack,
        handleForgotPassword,
        resetPasswordData,
        setResetPasswordData,
        confirmationCodeData,
        setConfirmationCodeData
      )}
    </Stack>
  );
};

AuthResetPassword.propTypes = {
  handleForgotPassword: PropTypes.func.isRequired,
};

export default AuthResetPassword;
