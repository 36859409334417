import React, { useRef } from "react";

// Material UI
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CardContent,
} from "@mui/material";

const ConfirmCloseAlert = ({ isOpen, onClose, onConfirm }) => {
  const cancelRef = useRef(null);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <Card>
        <CardContent>
          <DialogTitle>Exit import flow</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? Your current information will not be saved.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button ref={cancelRef} onClick={onClose} variant="outlined">
            Cancel
          </Button>
            <Button color="error" variant="contained" onClick={onConfirm}>
              Exit flow
            </Button>
          </DialogActions>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default ConfirmCloseAlert;
