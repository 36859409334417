import React, { Fragment } from "react";

// Material-UI
import { useTheme } from "@mui/material/styles";

import {
  Box,
  Grid,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";

// Custom Styles
import { customInput } from "../../../../theme/Typography";

// Project Imports
import { useUser } from "../../../../context/AuthContext";
import ErrorBox from "../../../../ui-components/NotificationErrorBox";

// Third-party
import * as Yup from "yup";
import { Formik } from "formik";

// ===========================|| ACCOUNT ID RESET PASSWORD ||=========================== //

const AWSCognitoResetPassword = ({
  handleNext,
  setResetPasswordData,
  handleForgotPassword,
}) => {
  // Theme
  const theme = useTheme();

  // User Context - Forgot Password
  const { handleResetPassword } = useUser();

  return (
    <Fragment>
      <Formik
        initialValues={{
          accountID: "",
        }}
        validationSchema={Yup.object({
          accountID: Yup.string()
          .required("Account ID is required")
          .min(8, "Your Account ID needs to be 8 digits number")
          .max(8, "Your Account ID needs to be 8 digits number"),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting, errors }
        ) => {
          // Reset Password - Context Cognito Function
          try {
            const accountID = values.accountID.replace(/\s/g, "");
            await handleResetPassword({
              username: accountID,
            });
            setResetPasswordData({ accountID });
            if (!errors) {
              handleNext();
            }
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Typography variant="h4" sx={{ mb: 3 }}>
                Reset Password
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.accountID && errors.accountID)}
                  sx={customInput}
                >
                  <InputLabel htmlFor="outlined-adornment-accountID">
                    Account ID
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-accountID"
                    name="accountID"
                    type="text"
                    value={values.accountID}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Account ID"
                  />
                  {touched.accountID && errors.accountID ? (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-accountID"
                    >
                      {errors.accountID}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {errors.submit && <ErrorBox message={errors.submit} />}
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
                >
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    type="submit"
                    size="large"
                    variant="contained"
                    sx={{
                      backgroundColor: "#008521",
                      color: theme.palette.primary.contrastText,
                      "&:hover": { backgroundColor: "#008521" },
                    }}
                  >
                    Send Code
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={handleForgotPassword}
                    sx={{
                      color: "#008521",
                      backgroundColor: "transparent",
                      borderColor: "#008521",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "#008521",
                      },
                    }}
                  >
                    Back to Sign In
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AWSCognitoResetPassword;
