import { useState, useEffect } from "react";

// AWS Amplify - API - Request Handler
import { handleGet } from '../../../utils/amplifyInstance';

export const useDiscardedTotalHours = (accountid, companyid, authToken, statementid) => {
  const [totalDiscardedHours, setTotalDiscardedHours] = useState(0);
  const [totalDiscardedHoursPension, setTotalDiscardedHoursPension] = useState(0);
  const [totalDiscardedHoursLTD, setTotalDiscardedHoursLTD] = useState(0);
  const [isDiscardedHoursLoading, setIsDiscardedHoursLoading] = useState(true);
  const [isDiscardedError, setIsDiscardedError] = useState(null);

  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid || !authToken || !statementid ) {
      return;
    }

    const fetchTotalDiscardedHours = async () => {
      try {
        const response = await handleGet({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${companyid}/jsondoc/discard/${statementid}`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        if (json.items.length > 0) {
          const filteredFinalData = json.items.reduce((acc, item) => {
            const penhrs = item.penhrs === undefined || item.penhrs === null || item.penhrs === "" ? 0 : item.penhrs < 0 ? 0 : item.penhrs;
            const ltdhrs = item.ltdhrs === undefined || item.ltdhrs === null || item.ltdhrs === "" ? 0 : item.ltdhrs < 0 ? 0 : item.ltdhrs;
            acc.push({ ...item, penhrs, ltdhrs });
            return acc;
          }, []);

          const sumOfPenhrs = ( 0 - (filteredFinalData.reduce((acc, item) => acc + (item.penhrs || 0), 0)));
          const sumOfLtdhrs = ( 0 - (filteredFinalData.reduce((acc, item) => acc + (item.ltdhrs || 0), 0)));

          const formattedSumOfPenhrs = parseFloat(sumOfPenhrs);
          const formattedSumOfLtdhrs = parseFloat(sumOfLtdhrs);

          const formattedTotalDiscardedHours = parseFloat(sumOfPenhrs + sumOfLtdhrs);

          setTotalDiscardedHoursPension(formattedSumOfPenhrs);
          setTotalDiscardedHoursLTD(formattedSumOfLtdhrs);
          setTotalDiscardedHours(formattedTotalDiscardedHours);
        }
      } catch (error) {
        console.error(error);
        setTotalDiscardedHoursPension(0);
        setTotalDiscardedHoursLTD(0);
        setTotalDiscardedHours(0);
        setIsDiscardedError(error);
      } finally {
        setIsDiscardedHoursLoading(false);
      }
    };

    fetchTotalDiscardedHours();

    return () => {
      cancel = true;
    };
  }, [accountid, companyid, authToken, statementid]);

  return { totalDiscardedHoursPension, totalDiscardedHoursLTD, totalDiscardedHours, isDiscardedHoursLoading, isDiscardedError };
};
