import React, { useState, Fragment } from "react";

// AWS Amplify - API - Request Handler
import { handlePut } from "../../utils/amplifyInstance";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Material-UI
import {
  Box,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";

// Hooks
import useCompanyAddress from "../../common/useCompanyAddress";

// Validation Rules
import { validateCompanyAddress } from "../../common/ValidationRules";

// Helper Functions
import { isUndefined, LookupValues } from "../../common/HelperFunctions";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";

// Third Party
import { useSnackbar } from "notistack";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

// ===========================|| COMPANY ADDRESS ||=========================== //

function CompanyAddress(props) {
  const { authToken } = useSession();
  const { accountid, companyid, roleid } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [reload, setReload] = useState(0);
  const [cancelEdit, setCancelEdit] = useState(false);

  const updateData = async () => {
    try {
      // const response = await put("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/address",
      // {
      //   body: [data]
      // });
      const response = await handlePut({
        apiName: "EmployerApiGw",
        path: "/employer/" + accountid + "/" + companyid + "/address",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [data],
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        enqueueSnackbar("Company Address updated successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(json.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const submitData = () => {
    if (!cancelEdit) {
      setReadOnlyMode(true);
      updateData();
    }
  };

  // Hook Props - Company Address
  const hookProps = { ...props, authToken };

  // Custom Hook - useCompanyAddress
  const {
    data,
    isLoading,
    errors,
    handleChange,
    handleLOVChange,
    handleSubmit,
  } = useCompanyAddress(submitData, validateCompanyAddress, hookProps, reload);

  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingScreen text="Loading Company Address" />
      ) : data === undefined ? (
        <Typography variant="h6" sx={{ p: 1 }}>
          Company Address not found!
        </Typography>
      ) : (
        <form onSubmit={handleSubmit} noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                id="address1"
                name="address1"
                label="Address line 1"
                value={data.address1 || ""}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                error={!isUndefined(errors.address1)}
                helperText={errors.address1}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                id="address2"
                name="address2"
                label="Address line 2"
                value={data.address2 || ""}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                error={!isUndefined(errors.address2)}
                helperText={errors.address2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="off"
                required
                fullWidth
                id="city"
                name="city"
                label="City"
                value={data.city}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                error={!isUndefined(errors.city)}
                helperText={errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <LookupValues
                name="province"
                label="Province"
                value={data.province}
                readOnlyMode={readOnlyMode}
                authToken={authToken}
                onValueChange={handleLOVChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                fullWidth
                id="postalcode"
                name="postalcode"
                label="Postal Code"
                value={data.postalcode}
                InputProps={{
                  readOnly: readOnlyMode,
                }}
                onChange={handleChange}
                error={!isUndefined(errors.postalcode)}
                helperText={errors.postalcode}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <LookupValues
                name="country"
                label="Country"
                value={data.country}
                readOnlyMode={readOnlyMode}
                authToken={authToken}
                onValueChange={handleLOVChange}
              />
            </Grid>
            <Grid container item xs={12} sm={3} justifyContent="flex-end">
              <Box visibility={readOnlyMode ? "visible" : "hidden"}>
                <Tooltip title="Edit Address">
                  <span>
                    <IconButton
                      disabled={roleid !== 1 ? true : false}
                      onClick={(e) => {
                        setCancelEdit(false);
                        setReadOnlyMode(false);
                      }}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
              <Box
                visibility={!readOnlyMode ? "visible" : "hidden"}
                sx={{ position: "absolute" }}
              >
                <Tooltip title="Save Changes">
                  <IconButton type="submit" color="primary" size="large">
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel Changes">
                  <IconButton
                    color="secondary"
                    onClick={(event) => {
                      setCancelEdit(true);
                      var i = reload + 1;
                      setReload(i);
                      setReadOnlyMode(true);
                    }}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Fragment>
  );
}

export default CompanyAddress;
