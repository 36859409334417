import React, { useState, useEffect, useCallback, Fragment } from "react";

// AWS Amplify - API - Request Handler
import {
  handleGet,
  handlePut,
  handlePost,
  handleDelete,
} from "../../utils/amplifyInstance";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Material-UI
import { Box, Tooltip } from "@mui/material";
import { blue } from "@mui/material/colors";

// DevExtreme React Grid
import { EditingState, DataTypeProvider } from "@devexpress/dx-react-grid";

// DevExtreme React Grid Material-UI
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableEditRow,
} from "@devexpress/dx-react-grid-material-ui";

// Helper Functions
import {
  FocusableCell,
  NoDataCell,
  StringEditCell,
  LookupEditCell,
} from "../../common/HelperFunctions";

// Validation Rules
import { validPhoneFormat } from "../../common/ValidationRules";

// Plugins
import TableEditColumnPlugIn from "../../plugins/TableEditColumnPlugIn";

// Third Party
import { useSnackbar } from "notistack";

// Icons
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

const getRowId = (row) => row.correspondencenumid;

const PhoneFormatter = ({ value }) =>
  value.replace(/(\(?\d{3}\)?)(\d{3})(\d{4})/, validPhoneFormat);

const PhoneTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={PhoneFormatter} {...props} />
);

const Cell = (props) => {
  if (props.column.name === "edits") {
    if (props.row.edits.length !== 0) {
      return (
        <FocusableCell {...props}>
          <Tooltip
            arrow
            leaveDelay={250}
            title={props.row.edits.map((e, i) => (
              <span key={i}>
                {e}
                <br />
              </span>
            ))}
          >
            <ErrorIcon color={"secondary"} fontSize={"small"} />
          </Tooltip>
        </FocusableCell>
      );
    } else {
      return <FocusableCell {...props} />;
    }
  } else if (
    props.column.name === "correspondencetype" &&
    props.row.correspondencetype === "WORK EMAIL"
  ) {
    return (
      <FocusableCell {...props}>
        {props.value}
        <Tooltip
          arrow
          leaveDelay={250}
          title="If you need to change your work email address, please contact the Plan Office."
        >
          <InfoIcon
            sx={{
              color: blue[500],
            }}
            fontSize={"small"}
          />
        </Tooltip>
      </FocusableCell>
    );
  }

  return <FocusableCell {...props} />;
};

const EditCell = ({ accountid, authToken, ...props }) => {
  if (props.column.name === "edits") {
    if (props.row.edits.length !== 0) {
      return (
        <TableEditRow.Cell {...props}>
          <Tooltip
            arrow
            leaveDelay={250}
            title={props.row.edits.map((e, i) => (
              <span key={i}>
                {e}
                <br />
              </span>
            ))}
          >
            <ErrorIcon color={"secondary"} fontSize={"small"} />
          </Tooltip>
        </TableEditRow.Cell>
      );
    } else {
      return <TableEditRow.Cell {...props} />;
    }
  }

  if (props.column.name === "correspondencetype") {
    return (
      <LookupEditCell
        accountid={accountid}
        authToken={authToken}
        readOnlyMode={false}
        {...props}
      />
    );
  }

  if (props.column.name === "correspondencevalue") {
    return <StringEditCell {...props} />;
  }

  return <TableEditRow.Cell {...props} />;
};

const TableHeaderContent = ({ column, children, classes, ...restProps }) => {
  return (
    <TableHeaderRow.Content column={column} {...restProps}>
      {column.name === "correspondencetype" ? (
        column.title === "EMAIL" ? (
          <Box pr={1}>
            <EmailIcon fontSize="small" />
          </Box>
        ) : (
          <Box pr={1}>
            <PhoneIcon fontSize="small" />
          </Box>
        )
      ) : null}
      {children}
    </TableHeaderRow.Content>
  );
};

function ContactPoints(props) {
  const { authToken } = useSession();
  const { accountid, companyid, roleid, contactid, pointsgroup } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [columns] = useState([
    { name: "correspondencetype", title: pointsgroup },
    {
      name: "correspondencevalue",
      title: pointsgroup === "EMAIL" ? "ADDRESSES" : "NUMBERS",
    },
    { name: "edits", title: " " },
  ]);
  const [columnExtensions] = useState([
    { columnName: "correspondencetype", align: "left", width: 150 },
    { columnName: "correspondencevalue", align: "left" },
    { columnName: "edits", align: "center", width: 30 },
  ]);
  const [editingStateColumnExtensions] = useState([
    { columnName: "edits", editingEnabled: false },
  ]);

  const [rows, setRows] = useState([]);
  const [editingRowIds, getEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [isNewRow, setIsNewRow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      if (!accountid || !companyid || !contactid || !authToken) {
        return;
      }

      try {
        // var response = await get("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/contact/" + contactid + "/points?group=" + pointsgroup);
        var response = await handleGet({
          apiName: "EmployerApiGw",
          path:
            "/employer/" +
            accountid +
            "/" +
            companyid +
            "/contact/" +
            contactid +
            "/points?group=" +
            pointsgroup,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        response = JSON.parse(
          JSON.stringify(json, (k, v) => {
            return k === "edits"
              ? v == null
                ? []
                : v.split(",")
              : v == null
              ? ""
              : v;
          })
        );

        setRows(response.items);
      } catch (e) {
        console.log(e);
        setRows([]);
      }

      setIsLoading(false);
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, [accountid, companyid, contactid, pointsgroup, authToken]);

  const newCorrespondenceNumId = async () => {
    let correspondencenumid = {};
    try {
      // const response = await get("EmployerApiGw", "/employer/" + accountid + "/correspondencenumid");
      const response = await handleGet({
        apiName: "EmployerApiGw",
        path: "/employer/" + accountid + "/correspondencenumid",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const json = await body.json();

      correspondencenumid = json.items[0];
    } catch (e) {
      console.log(e);
    }

    return correspondencenumid;
  };

  const updateData = async (rowData) => {
    try {
      // const response = await put("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/contact/" + contactid + "/points",
      // {
      //   body: [rowData]
      // });
      const response = await handlePut({
        apiName: "EmployerApiGw",
        path:
          "/employer/" +
          accountid +
          "/" +
          companyid +
          "/contact/" +
          contactid +
          "/points",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [rowData],
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        enqueueSnackbar(json.message, { variant: "success" });
      } else {
        enqueueSnackbar(json.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const deleteData = async (rowData) => {
    try {
      if (Array.isArray(rowData) && rowData.length > 0) {
        const correspondenceNumId = rowData[0];
        const response = await handleDelete({
          apiName: "EmployerApiGw",
          path:
            "/employer/" +
            accountid +
            "/" +
            companyid +
            "/contact/" +
            contactid +
            "/" +
            correspondenceNumId,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const { body } = await response;
        const json = await body.json();
  
        if (json.result === 1) {
          enqueueSnackbar(json.message, { variant: "success" });
        } else {
          enqueueSnackbar(json.message, { variant: "error" });
        }
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const addData = async (rowData) => {
    try {
      // const response = await post("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/contact/" + contactid + "/points",
      // {
      //   body: [rowData]
      // });
      const response = await handlePost({
        apiName: "EmployerApiGw",
        path:
          "/employer/" +
          accountid +
          "/" +
          companyid +
          "/contact/" +
          contactid +
          "/points",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [rowData],
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        enqueueSnackbar(json.message, { variant: "success" });
      } else {
        enqueueSnackbar(json.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const changeAddedRows = (value) => {
    const initialized = value.map((row) =>
      Object.keys(row).length
        ? row
        : {
            contactid: contactid * 1,
            correspondencetype: "",
            correspondencevalue: "",
            correspondencegroup: pointsgroup,
            edits: "",
          }
    );

    setAddedRows(initialized);
  };

  const editsRemove = (edits, value) => {
    return (edits || []).filter((e) => e !== value);
  };

  const editsAdd = (edits, value) => {
    edits = edits || [];
    if (!edits.includes(value)) {
      edits.push(value);
    }

    return edits;
  };

  const commitChanges = async ({ added, changed, deleted }) => {
    let changedRows = [];
    let modifiedRow = {};

    if (added) {
      let correspondencenumid = await newCorrespondenceNumId();
      modifiedRow = { ...correspondencenumid, ...added[0] };

      if (modifiedRow.correspondencegroup === "EMAIL") {
        if (
          !/^([\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6})?$/.test(
            modifiedRow.correspondencevalue
          )
        ) {
          enqueueSnackbar("Invalid Email Address.", { variant: "error" });
          modifiedRow.edits = editsAdd(
            modifiedRow.edits,
            "Invalid Email Address."
          );
        } else {
          modifiedRow.edits = editsRemove(
            modifiedRow.edits,
            "Invalid Email Address."
          );
        }
      }

      if (modifiedRow.correspondencegroup === "PHONE") {
        if (!/^[0-9]{10}$/.test(modifiedRow.correspondencevalue)) {
          enqueueSnackbar("Invalid Phone Number.", { variant: "error" });
          modifiedRow.edits = editsAdd(
            modifiedRow.edits,
            "Invalid Phone Number."
          );
        } else {
          modifiedRow.edits = editsRemove(
            modifiedRow.edits,
            "Invalid Phone Number."
          );
        }
      }

      if (modifiedRow.edits.length === 0) {
        addData(modifiedRow);
      }

      changedRows = [modifiedRow, ...rows];
    }

    if (changed) {
      let correspondencenumid = Object.keys(changed)[0] * 1;
      let original = rows.filter(
        (row) => row.correspondencenumid === correspondencenumid
      )[0];
      if (typeof changed[correspondencenumid] !== "undefined") {
        if (
          Object.keys(changed[correspondencenumid]).toString() ===
          "correspondencevalue"
        ) {
          if (original.correspondencegroup === "EMAIL") {
            if (
              !/^([\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6})?$/.test(
                changed[correspondencenumid].correspondencevalue
              )
            ) {
              enqueueSnackbar("Invalid Email Address.", { variant: "error" });
              original.edits = editsAdd(
                original.edits,
                "Invalid Email Address."
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                "Invalid Email Address."
              );
            }
          }

          if (original.correspondencegroup === "PHONE") {
            if (
              !/^[0-9]{10}$/.test(
                changed[correspondencenumid].correspondencevalue
              ) || /[a-zA-Z]/.test(
                changed[correspondencenumid].correspondencevalue
              )
            ) {
              enqueueSnackbar("Invalid Phone Number.", { variant: "error" });
              original.edits = editsAdd(
                original.edits,
                "Invalid Phone Number."
              );
            } else {
              original.edits = editsRemove(
                original.edits,
                "Invalid Phone Number."
              );
            }
          }
        }
      }

      modifiedRow = { ...original, ...changed[correspondencenumid] };
      if (modifiedRow.edits.length === 0) {
        updateData(modifiedRow);
      }

      changedRows = rows.map((row) =>
        changed[row.correspondencenumid] ? modifiedRow : row
      );
    }

    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(
        (row) => !deletedSet.has(row.correspondencenumid)
      );
      deleteData(deleted);
    }

    setRows(changedRows);
  };

  const PreEditCell = useCallback(
    (props) => {
      return (
        <EditCell accountid={accountid} authToken={authToken} {...props} />
      );
    },
    [accountid, authToken]
  );

  return (
    <Fragment>
      {isLoading ? null : (
        <Fragment>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <EditingState
              columnExtensions={editingStateColumnExtensions}
              editingRowIds={editingRowIds}
              onEditingRowIdsChange={getEditingRowIds}
              rowChanges={rowChanges}
              onRowChangesChange={setRowChanges}
              addedRows={addedRows}
              onAddedRowsChange={changeAddedRows}
              onCommitChanges={commitChanges}
            />
            {pointsgroup === "PHONE" ? (
              <PhoneTypeProvider for={["correspondencevalue"]} />
            ) : null}
            <VirtualTable
              height="auto"
              noDataCellComponent={NoDataCell}
              columnExtensions={columnExtensions}
              cellComponent={Cell}
            />
            <TableHeaderRow contentComponent={TableHeaderContent} />
            <TableEditRow cellComponent={PreEditCell} />
            <TableEditColumnPlugIn
              addButtonDisabled={isNewRow}
              setIsNewRow={setIsNewRow}
              roleid={roleid}
            />
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}

export default ContactPoints;
