import { create } from "zustand";

/* // ==============================|| SHARED -  FILE PARSING  ||============================== // */

// Current Employee ID - used to track the current employee being processed
export const useTrackCurrentEmployeeID = create((set) => ({
  currentEmployeeId: null,
  setCurrentEmployeeId: (id) => set({ currentEmployeeId: id }),
}));

// Current Employee Full Name
export const useTrackCurrentEmployeeFullName = create((set) => ({
  employeeFullName: {
    employeeGivenName: "",
    employeeSurname: "",
  },
  setEmployeeFullName: (value) => set({ employeeFullName: value }),
}));

// Current Employee Full Name Database 
export const useTrackCurrentEmployeeFullNameDB = create((set) => ({
    employeeDatabaseFullName: {
        employeeDatabaseGivenName: "",
        employeeDatabaseSurName: "",
  },
  setEmployeeDatabaseFullName: (fullName) => set({ employeeDatabaseFullName: fullName }),
}));

// Track the number of discarded rows
export const useTrackFinalDiscardedRows = create((set) => ({
  finalDiscardedRows: 0,
  setFinalDiscardedRows: (value) => set({ finalDiscardedRows: value }),
}));