import React, { Fragment, useState, useEffect } from "react";

// Storage - AWS Amplify
import { list } from 'aws-amplify/storage';

// Material-ui
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";

// Helpers Functions
import { DownloadFile } from "../../../../common/HelperFunctions";

// Project imports
import CustomLoadingScreen from "../../../../ui-components/CustomLoadingScreen";

// Icons
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";

const InstructionsBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

const DownloadFileSample = () => {
  const [file, setFile] = useState([]);
  const [isSampleAvailable, setIsSampleAvailable] = useState(true);

  useEffect(() => {
    const fetchFiles = async () => {
      var options = {
        level: "public",
        pageSize: 1000,
      };

      var fileList = await list("manuals/", options);
      fileList = fileList.items.filter(file => {
        return file.key.split("/").pop() !== "";
      });

      const sampleFile = fileList.find((file) =>
        file.key.includes("ta_upload_sample.csv")
      );

      setFile(sampleFile.key);
      setIsSampleAvailable(false);
    };

    fetchFiles();
  }, []);

  return isSampleAvailable ? (
    <CustomLoadingScreen text="blank sample CSV file ..." />
  ) : (
    <Fragment>
      <Grid item xs={12}>
        <Box
          sx={{
            WebkitFontSmoothing: "antialiased",
            borderRadius: "0.5rem",
            backgroundColor: "#dde0e4",
            padding: "0.75rem",
            marginTop: "1rem",
            marginBottom: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container alignItems="center" direction="row">
            <Grid item>
              <SummarizeOutlinedIcon sx={{ marginRight: "10px" }} />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                As an alternative to uploading a file, you have the option to
                download a blank sample CSV file. After downloading, you can
                proceed to input your company data into the file and
                subsequently upload it into the system.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography variant="subtitle1">
          You can download and view a
          <Button
            variant="text"
            onClick={() => {
              DownloadFile(file, null);
            }}
            style={{ textDecoration: "underline" }}
          >
            {" "}
            blank sample CSV File
          </Button>{" "}
          to use as a template. If you use the sample file to create your own import file, make sure you remove the employee examples.
        </Typography>
        <Typography variant="subtitle1">
         When you upload your own file, please ensure the first row of your file contains the following column headers: 
        </Typography>
        <InstructionsBox>
          <List sx={{ listStyleType: "disc" }}>
            <List>
              <ListItem disablePadding sx={{ display: "list-item" }}>
                <List>
                  <ListItem disablePadding  sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="Company Number" />
                  </ListItem>
                  <ListItem disablePadding sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="Period Start" />
                  </ListItem>
                  <ListItem disablePadding sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="Period End" />
                  </ListItem>
                  <ListItem disablePadding sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="SIN (Social Insurance Number)" />
                  </ListItem>
                  <ListItem disablePadding sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="Given names" />
                  </ListItem>
                  <ListItem disablePadding sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="Surnames" />
                  </ListItem>
                  <ListItem disablePadding sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="Employment Type" />
                  </ListItem>
                  <ListItem disablePadding sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="Pension Hours" />
                  </ListItem>
                  <ListItem disablePadding sx={{ '&::before': { content: '"➤"', marginRight: '10px' } }}>
                    <ListItemText primary="LTD Hours" />
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </List>
        </InstructionsBox>
      </Grid>
    </Fragment>
  );
};

export default DownloadFileSample;
