import React, { useEffect, useState, Fragment } from "react";

// AWS Amplify - Auth
import { fetchUserAttributes } from "aws-amplify/auth";

// AWS Amplify - API
import { handleGet } from "../../utils/amplifyInstance";

// Material-UI
import { AppBar, Tab, Tabs } from "@mui/material";

// Session Context
import { useSession } from "../../context/AuthSession";

// Main Context
import { useMainContext } from "../../context/MainContext";

// Project Imports
import ContactsDetails from "../account-details/ContactsDetails";
import PlanDetails from "../account-details/PlanDetails";
import CompanyAddress from "../account-details/CompanyAddress";
import MessageDialog from "../account-banner/MessageDialog";

// Icons
import ContactsIcon from "@mui/icons-material/Contacts";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ViewListIcon from "@mui/icons-material/ViewList";

function Dashboard() {
  const { authToken } = useSession();
  const { account, companyid } = useMainContext();

  // Account Information
  const accountid = account.map((i) => i.accountid)[0];
  const roleid = account.map((i) => i.roleid)[0];

  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      if (!accountid || !authToken) {
        return;
      }

      try {
        // const response = await get("EmployerApiGw", "/employer/" + accountid + "/publicmessage");
        const response = await handleGet({
          apiName: "EmployerApiGw",
          path: `/employer/${accountid}/publicmessage`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        setData(json.items);
        if (json.items.length > 0) {
          const { profile } = await fetchUserAttributes();
          if (profile === "admin") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
          setOpenDialog(true);
        }
      } catch (e) {
        setData([]);
        console.log(e);
      }
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, [accountid, authToken]);

  const handleChange = (event, newValue) => {
    event.persist();
    setValue(newValue);
  };

  return (
    <Fragment>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange}>
          <Tab icon={<ContactsIcon />} label="Contacts" />
          <Tab icon={<HomeWorkIcon />} label="Address" />
          <Tab icon={<ViewListIcon />} label="Plans" />
        </Tabs>
      </AppBar>
      <br />
      {value === 0 ? (
        <ContactsDetails
          accountid={accountid}
          companyid={companyid}
          roleid={roleid}
        />
      ) : value === 1 ? (
        <CompanyAddress
          accountid={accountid}
          companyid={companyid}
          roleid={roleid}
        />
      ) : (
        <PlanDetails accountid={accountid} companyid={companyid} />
      )}
      {!isAdmin ? (
        <MessageDialog
          accountid={accountid}
          open={openDialog}
          setOpenDialog={setOpenDialog}
          row={data}
        />
      ) : null}
    </Fragment>
  );
}

export default Dashboard;
