import React, { useState, useMemo, Fragment } from 'react';

// AWS Amplify - API - Request Handler
import { handlePost } from '../../../../utils/amplifyInstance';

// Auth Session
import { useSession } from '../../../../context/AuthSession';

// Material-ui
import { Button, Box, Grid, Stack, Typography } from '@mui/material';

//  Project Imports
import NewCardEmployeeData from './NewCardEmployeeData';
import CustomLoadingScreen from '../../../../ui-components/CustomLoadingScreen';

// Third-party
import { useSnackbar } from 'notistack';

const NewEmployeeData = (props) => {
  const {
    accountid,
    companyid,
    roleid,
    submitData,
    handleNext,
    employeeNewSinNumber,
    setEmployeeNewSinNumber,
  } = props;

  // Auth Session
  const { authToken } = useSession();

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [verificationData, setVerificationData] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // Selected Card Id for toggling
  const [selectedCardId, setSelectedCardId] = useState(null);

  const validateDataSinNumber = useMemo(
    () =>
      submitData.map(({ SIN, Givennames, Surname, workstatusstartdate }) => ({
        SIN,
        Givennames,
        Surname,
        workstatusstartdate,
      })),
    [submitData]
  );

  const checkSinNumber = async (data) => {
    if (!data) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await handlePost({
        apiName: 'EmployerApiGw',
        path: '/employer/' + accountid + '/' + companyid + '/new-employees',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: data,
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result) {
        setEmployeeNewSinNumber(json.result);
        setVerificationData(true);
      } else {
        setEmployeeNewSinNumber([]);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.message || 'An error occurred';
      enqueueSnackbar(errorMessage, { variant: 'error' });
      setVerificationData(false);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  const handleCardToggle = (index) => {
    setSelectedCardId(selectedCardId === index ? null : index);
  };

  return (
    <Fragment>
      <Typography variant="h5" gutterBottom sx={{ m: 1 }}>
        New employee data detection
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ m: 1 }}>
        A new employee has been identified. Please open the employee information card by clicking
        the arrow to the right side of their name. <br />
      </Typography>
      <Grid container spacing={1}>
        {employeeNewSinNumber.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom sx={{ m: 1 }}>
              Click refresh <em>after</em> adding new employee information card(s). <br />
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-start">
            <Button
              variant="contained"
              sx={{ my: 1, ml: 1 }}
              type="submit"
              onClick={() => checkSinNumber(validateDataSinNumber)}
            >
              {employeeNewSinNumber.length === 0 ? 'Verify employee information' : 'Refresh'}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {isLoading ? (
        <CustomLoadingScreen text="Loading..." />
      ) : (
        <Fragment>
          {employeeNewSinNumber.length === 0 && verificationData === true && refresh === false ? (
            <div>
              <Box sx={{ p: 2 }}>
                <Typography variant="body1" gutterBottom>
                  <strong>No new employees found.</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  If there are no new employees, click the Next button to continue.
                </Typography>
              </Box>
            </div>
          ) : (
            <div>
              <Box sx={{ p: 1 }}>
                {employeeNewSinNumber.length > 0 && (
                  <Typography variant="body1" gutterBottom>
                    Fill in all the mandatory fields by clicking the pencil icon located on the
                    bottom of the information card. <br />
                  </Typography>
                )}

                <NewCardEmployeeData
                  employeeNewSinNumber={employeeNewSinNumber}
                  selectedCardId={selectedCardId}
                  handleCardToggle={handleCardToggle}
                  accountid={accountid}
                  companyid={companyid}
                  roleid={roleid}
                  setRefresh={setRefresh}
                />
              </Box>
            </div>
          )}
        </Fragment>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{ my: 3, ml: 1 }}
              type="submit"
              disabled={
                employeeNewSinNumber.length > 0 ? true : false || verificationData === false
              }
              onClick={() => handleNext()}
            >
              Next
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default NewEmployeeData;
