import React, { useState, Fragment } from "react";

// Material-UI
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

// Custom Styles
import { customInput } from "../../../../theme/Typography";

// Project Imports
import { useUser } from "../../../../context/AuthContext";
import { ExceptionErrorCode } from "../../../../common/CognitoErrors"
import ErrorBox from "../../../../ui-components/NotificationErrorBox";

// Third-party
import * as Yup from "yup";
import { Formik } from "formik";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// ===========================|| LOGIN FORM ||=========================== //

const AwsCognitoLogin = ({ handleNext, setLoginData }) => {
  // Theme
  const theme = useTheme();
  
  // User Context
  const { handleSignIn } = useUser();

  // Password Show/Hide
  const [showPassword, setShowPassword] = useState(false);
  
  // Handle Password Show/Hide
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle Mouse Down Password
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
  // Handle Error
  async function handleError(error) {
    try {
      const errorCode = await ExceptionErrorCode(error);
      if (errorCode === "UserLambdaValidationException") {
        let errorMessage = error.toString();
        errorMessage = errorMessage.replace("UserLambdaValidationException: PreAuthentication failed with error :-", "").trim();
        return errorMessage;
      } else {
        return error.message;
      }
    } catch (err) {
      return error.message;
    }
  }

  return (
    <Fragment>
      <Formik
        initialValues={{
          accountID: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          accountID: Yup.string()
          .required("Account ID is required")
          .min(8, "Your Account ID needs to be 8 digits number")
          .max(8, "Your Account ID needs to be 8 digits number"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // login logic - Context Cognito Log
            const username = values.accountID.trim();
            const password = values.password.trim();
            await handleSignIn({
              username,
              password,
            });
            setLoginData({
              accountID: values.accountID,
              password: values.password,
            });
            setStatus({ success: true });
            setSubmitting(false);
            handleNext();
          } catch (error) {
            const errorMessage = await handleError(error);
            setStatus({ success: false });
            setErrors({ submit: errorMessage });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Box sx={{ maxWidth: '400px' }}>
          <form noValidate onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={Boolean(touched.accountID && errors.accountID)}
              sx={customInput}
            >
              <InputLabel htmlFor="accountID">Account ID</InputLabel>
              <OutlinedInput
                id="accountID"
                name="accountID"
                type="text"
                value={values.accountID}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Account ID"
              />
              {touched.accountID && errors.accountID && (
                <FormHelperText error id="accountID-helper-text">
                  {touched.accountID && errors.accountID}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={customInput}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Password"
                autoComplete="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {touched.password && errors.password && (
                <FormHelperText error id="password-helper-text">
                  {touched.password && errors.password}
                </FormHelperText>
              )}
              <Box sx={{ m: 1 }} />
              {errors.submit && <ErrorBox message={errors.submit} />}
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <Button
                disableElevation
                fullWidth
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#008521",
                  color: theme.palette.primary.contrastText,
                  "&:hover": { backgroundColor: "#008521" },
                }}
              >
                Sign In
              </Button>
            </Box>
            <Box sx={{ mt: 2 }} />
          </form>
          </Box>
        )}
      </Formik>
    </Fragment>
  );
};

export default AwsCognitoLogin;
