
import { setColumn } from "./setColumn";

function calculateLevenshteinDistance(a, b) {
    const m = [];

    if (!(a && b)) return (b || a).length;

    for (let i = 0; i <= b.length; m[i] = [i++]);
    for (let j = 0; j <= a.length; m[0][j] = j++);

    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            m[i][j] = b.charAt(i - 1) === a.charAt(j - 1)
                ? m[i - 1][j - 1]
                : Math.min(
                    m[i - 1][j - 1] + 1,
                    Math.min(m[i][j - 1] + 1, m[i - 1][j] + 1)
                );
        }
    }

    return m[b.length][a.length];
}

const findMatch = (header, fields, autoMapDistance) => {
    const smallestValue = fields.reduce(
      (acc, field) => {
        const distance = Math.min(
          ...[
            calculateLevenshteinDistance(field.key, header),
            ...(field.alternateMatches?.map((alternate) =>
            calculateLevenshteinDistance(alternate, header)
            ) || []),
          ]
        );
        return distance < acc.distance || acc.distance === undefined
          ? { value: field.key, distance }
          : acc;
      },
      { value: '', distance: undefined }
    );
    return smallestValue.distance <= autoMapDistance
      ? smallestValue.value
      : undefined;
  };

  export function getMatchedColumns(columns, fields, data, autoMapDistance) {
    return columns.reduce((arr, column) => {
      const autoMatch = findMatch(column.header, fields, autoMapDistance);
  
      if (autoMatch) {
        const field = fields.find((f) => f.key === autoMatch);
        const duplicateIndex = arr.findIndex((col) => "value" in col && col.value === field.key);
        const duplicate = arr[duplicateIndex];
  
        if (duplicate && 'value' in duplicate) {
          const updatedArr = [
            ...arr.slice(0, duplicateIndex),
            setColumn(duplicate, field.data),
            ...arr.slice(duplicateIndex + 1),
            setColumn(column),
          ];
  
          return calculateLevenshteinDistance(duplicate.value, column.header) < calculateLevenshteinDistance(field.key, column.header)
            ? updatedArr
            : [
              ...arr.slice(0, duplicateIndex),
              setColumn(duplicate),
              ...arr.slice(duplicateIndex + 1),
              setColumn(column, field, data),
            ];
        } else {
          return [
            ...arr,
            setColumn(column, field, data),
          ];
        }
      } else {
        return [
          ...arr,
          setColumn(column),
        ];
      }
    }, []);
  }
  