import React, { Fragment, useState, lazy, Suspense } from 'react';

// DevExtreme React Grid
import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core';

// Material-UI
import { Dialog, DialogContent, Tooltip, IconButton, InputLabel } from '@mui/material';

// Project Imports
import CustomLoadingScreen from '../ui-components/CustomLoadingScreen';

// Icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Project Imports - Lazy Load
const EmployeeInfo = lazy(() => import('../components/employee-list/EmployeeInfo'));

const pluginDependencies = [{ name: 'Toolbar' }];

function ELToolbarActions(props) {
  const { accountid, companyid, roleid, setRefresh, showTAOnly, setShowTAOnly } = props;
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Fragment>
      <Plugin dependencies={pluginDependencies}>
        <Template name="toolbarContent">
          {(params) => (
            <Fragment>
              <TemplatePlaceholder>
                {() => (
                  <Tooltip title="Add Employee">
                    <span>
                      <IconButton
                        size="small"
                        disabled={props.addButtonDisabled || props.roleid !== 1}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenDialog(true);
                        }}
                      >
                        <PersonAddIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </TemplatePlaceholder>
              <Tooltip title={showTAOnly === 'Y' ? 'Show All Employees' : 'Show Current Employees'}>
                <IconButton
                  onClick={() => {
                    if (showTAOnly === 'Y') {
                      setShowTAOnly('N');
                    } else {
                      setShowTAOnly('Y');
                    }
                  }}
                  size="large"
                >
                  {showTAOnly === 'Y' ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Tooltip>
              <InputLabel
                sx={{
                  paddingLeft: '8px',
                  fontSize: 12,
                }}
              >
                {props.rowcount} Row(s)
              </InputLabel>
            </Fragment>
          )}
        </Template>
      </Plugin>
      <div onClick={(event) => event.stopPropagation()}>
        <Dialog open={openDialog}>
          <DialogContent>
            <Suspense fallback={<CustomLoadingScreen text="Loading Employee Information" />}>
              <EmployeeInfo
                setOpenDialog={setOpenDialog}
                accountid={accountid}
                companyid={companyid}
                roleid={roleid}
                setRefresh={setRefresh}
              />
            </Suspense>
          </DialogContent>
        </Dialog>
      </div>
    </Fragment>
  );
}

export default ELToolbarActions;
