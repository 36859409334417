import React from "react";

// Materia UI
import { Grid, Typography } from "@mui/material";

const ContributionItem = ({ title, value }) => (
  <Grid
    container
    spacing={1}
    alignItems="center"
    justifyContent="space-between"
  >
    <Grid item>
      <Typography variant="subtitle1">
        <span>{title}</span>
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="h6" sx={{ fontWeight: "normal" }}>
        {value}
      </Typography>
    </Grid>
  </Grid>
);

export default ContributionItem;
