import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const DateRangeBox = ({
  payPeriodStart,
  payPeriodEnd,
  typographyColor,
  arrowColor,
  arrowFontSize,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          mt: 2,
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '11px',
            color: `${typographyColor}`,
          }}
        >
          {payPeriodStart ? payPeriodStart : 'N/A'}
        </Typography>

        <ArrowForwardIcon
          sx={{
            color: `${arrowColor}`,
            fontSize: `${arrowFontSize}`,
          }}
        />

        <Typography
          variant="body2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '11px',
            color: `${typographyColor}`,
          }}
        >
          {payPeriodEnd ? payPeriodEnd : 'N/A'}
        </Typography>
      </Box>
    </>
  );
};

export default DateRangeBox;
