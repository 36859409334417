import { useQuery } from "@tanstack/react-query";

// AWS Amplify - API - Request Handler
import { handleGet } from "../utils/amplifyInstance";

const useCompanySummary = (accountid, companyid, authToken) => {
  const fetchCompanySummary = async () => {
    if (!accountid || !companyid || !authToken) {
      return;
    }

    try {
      const response = await handleGet({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/${companyid}/accountsummary`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = response;
      const json = await body.json();

      return json.items;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["companySummary", accountid, companyid, authToken],
    queryFn: fetchCompanySummary,
    enabled: !!accountid && !!companyid && !!authToken,
  });

  if (error) {
    console.error("Error fetching company summary: ", error);
  }

  return { data, error, isLoading };
};

export default useCompanySummary;
