import React from "react";

// Material UI
import { Tooltip, IconButton, Box } from "@mui/material";

// Icons
import InfoIcon from "@mui/icons-material/Info";

const InfoTooltip = ({ initialHours, finalHours }) => {
  return (
    <Tooltip
      title={
        <>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              sx={{
                width: 14,
                height: 14,
                borderRadius: "50%",
                bgcolor: "rgba(135, 206, 235, 0.5)",
              }}
            />{" "}
            {initialHours}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
            <Box
              sx={{
                width: 14,
                height: 14,
                borderRadius: "50%",
                bgcolor: "rgba(60, 179, 113, 0.5)",
              }}
            />{" "}
            {finalHours}
          </Box>
        </>
      }
    >
      <IconButton>
        <InfoIcon style={{ color: '#004085' }} />
      </IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
