import { useCallback } from "react";

/**
 * Custom hook to toggle employee validation.
 * @param {Object} props - The properties.
 * @param {Function} props.setCurrentEmployeeId - Function to set current employee id.
 * @param {Function} props.setSelection - Function to set selection.
 * @param {Function} props.setEmployeeFullName - Function to set employee full name.
 * @param {Function} props.setEmployeeDatabaseFullName - Function to set employee database full name.
 * @returns {Object} - Object containing the handleEmployeeValidationOpen and handleEmployeeValidationClose functions.
 *
 */

const useEmployeeValidationToggle = ({
  setCurrentEmployeeId,
  setSelection,
  setEmployeeFullName,
  setEmployeeDatabaseFullName,
}) => {
  const handleEmployeeValidationOpen = useCallback(
    (
      employeelistid,
      employeeIndex,
      employeeGivenName,
      employeeSurname,
      employeeDatabaseGivenName,
      employeeDatabaseSurName
    ) => {
      setCurrentEmployeeId(employeelistid);
      setSelection([employeeIndex]);
      setEmployeeFullName({ employeeGivenName, employeeSurname });
      setEmployeeDatabaseFullName({
        employeeDatabaseGivenName,
        employeeDatabaseSurName,
      });
    },
    [setCurrentEmployeeId, setSelection, setEmployeeFullName, setEmployeeDatabaseFullName]
  );

  const handleEmployeeValidationClose = useCallback(() => {
    setCurrentEmployeeId(null);
    setSelection([]);
    setEmployeeFullName({ employeeGivenName: "", employeeSurname: "" });
  }, [setCurrentEmployeeId, setSelection, setEmployeeFullName]);

  return {
    handleEmployeeValidationOpen,
    handleEmployeeValidationClose,
  };
};

export default useEmployeeValidationToggle;
