export function generateColumns(fields) {
  return [
    ...fields.map((column) => ({
      title: column.label,
      name: column.key.replace(/\s+/g, ''),
      minWidth: 200,
      resizable: true,
      editingEnabled: true,
    })),
  ];
}

export default generateColumns;
