import { Grid, Typography } from "@mui/material";

const ContributionGrandTotal = ({ amount }) => {
  return (
    <>
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: "normal" }}>
            {amount}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ContributionGrandTotal;
