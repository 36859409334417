import React, { Fragment, useState } from "react";

import { useDropzone } from "react-dropzone";

// Material ui
import {
  Avatar,
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Icons
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

const UploadFileAttachment = (props) => {
  const {
    files = [],
    handleChange,
    onRemove,
    onRemoveAll,
    onUpload,
    ...other
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept: {
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "text/csv": [".csv"],
      },
      ...other,
    });

  const [showFileRejections, setShowFileRejections] = useState(false);

  const hasAnyFiles = files.length > 0;
  const hasFilesRejections = fileRejections.length > 0;

  return (
    <Fragment>
      <Box
        sx={{
          alignItems: "center",
          border: 1,
          borderRadius: 1,
          borderStyle: "dashed",
          borderColor: theme.palette.secondary.rust,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          p: 2,
          ...(isDragActive && {
            backgroundColor: "",
            opacity: 0.5,
          }),
          "&:hover": {
            backgroundColor: "",
            cursor: "pointer",
            opacity: 0.5,
          },
        }}
        {...getRootProps()}
      >
        <input {...getInputProps({ onChange: handleChange })} />

        <Stack
          alignItems="center"
          justifyContent="center"
          direction={isSmallScreen ? "column" : "row"}
          spacing={3}
        >
          <Avatar
            sx={{
              backgroundColor: "transparent",
              height: 64,
              width: 64,
            }}
          >
            <SvgIcon>
              <AttachFileIcon
                sx={{
                  height: 60,
                  width: 60,
                  color: "#008522",
                  opacity: 0.2,
                }}
              />
            </SvgIcon>
          </Avatar>
          <Stack spacing={1}>
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.secondary.rust,
                fontFamily: `'PT Sans Narrow', sans-serif`,
              }}
            >
              ATTACH DOCUMENTS
            </Typography>
            <Typography variant="body2">
              Allowed formats: .csv, .xls .xlsx spreadsheets accepted
            </Typography>
            <Typography variant="body2" mb={2}>
              Maximum size: 5MB
            </Typography>
            <Typography variant="body2" mb={1}>
              You can upload and work on one file at a time
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.secondary.rust,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.rust,
                  opacity: 0.9,
                },
              }}
            >
              <Typography variant="SelectFileButtonText">
                SELECT A FILE
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
      {hasFilesRejections && (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            startIcon={<ErrorOutlineIcon color="error" />}
            onClick={() => setShowFileRejections(!showFileRejections)}
          >
            {showFileRejections ? "Hide" : "Show"} {fileRejections.length} file
            rejection{fileRejections.length > 1 ? "s" : ""}
          </Button>
          <Collapse in={showFileRejections}>
            <List sx={{ mt: 2 }}>
              {fileRejections.map((rejection) => (
                <ListItem
                  key={rejection.file.path}
                  sx={{
                    border: 1,
                    borderColor: "error.main",
                    borderRadius: 1,
                    "& + &": {
                      mt: 1,
                    },
                  }}
                >
                  <ListItemIcon>
                    <ErrorOutlineIcon color="error" />
                  </ListItemIcon>
                  <ListItemText
                    primary={rejection.file.name}
                    primaryTypographyProps={{
                      variant: "body1",
                      style: {
                        flexWrap: "wrap",
                        fontWeight: 600,
                        color: theme.palette.secondary.red,
                      },
                    }}
                    secondary={rejection.errors[0].message}
                    secondaryTypographyProps={{
                      variant: "body2",
                      style: {
                        flexWrap: "wrap",
                        color: theme.palette.secondary.red,
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      )}
      {hasAnyFiles && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <List>
            {files.map((file) => {
              const extension = file.name.split(".").pop();

              return (
                <ListItem
                  key={file.path}
                  sx={{
                    border: 1,
                    borderColor: "divider",
                    borderRadius: 1,
                    "& + &": {
                      mt: 1,
                    },
                  }}
                >
                  <ListItemIcon>
                    <UploadFileOutlinedIcon extension={extension} />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{
                      variant: "body1",
                      style: {
                        flexWrap: "wrap",
                        fontWeight: 600,
                        color: theme.palette.secondary.greenLight,
                      },
                    }}
                  />
                  <Tooltip title="Remove">
                    <IconButton edge="end" onClick={() => onRemove?.(file)}>
                      <CloseIcon
                        fontSize="medium"
                        sx={{
                          color: theme.palette.secondary.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              onClick={onRemoveAll}
              size="small"
              type="button"
            >
              Remove All
            </Button>
          </Stack>
        </Box>
      )}
    </Fragment>
  );
};

export default UploadFileAttachment;
