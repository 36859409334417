import { useCallback } from "react";

// Notistack
import { useSnackbar } from "notistack";

/**
 * Custom hook to delete selected rows from data.
 *
 * @param {Object} props - The properties.
 * @param {Array} props.data - The data array.
 * @param {Array} props.selection - The selected rows.
 * @param {Function} props.setData - Function to set data.
 * @param {Function} props.setDeletedRows - Function to set deleted rows.
 * @param {Function} props.setSelection - Function to set selection.
 * @param {Function} props.setCurrentEmployeeId - Function to set current employee id.
 * @param {Function} props.setEmployeeFullName - Function to set employee full name.
 * @returns {Object} - Object containing the deleteSelectedRows function.
 */

const useDeleteSelectedRows = ({
  data,
  selection,
  setData,
  setDeletedRows,
  setSelection,
  setCurrentEmployeeId,
  setEmployeeFullName,
}) => {  
  const { enqueueSnackbar } = useSnackbar();

  const deleteSelectedRows = useCallback(() => {
    if (selection.length === 0) {
      enqueueSnackbar(
        "Please select at least one row before attempting to delete.",
        { variant: "warning" }
      );
      return;
    }

    // Store Deleted Rows - Memoized
    const rowsToDelete = data.filter((value) =>
      selection.includes(value.__index)
    );
    const updatedRows = data.filter(
      (value) => !selection.includes(value.__index)
    );
    setDeletedRows(prevDeletedRows => [...prevDeletedRows, ...rowsToDelete]);
    setData(updatedRows);
    setSelection([]);
    setCurrentEmployeeId(null);
    setEmployeeFullName({ employeeGivenName: "", employeeSurname: "" });
  }, [
    data,
    selection,
    setData,
    setDeletedRows,
    setSelection,
    setCurrentEmployeeId,
    setEmployeeFullName,
    enqueueSnackbar,
  ]);

  return { deleteSelectedRows };
};

export default useDeleteSelectedRows;
