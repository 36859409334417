// Material - UI 
import { tabClasses } from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

const SummaryTabs = ({ children, tabSlotProps, sx, ...other }) => {
  const theme = useTheme();

  return (
    <Tabs
      sx={{
        gap: { sm: 0 },
        minHeight: 48,
        flexShrink: 0,
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
        [`& .${tabsClasses.flexContainer}`]: {
          gap: 0,
          ...tabSlotProps?.flexContainer,
        },
        [`& .${tabClasses.scrollButtons}`]: {
          borderRadius: 2,
          minHeight: "inherit",
          ...tabSlotProps?.scrollButtons,
        },
        [`& .${tabClasses.scroller}`]: {
          p: 1,
          ...tabSlotProps?.scroller,
        },
        [`& .${tabClasses.indicator}`]: {
          py: 1,
          height: 1,
          backgroundColor: "transparent",
          "& > span": {
            width: 1.5,
            height: 1.5,
            borderRadius: 1,
            display: "block",
            backgroundColor: theme.palette.primary.main,
            boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
            ...tabSlotProps?.indicator,
          },
        },

        [`& .${tabClasses.root}`]: {
          py: 1,
          px: 2,
          zIndex: 1,
          minHeight: "auto",
          textAlign: "center",
          ...tabSlotProps?.tab,
          [`&.${tabClasses.selected}`]: {
            ...tabSlotProps?.selected,
          },
        },
        ...sx,
      }}
      {...other}
      TabIndicatorProps={{
        children: <span />,
      }}
    >
      {children}
    </Tabs>
  );
};


export default SummaryTabs;