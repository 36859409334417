import React, { Fragment, useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet, handlePost } from '../../../../utils/amplifyInstance';

// Auth Session
import { useSession } from '../../../../context/AuthSession';

// Material-ui
import { Typography } from '@mui/material';

// Project Imports
import FileParsingConfirmation from './steps/FileParsingConfirmation';

// Helper Functions
import {
  FileExists,
  UploadFile,
  PostMessage,
  validateFileSize,
  validateFileType,
} from '../../../../common/HelperFunctions';

// Third-party
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';

const ConfirmationFile = ({
  submitData,
  setSubmitData,
  uploadFileData,
  accountid,
  companyid,
  statementid,
  cognito_identity_uuid,
  statementRow,
  processedRows,
  discardedRows,
  deletedRows,
  setDeletedRows,
  employeeActiveCheckList,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  recordedRows,
  setRecordedRows,
  setProcessedRows,
  setConfirmClose,
  handleClose,
}) => {
  // Auth Session
  const { authToken } = useSession();

  // Notify
  const { enqueueSnackbar } = useSnackbar();

  // Summary data
  const [summaryData, setSummaryData] = useState([]);
  const [totalHours, setTotalHours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Disable button after submit
  const [disableButtonAfterSubmit, setDisableButtonAfterSubmit] = useState(false);

  // Calculate the total hours for pension and LTD
  useEffect(() => {
    let cancel = false;

    const calculateFileHours = async () => {
      if (!submitData) {
        return;
      }

      try {
        let totalHours = submitData?.validData.reduce(
          (acc, value) => {
            const { penhrs, ltdhrs } = value;
            acc.penhrs += Number(penhrs);
            acc.ltdhrs += Number(ltdhrs);
            return acc;
          },
          { penhrs: 0, ltdhrs: 0 }
        );

        if (cancel) {
          return;
        }

        if (totalHours) {
          setTotalHours(totalHours);
        } else {
          setTotalHours([]);
        }
      } catch (e) {
        console.log(e);
        setTotalHours([]);
      }
      setIsLoading(false);
    };

    calculateFileHours();

    return () => {
      cancel = true;
    };
  }, [submitData]);

  // Set summary data
  useEffect(() => {
    let cancel = false;

    const fetchSummaryTotal = async () => {
      if (!accountid || !companyid || !authToken || !statementid) {
        return;
      }

      try {
        // let response = await API.get(
        //   "EmployerApiGw",
        //   "/employer/" +
        //     accountid +
        //     "/" +
        //     companyid +
        //     "/billingstatements" +
        //     "?offset=0" +
        //     "&statementid=" +
        //     statementid
        // );

        let response = await handleGet({
          apiName: 'EmployerApiGw',
          path: `/employer/${accountid}/${companyid}/billingstatements?offset=0&statementid=${statementid}`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        response = JSON.parse(
          JSON.stringify(json, (k, v) => {
            return k === 'edits' ? (v == null ? [] : v.split(',')) : v == null ? '' : v;
          })
        );

        if (response.items.length > 0) {
          setSummaryData(response.items);
        } else {
          setSummaryData([]);
        }
      } catch (e) {
        console.log(e);
        setSummaryData([]);
      }

      setIsLoading(false);
    };

    fetchSummaryTotal();

    return () => {
      cancel = true;
    };
  }, [accountid, companyid, authToken, statementid]);

  // Function to refresh the page after 30 seconds of submission success
  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 60000);
  };

  // Function to send a message to the employer when a file is uploaded
  const onFileInputChange = async (uploadFile) => {
    try {
      let result = null;
      let file = uploadFile[0];

      if (!validateFileType('memberdetail', file)) {
        enqueueSnackbar('Invalid file type. Please upload a valid file.', {
          variant: 'error',
        });
      } else if (!validateFileSize(file)) {
        enqueueSnackbar('File size exceeds the limit. Please upload a file less than 5MB.', {
          variant: 'error',
        });
      } else {
        var fileExists = await FileExists(companyid, cognito_identity_uuid, 'protected', file.name);
        if (
          !fileExists ||
          (fileExists &&
            window.confirm(
              'A file with the same name already exists. Do you want to overwrite it?'
            ))
        ) {
          result = await UploadFile(file, companyid, cognito_identity_uuid);
        } else {
          enqueueSnackbar('File upload cancelled.', { variant: 'info' });
        }
      }

      if (result) {
        enqueueSnackbar('File uploaded successfully.', {
          variant: 'success',
        });

        var attachmentInfo = [];
        attachmentInfo.push({
          filelink: result,
        });

        var message = {
          parentid: null,
          viewedon: format(new Date(), 'yyyyMMddHHmmss'),
          ownerid: parseInt(companyid),
          ownertypeid: 2,
          author: accountid.toString(),
          sender: 'ERCONTACT',
          publicmessageid: null,
          attachments: attachmentInfo,
        };

        message.note =
          'Autogenerated Message: File successfully uploaded for statement ID: ' + statementid;
        PostMessage(accountid, message, authToken)
          .then(() => {
            enqueueSnackbar('Message sent successfully!', {
              preventDuplicate: true,
              persist: true,
              variant: 'success',
            });
          })
          .catch((error) => {
            enqueueSnackbar('Message failed: ' + error, { variant: 'error' });
          });
      }
    } catch (e) {
      enqueueSnackbar('File Upload to server failed!' + e.message, {
        variant: 'error',
      });
    }
  };

  // Api call to submit TA hours to the database
  const onSubmit = async (data) => {
    if (!data || !accountid || !companyid || !authToken || !statementid) {
      return;
    }
    // Final version
    const version = 'final';

    try {
      const response = await handlePost({
        apiName: 'EmployerApiGw',
        path: '/employer/' + accountid + '/' + companyid + '/excelfile/' + version + '/' + statementid,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: data,
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        enqueueSnackbar('Successfully submitted TA hours.', {
          variant: 'success',
        });
        // Upload the file to the server
        onFileInputChange(uploadFileData);
        // Set the state to disable the button and confirm close
        setDisableButtonAfterSubmit(true);
        setConfirmClose(true);
        refreshPage();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
        setDisableButtonAfterSubmit(false);
        setConfirmClose(false);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.message || 'An error occurred';
      enqueueSnackbar(errorMessage, { variant: 'error' });
      setDisableButtonAfterSubmit(false);
      setConfirmClose(false);
    }
  };

  // Save Disabled Row for follow up report.
  const submitDiscardedRows = async (data) => {
    if (!data) {
      return;
    }

    try {
      const response = await handlePost({
        apiName: 'EmployerApiGw',
        path: `/employer/${accountid}/${companyid}/jsondoc/discard/${statementid}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: data,
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        // Don't show the snackbar if the discarded rows are saved successfully internal process.
        console.log('Successfully saved discarded rows.');
      } else {
        enqueueSnackbar(json.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Typography variant="h5" gutterBottom sx={{ m: 2 }}>
        READY TO SUBMIT
      </Typography>
      <FileParsingConfirmation
        submitData={submitData}
        setSubmitData={setSubmitData}
        deletedRows={deletedRows}
        setDeletedRows={setDeletedRows}
        employeeActiveCheckList={employeeActiveCheckList}
        accountid={accountid}
        onClaimEmployeeIds={onClaimEmployeeIds}
        totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
        statementRow={statementRow}
        totalHours={totalHours}
        statementid={statementid}
        summaryData={summaryData}
        isLoading={isLoading}
        discardedRows={discardedRows}
        processedRows={processedRows}
        recordedRows={recordedRows}
        setProcessedRows={setProcessedRows}
        setRecordedRows={setRecordedRows}
        disableButtonAfterSubmit={disableButtonAfterSubmit}
        onSubmit={onSubmit}
        submitDiscardedRows={submitDiscardedRows}
        handleClose={handleClose} 
      />
    </Fragment>
  );
};

export default ConfirmationFile;
