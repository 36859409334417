import React, { lazy } from 'react';

// PropTypes validation
import PropTypes from 'prop-types';

// Project Import 
import Loadable from "../ui-components/Loadable";

// Main Context
import MainContextProvider from '../context/MainContext';

// Home Component
import Home from '../components/home/Home';

// Dashboard Routes 
import Dashboard from '../components/dashboard/Dashboard';

// Account Routes
import AccountSummary from '../components/account-summary/AccountSummary';

// Messages Routes
import MessagesList from '../components/mail/MessagesList';
import MessageCreate from '../components/mail/MessageCreate';
import MessageThread from '../components/mail/MessageThread';

// Files Routes
import ProtectedFileList from '../components/protected-file-list/ProtectedFileList';
import PublicFileList from '../components/public-file-list/PublicFileList';

// Employee Routes
import EmployeeList from '../components/employee-list/EmployeeList';

// Billing Routes
import BillingStatement from '../components/billing-statement/BillingStatement';

// Payment Routes 
import ViewPAD from '../components/payment-agreement/ViewPAD';

// AuthGuard Context
const AuthGuard = Loadable(lazy(() => import("../auth-guard/AuthGuard").catch(e=> window.location.reload())));



// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: "/",
    element: (
        <MainContextProvider>
          <AuthGuard>
            <Home />
          </AuthGuard>
        </MainContextProvider>
    ),
    children: [
        {
            path: "/",
            element: <Dashboard />,
        },
        {
            path: "/account-summary",
            element: <AccountSummary />,
        },
        {
            path:"/messages/inbox",
            element: <MessagesList  type="inbox" />,
        },
        {
            path:"/messages/sent",
            element: <MessagesList  type="sent" />,
        },
        {
            path: "/messages/new",
            element: <MessageCreate />,
        },
        {
            path: "/messages/thread/:messageid",
            element: <MessageThread />,
        },
        {
            path: "/myfiles",
            element: <ProtectedFileList />,
        },
        {
            path: "/manuals",
            element: <PublicFileList />,
        },
        {
            path: "/employee-list",
            element: <EmployeeList />,
        },
        {
            path: "/billing-statement",
            element: <BillingStatement />,
        },
        {
            path: "/view-pad",
            element: <ViewPAD />,
        },
    ],
};

MainRoutes.propTypes = {
    element: PropTypes.node,
};

export default MainRoutes;