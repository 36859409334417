import { ColumnType } from "../MatchColumnsFile";

const booleanWhitelist = {
  yes: true,
  no: false,
  true: true,
  false: false,
};

const normalizeCheckboxValue = (value) => {
  if (value && value.toLowerCase() in booleanWhitelist) {
    return booleanWhitelist[value.toLowerCase()];
  }
  return false;
};

export const normalizeTableData = (columns, data, fields) =>
  data.map((row) =>
    columns.reduce((acc, column, index) => {
      const curr = row[index];
      const field = fields.find((field) => field.key === column.value);
      switch (column.type) {
        case ColumnType.matchedCheckbox: {
          if (
            "booleanMatches" in field.fieldType &&
            Object.keys(field.fieldType.booleanMatches || []).length
          ) {
            const booleanMatchKey = Object.keys(
              field.fieldType.booleanMatches || []
            ).find((key) => key.toLowerCase() === curr?.toLowerCase());
            const booleanMatch =
              field.fieldType.booleanMatches?.[booleanMatchKey];
            acc[column.value] = booleanMatchKey
              ? booleanMatch
              : normalizeCheckboxValue(curr);
          } else {
            acc[column.value] = normalizeCheckboxValue(curr);
          }
          return acc;
        }
        case ColumnType.matched: {
          acc[column.value] = curr === "" ? null : curr;
          return acc;
        }
        case ColumnType.matchedSelect:
        case ColumnType.empty:
        case ColumnType.ignored: {
          return acc;
        }
        default:
          return acc;
      }
    }, {})
  );
