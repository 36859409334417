import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';

// Material-ui
import { Box, Grid, Button, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Hooks
import useDeleteSelectedRows from '../../ValidationFile/hooks/useDeleteSelectedRows';
import useDefaultWorkStatusEndDate from '../../ValidationFile/hooks/useDefaultWorkStatusEndDate';
import useErrorDetection from '../../ValidationFile/hooks/useErrorDetection';
import useWarningDetection from '../../ValidationFile/hooks/useWarningDetection';

// Store
import {
  useTrackCurrentEmployeeID,
  useTrackCurrentEmployeeFullName,
  useTrackCurrentEmployeeFullNameDB,
} from '../../../store/useFileParsingStore';

// project imports
import TableValidation from '../../ValidationFile/TableValidation';
import EmployerNameColumnCard from '../../ValidationFile/components/EmployeeNameColumnCard';
import ErrorDetectedCollapse from '../../ValidationFile/components/ErrorDetectedCollapse';
import DiscardSelectedRowButton from '../../ValidationFile/components/DiscardSelectedRowButton';
import DefaultEndDateButton from '../../ValidationFile/components/DefaultEndDateButton';
import SwitchErrorFilter from '../../ValidationFile/components/SwitchErrorFilter';
import SwitchWarningFilter from '../../ValidationFile/components/SwitchWarningFilter';

// UI Components
import GuideCard from '../../../../../ui-components/GuideCard';

// Utils
import { fields } from '../../../fields';
import { addErrorsAndRunHooks } from '../../ValidationFile/utils/dataMutations';
import { generateColumns } from '../../ValidationFile/utils/generateColumns';

// Third party
import { useSnackbar } from 'notistack';

// Error - Data Correction
const contentErrorItems = [
  {
    contentTitle: 'Data Correction',
    contentDescription:
      'Please correct the data indicated in red. Hover over the error for an explanation.',
  },
  {
    contentTitle: 'Data Not Required',
    contentDescription:
      ' If an entire row is red, this means the data is not required. Please delete by selecting the check box located on the left side of the row and click the button below: “Discard selected rows”.',
  },
];

// Warninig - Data Highlight
const contentWarningItems = [
  {
    contentTitle: 'Employee Status',
    contentDescription:
      'Rows that are highlighted in yellow are for employees with an invalid status end-date or no hours reported. Please change their status if they are not currently active or change their status end-date.',
  },
];

// Info - Instructions
const contentInfoItems = [
  {
    contentTitle: 'Errors Toggle',
    contentDescription:
      'To quickly access errors, use the toggle switch located below: “Show only rows with errors”.',
  },
  {
    contentTitle: 'Warnings Toggle',
    contentDescription:
      'To quickly access warnings, use the toggle switch located below: “Show only rows with warnings”.',
  },
  {
    contentTitle: 'Set Default End Date',
    contentDescription:
      'If you do not know the status end date, check box(es) on the left and click “Default end date” button at top right. This will automatically enter your billing statement end date.',
  },
];

const TableActivateValidation = ({
  handleNext,
  submitData,
  setSubmitData,
  setDeletedRows,
  employeeActiveCheckList,
  accountid,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  statementRow,
  setNumOfProcessedRows,
}) => {
  // Get the previous data from the previous step and add errors and unique index.
  const [data, setData] = useState(
    useMemo(
      () => addErrorsAndRunHooks(employeeActiveCheckList, fields),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  );

  // Theme
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const stackAlignment = isSmallScreen ? 'flex-start' : 'center';
  const stackDirection = isSmallScreen ? 'column' : 'row';

  // Notistack
  const { enqueueSnackbar } = useSnackbar();

  // Columns Mapping
  const columns = useMemo(() => generateColumns(fields), []);

  // Member Activate selection
  const [activateSelection, setActivateSelection] = useState([]);

  // Track current employee id and full name
  const { currentEmployeeId, setCurrentEmployeeId } = useTrackCurrentEmployeeID();
  const { employeeFullName, setEmployeeFullName } = useTrackCurrentEmployeeFullName();
  const { employeeDatabaseFullName, setEmployeeDatabaseFullName } =
    useTrackCurrentEmployeeFullNameDB();

  // Filter by errors.
  const [filterByErrors, setFilterByErrors] = useState(false);

  // Filter by warnings.
  const [filterByWarnings, setFilterByWarnings] = useState(false);

  // Show collapse when there is an error in the data.
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);

  // Error Collapse Ref
  const errorCollapseRef = useRef(null);

  // Track switch changes for filters.
  const [switchChanges, setSwitchChanges] = useState(false);

  // Control Pagination filter by errors and warnings
  useEffect(() => {
    let cancel = false;

    const trackFilterStatus = () => {
      if (cancel) {
        return;
      }

      if (!filterByErrors && !filterByWarnings) {
        setSwitchChanges(false);
      } else {
        setSwitchChanges(filterByErrors || filterByWarnings);
      }
    };

    trackFilterStatus();

    return () => {
      cancel = true;
    };
  }, [filterByErrors, filterByWarnings]);

  // Update the Data with new errors.
  const updateData = useCallback(
    (rows) => {
      setData(addErrorsAndRunHooks(rows, fields));
    },
    [setData]
  );

  // Custom hook to delete selected rows from data.
  const { deleteSelectedRows } = useDeleteSelectedRows({
    data,
    selection: activateSelection,
    setData,
    setDeletedRows,
    setSelection: setActivateSelection,
    setCurrentEmployeeId,
    setEmployeeFullName,
  });

  // custom hook to detect errors in the data.
  const errorDetected = useErrorDetection(data);

  // custom hook to detect warnings in the data.
  const warningDetected = useWarningDetection(data);

  // Update Row Data when user changes the data in the table.
  const updateRow = useCallback(
    (rows, changedData) => {
      const changes = changedData?.indexes.reduce((acc, index) => {
        // when data is filtered val !== actual index
        const realIndex = data.findIndex((value) => value.__index === rows[index].__index);
        acc[realIndex] = rows[index];
        return acc;
      }, {});
      const newData = Object.assign([], data, changes);
      updateData(newData);

      // Add the indexes of the changed rows to activateSelection
      const newSelection = [...new Set([...activateSelection, ...changedData?.indexes])];
      setActivateSelection(newSelection);
    },
    [data, updateData, activateSelection]
  );

  // Workstatus that requires WorkstatusStart date and WorkstatusEnd date
  const requireStartAndEndDateWorkStatuses = ['S', 'W', 'L', 'I', 'ESA', 'E'];

  // Handle select default work status end date
  const { handleSelectDefaultWorkStatusEndDate } = useDefaultWorkStatusEndDate({
    data,
    selection: activateSelection,
    updateData,
    setSelection: setActivateSelection,
    requireStartAndEndDateWorkStatuses,
    statementRow,
  });

  // Table Filter
  const tableData = useMemo(() => {
    if (filterByErrors || filterByWarnings) {
      return data.filter((value) => {
        if (value?.__errors || value?.__warnings) {
          return (
            Object.values(value.__errors || {}).filter(
              (err) => filterByErrors && err.level === 'error'
            ).length +
            Object.values(value.__warnings || {}).filter(
              (warn) => filterByWarnings && warn.level === 'warning'
            ).length
          );
        }
        return false;
      });
    }
    return data;
  }, [data, filterByErrors, filterByWarnings]);

  // Handle Selection Change
  const handleSelectionChange = (newSelection) => {
    setActivateSelection(newSelection);
  };

  // Function to scroll to the error collapse
  const scrollToErrorCollapse = () => {
    if (errorCollapseRef.current) {
      errorCollapseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Submit Restore Data
  const submitRestoreData = async () => {
    const restoreData = data
      .filter((_, index) => activateSelection.includes(index))
      .reduce((acc, value) => {
        const { __index, __errors, ...values } = value;
        acc.push(values);
        return acc;
      }, []);
    const mergedData = [...submitData.validData, ...restoreData];
    setSubmitData({ validData: mergedData });

    if (activateSelection.length === 0) {
      handleNext();
      setShowSubmitAlert(false);
    } else {
      enqueueSnackbar('The data has been change successfully', {
        variant: 'success',
      });
      setNumOfProcessedRows(activateSelection.length > 0 ? activateSelection.length : 0);
      handleNext();
      setShowSubmitAlert(false);
    }
  };

  // Submit Data
  const onActivateSubmit = () => {
    const invalidData = data.find((value) => {
      if (value?.__errors) {
        return Object.values(value.__errors)?.filter((err) => err.level === 'error').length;
      }
      return false;
    });
    if (!invalidData) {
      submitRestoreData();
    } else {
      setShowSubmitAlert(true);
      setTimeout(scrollToErrorCollapse, 1000);
    }
  };

  // Open Employee Validation
  const handleEmployeeValidationOpen = useCallback(
    (
      employeelistid,
      employeeIndex,
      employeeGivenName,
      employeeSurname,
      employeeDatabaseGivenName,
      employeeDatabaseSurName
    ) => {
      setCurrentEmployeeId(employeelistid);
      setActivateSelection([employeeIndex]);
      setEmployeeFullName({ employeeGivenName, employeeSurname });
      setEmployeeDatabaseFullName({
        employeeDatabaseGivenName,
        employeeDatabaseSurName,
      });
    },
    [setCurrentEmployeeId, setEmployeeFullName, setEmployeeDatabaseFullName]
  );

  // Close Employee Validation
  const handleEmployeeValidationClose = useCallback(() => {
    setCurrentEmployeeId(null);
    setActivateSelection([]);
    setEmployeeFullName({ employeeGivenName: '', employeeSurname: '' });
  }, [setCurrentEmployeeId, setEmployeeFullName]);

  // Employee Name Confirmation and Update
  const updateSelectedRows = (newEmployeeGivenames, newEmployeeSurname) => {
    const employeeRowIndex = data
      .filter((row) => activateSelection.includes(row.__index))
      .map((row) => data.indexOf(row));

    const updatedData = data.map((row) => {
      if (activateSelection.includes(row.__index)) {
        return {
          ...row,
          Givennames: newEmployeeGivenames,
          Surname: newEmployeeSurname,
        };
      } else {
        return row;
      }
    });
    updateRow(updatedData, { columns: {}, indexes: [employeeRowIndex] });
    handleEmployeeValidationClose();
    return;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <GuideCard
            mainTitle="Instructions"
            subTitle="Please follow the instructions below."
            cardHeaderColor="#e6effe"
            cardIconColor="rgb(0, 120, 215)"
            cardContentIcon="info"
            contentItems={contentInfoItems}
          />
        </Grid>
        {errorDetected || warningDetected ? (
          <>
            {errorDetected && (
              <Grid
                item
                xl={warningDetected ? 6 : 12}
                lg={warningDetected ? 6 : 12}
                md={warningDetected ? 6 : 12}
                sm={12}
                xs={12}
              >
                <GuideCard
                  mainTitle="Errors"
                  subTitle="Please review the items below."
                  cardHeaderColor="#FFF8F8"
                  cardIconColor="rgb(209, 65, 36)"
                  cardContentIcon="error"
                  heightSize={errorDetected && warningDetected ? '238px' : 'auto'}
                  contentItems={contentErrorItems}
                />
              </Grid>
            )}
            {warningDetected && (
              <Grid
                item
                xl={errorDetected ? 6 : 12}
                lg={errorDetected ? 6 : 12}
                md={errorDetected ? 6 : 12}
                sm={12}
                xs={errorDetected ? 6 : 12}
              >
                <GuideCard
                  mainTitle="Warnings"
                  subTitle="Please review the items below."
                  cardHeaderColor="#fcf2e9"
                  cardIconColor="rgb(255, 220, 76)"
                  cardContentIcon="warning"
                  heightSize={errorDetected && warningDetected ? '238px' : 'auto'}
                  contentItems={contentWarningItems}
                />
              </Grid>
            )}
          </>
        ) : null}
      </Grid>
      <Stack
        alignItems={stackAlignment}
        justifyContent="flex-start"
        alignContent={stackAlignment}
        direction={stackDirection}
        spacing={1}
        sx={{ ml: 1, mb: 2, mt: 2 }}
      >
        <DiscardSelectedRowButton deleteSelectedRows={deleteSelectedRows} />
        <DefaultEndDateButton
          handleSelectDefaultWorkStatusEndDate={handleSelectDefaultWorkStatusEndDate}
        />
        <SwitchErrorFilter filterByErrors={filterByErrors} setFilterByErrors={setFilterByErrors} />
        <SwitchWarningFilter
          filterByWarnings={filterByWarnings}
          setFilterByWarnings={setFilterByWarnings}
        />
      </Stack>
      <ErrorDetectedCollapse
        isOpen={showSubmitAlert}
        onClose={() => setShowSubmitAlert(false)}
      />
      <Box ref={errorCollapseRef} sx={{ m: 2 }} />
      <TableValidation
        rows={tableData}
        columns={columns}
        accountid={accountid}
        onClaimEmployeeIds={onClaimEmployeeIds}
        totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
        statementrow={statementRow}
        onRowsChange={updateRow}
        selection={activateSelection}
        switchChanges={switchChanges}
        handleSelectionChange={handleSelectionChange}
        onEmployeeValidationOpen={handleEmployeeValidationOpen}
      />
      <EmployerNameColumnCard
        open={!!currentEmployeeId}
        selection={activateSelection}
        employeelistid={currentEmployeeId || undefined}
        employeeNames={employeeFullName}
        employeeDatabaseFullName={employeeDatabaseFullName}
        condition={false}
        confirmation={updateSelectedRows}
        onClose={handleEmployeeValidationClose}
      />
      <Box sx={{ m: 3 }} />
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Button variant="contained" sx={{ ml: 1 }} onClick={onActivateSubmit} type="submit">
            {'Next'}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default TableActivateValidation;
