import React from 'react';

// Material-ui
import { Container, Grid } from '@mui/material';

// Hooks
import useErrorDetection from '../../../ValidationFile/hooks/useErrorDetection';

// Project imports
import TableVerification from '../../table/TableVerification';

// UI Components
import GuideCard from '../../../../../../ui-components/GuideCard';

// Error - Data Correction
const contentErrorItems = [
  {
    contentTitle: 'Data Correction',
    contentDescription:
      'Please correct the data indicated in red. Hover over the error for an explanation.',
  },
  {
    contentTitle: 'Data Not Required',
    contentDescription:
      ' If an entire row is red, this means the data is not required. Please delete by selecting the check box located on the left side of the row and click the button below: “Discard selected rows”.',
  },
];

// Info - Instructions
const contentInfoItems = [
  {
    contentTitle: 'Discarded Records',
    contentDescription:
      'The rows displayed below are records that you discarded in the validation process. Please fix the errors in these rows if you want to restore them. If you continue without restoring, they will be removed from this billing statement.',
  },
  {
    contentTitle: 'Restore Options',
    contentDescription:
      'If you want to restore a record, you can do so by selecting the check box located on the left side of the row and click the button below: “Restore discarded data”. Otherwise click on the “Continue without restoring” button to proceed to the next step.',
  },
];

const DiscardRowsVerification = ({
  submitData,
  setSubmitData,
  deletedRows,
  accountid,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  statementRow,
  setRecordedRows,
  handleNext,
  submitDiscardedRows,
}) => {

  // custom hook to detect errors in the data.
  const errorDetected = useErrorDetection(deletedRows ? deletedRows : []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <GuideCard
            mainTitle="Instructions"
            subTitle="Please follow the instructions below"
            cardHeaderColor="#e6effe"
            cardIconColor="rgb(0, 120, 215)"
            cardContentIcon="info"
            contentItems={contentInfoItems}
          />
          {errorDetected && (
            <Grid item xs={12}>
              <GuideCard
                mainTitle="Errors"
                subTitle="Please review the items below."
                cardHeaderColor="#FFF8F8"
                cardIconColor="rgb(209, 65, 36)"
                cardContentIcon="error"
                contentItems={contentErrorItems}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Container maxWidth="xl" disableGutters>
        <TableVerification
          submitData={submitData}
          setSubmitData={setSubmitData}
          deletedRows={deletedRows}
          accountid={accountid}
          onClaimEmployeeIds={onClaimEmployeeIds}
          totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
          statementRow={statementRow}
          setRecordedRows={setRecordedRows}
          handleNext={handleNext}
          submitDiscardedRows={submitDiscardedRows}
        />
      </Container>
    </>
  );
};

export default DiscardRowsVerification;
