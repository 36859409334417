import { useMemo, useState, useCallback } from "react";

const useSummaryTabs = (defaultValue) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const storeMemoValue = useMemo(
    () => ({ value, setValue, handleChange }),
    [handleChange, value]
  );

  return storeMemoValue;
};

export default useSummaryTabs;
