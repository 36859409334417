import React, { useState, useEffect, Fragment } from "react";

// Auth Context
import { useSession } from "../context/AuthSession";

// Hooks - useFetchCompanyList
import useFetchCompanyList from "../hooks/useCompanyListFetch";

// Material-UI
import {
  Button,
  Menu,
  MenuItem,
  ListItemText,
  CircularProgress,
  Typography,
} from "@mui/material";

// Project Imports
import ErrorBox from "../ui-components/NotificationErrorBox";

// Icons
import DownIcon from "@mui/icons-material/ArrowDropDown";

const ChangeCompanyMenu = (props) => {
  const { authToken } = useSession();
  const { accountid, setCompanyId } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // useFetchCompanyList Hook
  const { data, error, isLoading } = useFetchCompanyList(accountid, authToken);

  useEffect(() => {
    if (data && data.length > 0) {
      setCompanyId(data.map((i) => i.companyid)[0].toString());
    }
  }, [data, setCompanyId]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorBox message={error.message} />;
  }

  return (
    <div>
      {/* Change Company Button */}
      <Button
        id="change-company-button"
        aria-controls="change-company-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        endIcon={<DownIcon />}
      >
        Change Company
      </Button>
      {data && data.length > 0 && (
        <Menu
          id="change-company-menu"
          MenuListProps={{
            "aria-labelledby": "change-company-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {data.map((row, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  setCompanyId(row.companyid);
                  setAnchorEl(null);
                }}
              >
                <ListItemText
                  primary={row.companyname}
                  secondary={
                    <Fragment>
                      <Typography component="span" color="textPrimary">
                        {row.divisionname}
                      </Typography>
                      {row.divisionname && <br />}
                      {row.companynumber}
                    </Fragment>
                  }
                />
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </div>
  );
};

export default ChangeCompanyMenu;
