import React, { useState, useEffect, Fragment } from "react";

// React-Router
import { useParams } from "react-router-dom";

// AWS Amplify - API - Request Handler
import { handleGet, handlePut } from "../../utils/amplifyInstance";

// Auth Session
import { useSession } from "../../context/AuthSession";

// Main Context
import { useMainContext } from "../../context/MainContext";

// Material-UI
import {
  List,
  Divider,
  ListItem,
  ListItemText,
  InputBase,
  Button,
  Box,
} from "@mui/material";

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";
import MessageCreate from "./MessageCreate";

// Third Party
import { format, parseJSON } from "date-fns";

// Helper Functions
import { DownloadFile } from "../../common/HelperFunctions";

// Icons
import AttachFileIcon from "@mui/icons-material/AttachFile";

// ===========================|| MESSAGE THREAD ||=========================== //

function MessageThread() {
  const { authToken } = useSession();
  const { account, companyid } = useMainContext();

  const accountid = account.map((i) => i.accountid)[0];
  const cognito_identity_uuid = account.map((i) => i.cognito_identity_uuid)[0];

  const [messageList, setMessageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let { messageid } = useParams();

  useEffect(() => {
    const setViewedOn = async () => {
      if (!accountid || !companyid || !messageid || !authToken) {
        return;
      }

      // const response = await put("EmployerApiGw",
      //     "/employer/" + accountid + "/" + companyid + "/message/" + messageid);
      const response = await handlePut({
        apiName: "EmployerApiGw",
        path:
          "/employer/" + accountid + "/" + companyid + "/message/" + messageid,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === -1) {
        throw json.message;
      }
    };

    setViewedOn();
  }, [accountid, companyid, messageid, authToken]);

  useEffect(() => {
    let cancel = false;

    if (!accountid || !companyid || !messageid || !authToken) {
      return;
    }

    const fetchMessageList = async () => {
      try {
        // const response = await get("EmployerApiGw",
        //   "/employer/" + accountid + "/" + companyid + "/message/" + messageid);
        const response = await handleGet({
          apiName: "EmployerApiGw",
          path:
            "/employer/" +
            accountid +
            "/" +
            companyid +
            "/message/" +
            messageid,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (cancel) {
          return;
        }

        const { body } = await response;
        const json = await body.json();

        setMessageList(json.items);
      } catch (e) {
        console.log(e);
        setMessageList([]);
      }

      setIsLoading(false);
    };

    fetchMessageList();

    return () => {
      cancel = true;
    };
  }, [accountid, companyid, messageid, authToken]);

  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingScreen text="Loading message thread" />
      ) : (
        <Fragment>
          <MessageCreate
            replyTo={messageList.map((i) => i.messageid)}
            accountid={accountid}
            cognito_identity_uuid={cognito_identity_uuid}
            companyid={companyid}
          />
          <br />
          <Divider />
          <List>
            {messageList.map((message, index) => {
              return (
                <ListItem key={index}>
                  <ListItemText>
                    <InputBase
                      fullWidth
                      startAdornment={
                        <b>
                          {String(message.accountid) === message.author
                            ? "Sent:"
                            : "Received:"}
                        </b>
                      }
                      value={
                        "  " +
                        format(
                          parseJSON(message.created),
                          "yyyy-MM-dd HH:mm:ss"
                        )
                      }
                    />
                    <InputBase
                      fullWidth
                      startAdornment={<b>From:</b>}
                      value={
                        String(message.accountid) === message.author
                          ? "  You"
                          : "  Plan Office"
                      }
                    />
                    {message.attachments.map((row, index) => {
                      return (
                        <Button
                          key={index}
                          size="small"
                          variant="outlined"
                          startIcon={<AttachFileIcon fontSize={"small"} />}
                          onClick={() =>
                            DownloadFile(
                              row.filelink
                                .split("/")
                                .slice(2)
                                .join("/"),
                              cognito_identity_uuid
                            )
                          }
                          sx={{ textTransform: "none" }}
                        >
                          {row.filelink.split("/").pop()}
                        </Button>
                      );
                    })}
                    <Box pt={2}>
                      <InputBase fullWidth value={message.note} multiline />
                    </Box>
                    <Divider />
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Fragment>
      )}
    </Fragment>
  );
}

export default MessageThread;
