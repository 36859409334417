import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";

// AWS Amplify - Auth
import { fetchAuthSession } from "aws-amplify/auth";

// AWS Amplify - UI React
import { useAuthenticator } from "@aws-amplify/ui-react";

// ===========================|| AUTH SESSION CONTEXT ||=========================== //

const loadSession = (options = undefined) => {
  return fetchAuthSession(options)
    .then((session) => session)
    .catch((error) => {
      throw error;
    });
};

const contextTypeValues = {
  authToken: undefined,
  expiration: undefined,
  isConfigured: false,
  isSignedIn: false,
  loadSession: loadSession,
};

const SessionContext = createContext(contextTypeValues);

export const SessionProvider = ({ children }) => {
  const { authStatus } = useAuthenticator();
  const [session, setSession] = useState(undefined);
  const [isConfigured, setIsConfigured] = useState(false);
  const authToken = useMemo(
    () => session?.tokens?.idToken?.toString(),
    [session]
  );
  const accessToken = useMemo(
    () => session?.tokens?.accessToken,
    [session]
  );
  const username = useMemo(
    () => session?.tokens?.signInDetails?.loginId,
    [session]
  );
  const cognito_identity_uuid = useMemo(() => session?.identityId, [session]);
  const userSub = useMemo(() => session?.userSub, [session]);
  const expiration = useMemo(() => session?.credentials?.expiration, [session]);
  const isSignedIn = useMemo(() => authToken != null, [authToken]);

  const loadSessionCallback = useCallback(
    async (options) => {
      const session = await fetchAuthSession(options);
      setSession(session);
      return session;
    },
    [setSession]
  );

  useEffect(() => {
    switch (authStatus) {
      case "configuring":
        if (isConfigured) setIsConfigured(false);
        break;
      case "authenticated":
        loadSessionCallback();
        setIsConfigured(true);
        break;
      case "unauthenticated":
        setIsConfigured(true);
        break;
      default:
        break;
    }
  }, [authStatus, isConfigured, loadSessionCallback]);

  return (
    <SessionContext.Provider
      value={{
        authToken,
        accessToken,
        expiration,
        isConfigured,
        username,
        cognito_identity_uuid,
        userSub,
        isSignedIn,
        authStatus,
        loadSession: loadSessionCallback,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
