// AWS - Configuration file

const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_AWS_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_AWS_WEB_CLIENT,
      identityPoolId: import.meta.env.VITE_AWS_IDENTITY_POOL_ID,
      region: import.meta.env.VITE_AWS_REGION,
      authenticationFlowType: "CUSTOM_AUTH",
    },
  },
  API: {
    REST: {
      EmployerApiGw: {
        endpoint: import.meta.env.VITE_AWS_REST_API_ENDPOINT + process.env.VITE_REACT_APP_STAGE,
        region: import.meta.env.VITE_AWS_REGION,
      },
    },
  },
  Storage: {
    S3: {
      bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME,
      region: import.meta.env.VITE_AWS_REGION,
    },
  },
};

export default awsConfig;


// const awsConfig = {
//   Auth: {
//     Cognito: {
//       userPoolId: process.env.VITE_REACT_APP_STAGE === "prod" ? import.meta.env.VITE_AWS_USER_POOL_ID : import.meta.env.VITE_AWS_USER_POOL_ID_DEV,
//       userPoolClientId: process.env.VITE_REACT_APP_STAGE === "prod" ? import.meta.env.VITE_AWS_WEB_CLIENT : import.meta.env.VITE_AWS_WEB_CLIENT_DEV,
//       identityPoolId:  process.env.VITE_REACT_APP_STAGE === "prod" ? import.meta.env.VITE_AWS_IDENTITY_POOL_ID : import.meta.env.VITE_AWS_IDENTITY_POOL_ID_DEV,
//       region:  process.env.VITE_REACT_APP_STAGE === "prod" ? import.meta.env.VITE_AWS_REGION : import.meta.env.VITE_AWS_REGION_DEV,
//       authenticationFlowType: "CUSTOM_AUTH",
//     },
//   },
//   API: {
//     REST: {
//       EmployerApiGw: {
//         endpoint: process.env.VITE_REACT_APP_STAGE === "prod" ? import.meta.env.VITE_AWS_REST_API_ENDPOINT :  import.meta.env.VITE_AWS_REST_API_ENDPOINT_DEV + process.env.VITE_REACT_APP_STAGE,
//         region: process.env.VITE_REACT_APP_STAGE === "prod" ? import.meta.env.VITE_AWS_REGION : import.meta.env.VITE_AWS_REGION_DEV,
//       },
//     },
//   },
//   Storage: {
//     S3: {
//       bucket: import.meta.env.VITE_AWS_S3_BUCKET_NAME,
//       region: import.meta.env.VITE_AWS_REGION,
//     },
//   },
// };

// export default awsConfig;
