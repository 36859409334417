import React, { Fragment, useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handlePut } from '../../utils/amplifyInstance';

// Auth Session
import { useSession } from '../../context/AuthSession';

// Material-UI
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material';

// Helper Functions
import {
  isUndefined,
  LookupValues
} from "../../common/HelperFunctions";

// Hooks
import useContactInfoForm from "../../common/useContactInfoForm";

import {
  validateContactInfo
} from '../../common/ValidationRules';

// Project Imports
import CustomLoadingScreen from "../../ui-components/CustomLoadingScreen";

// Third Party 
import { useSnackbar } from 'notistack';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function ContactInfo(props) {
  const { authToken } = useSession();
  const { accountid, companyid, roleid, contactid } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [cancelEdit, setCancelEdit] = useState(false);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    setReadOnlyMode(true);
  }, [contactid]);

  const updateData = async () => {
    try {
      // const response = await put("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/contact/" + contactid,
      // {
      //   body: [data]
      // });

      const response = await handlePut({
        apiName: "EmployerApiGw",
        path: "/employer/" + accountid + "/" + companyid + "/contact/" + contactid,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [data]
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        enqueueSnackbar("Contact updated successfully!", {variant: "success"});
      }
      else {
        enqueueSnackbar(json.message, {variant: "error"});
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, {variant: "error"});
    }
  };

  const submitData = () => {
    if (!cancelEdit) {
      setReadOnlyMode(true);
      updateData();
    }
  };

  const { data, isLoading, errors, handleChange, handleLOVChange, handleSubmit} = useContactInfoForm(submitData, validateContactInfo, props, reload);

  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingScreen text="Loading Contact Information" />
      ) : (
        <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <LookupValues
              accountid={accountid}
              name="contacttype"
              label="Contact Type"
              value={data.contacttype}
              readOnlyMode={readOnlyMode}
              authToken={authToken}
              onValueChange={handleLOVChange}
            />
          </Grid>
          <Grid container item xs={12} sm={3} >
            <FormControlLabel
              label="Default Contact"
              control={
                <Checkbox
                  id="defaultcontact"
                  name="defaultcontact"
                  checked={data.defaultcontact === "Y"}
                  disabled={readOnlyMode}
                  onChange={handleChange}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="givennames"
              name="givennames"
              label="First name"
              value={data.givennames}
              InputProps={{
                readOnly: true
              }}
              onChange={handleChange}
              error={!isUndefined(errors.givennames)}
              helperText={errors.givennames}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="surname"
              name="surname"
              label="Last name"
              value={data.surname}
              InputProps={{
                readOnly: true
              }}
              onChange={handleChange}
              error={!isUndefined(errors.surname)}
              helperText={errors.surname}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              id="attn"
              name="attn"
              label="Attn"
              value={data.attn}
              InputProps={{
                readOnly: readOnlyMode
              }}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              required
              id="address1"
              name="address1"
              label="Address line 1"
              value={data.address1}
              InputProps={{
                readOnly: readOnlyMode
              }}
              onChange={handleChange}
              fullWidth
              error={!isUndefined(errors.address1)}
              helperText={errors.address1}
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              id="address2"
              name="address2"
              label="Address line 2"
              value={data.address2}
              InputProps={{
                readOnly: readOnlyMode
              }}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="City"
              value={data.city}
              InputProps={{
                readOnly: readOnlyMode
              }}
              onChange={handleChange}
              fullWidth
              error={!isUndefined(errors.city)}
              helperText={errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LookupValues
              accountid={accountid}
              name="province"
              label="Province"
              value={data.province}
              readOnlyMode={readOnlyMode}
              authToken={authToken}
              onValueChange={handleLOVChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              id="postalcode"
              name="postalcode"
              label="Postal code"
              value={data.postalcode}
              InputProps={{
                readOnly: readOnlyMode
              }}
              onChange={handleChange}
              fullWidth
              error={!isUndefined(errors.postalcode)}
              helperText={errors.postalcode}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <LookupValues
              accountid={accountid}
              name="country"
              label="Country"
              value={data.country}
              readOnlyMode={readOnlyMode}
              authToken={authToken}
              onValueChange={handleLOVChange}
            />
          </Grid>
          <Grid container item xs={12} sm={3} justifyContent="flex-end">
            <Box
              visibility={readOnlyMode ? "visible" : "hidden"}
            >
            <Tooltip title="Edit Contact">
              <span>
                <IconButton
                  disabled={(roleid !== 1)}
                  onClick={(e) => {
                    setCancelEdit(false);
                    setReadOnlyMode(false);
                  }}
                  size="large">
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            </Box>
            <Box
              visibility={!readOnlyMode ? "visible" : "hidden"}
              sx={{position:"absolute"}}
            >
            <Tooltip title="Save Changes">
              <IconButton type="submit" color="primary" size="large">
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel Changes">
              <IconButton
                color="secondary"
                onClick={(event) => {
                  setCancelEdit(true);
                  var i = reload + 1;
                  setReload(i);
                  setReadOnlyMode(true);
                }}
                size="large">
                <CancelIcon />
              </IconButton>
            </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} >
            <Box pb={1}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
      </form>
      )}
    </Fragment>
  );
}

export default ContactInfo;