import React from 'react';

// Material-UI
import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material';

// Icons
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

const GuideCard = ({
  mainTitle,
  subTitle,
  cardHeaderColor,
  cardIconColor,
  cardContentIcon,
  heightSize,
  contentItems,
}) => {
  const renderIcon = (iconType) => {
    switch (iconType) {
      case 'error':
        return <ErrorIcon sx={{ color: `${cardIconColor}`, fontSize: 32 }} />;
      case 'warning':
        return <WarningIcon sx={{ color: `${cardIconColor}`, fontSize: 32 }} />;
      case 'info':
        return <InfoIcon sx={{ color: `${cardIconColor}`, fontSize: 32 }} />;
      default:
        return null;
    }
  };

  return (
    <Card
      sx={{
        border: `1px solid ${cardHeaderColor}`,
        borderRadius: '8px',
        maxWidth: 'auto',
        margin: 'auto',
        height: `${heightSize ? heightSize : 'auto'}`,
        minHeight: `${heightSize ? heightSize : 'auto'}`,
        maxHeight: '80vh',
        marginTop: '0.5rem',
      }}
    >
      <CardHeader
        avatar={renderIcon(cardContentIcon)}
        title={
          <Typography variant="h6" sx={{ color: '#000000', fontWeight: 'bold' }}>
            {mainTitle}: {subTitle}
          </Typography>
        }
        sx={{
          mb: 2,
          backgroundColor: `${cardHeaderColor}`,
        }}
      />
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {contentItems.map((item, index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#000', mr: 1 }}>
                {item.contentTitle}
              </Typography>
              <Typography variant="body1" sx={{ color: '#000', mr: 1 }}>
                -
              </Typography>
              <Typography variant="body1" sx={{ color: '#000', ml: 1 }}>
                {item.contentDescription}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default GuideCard;
