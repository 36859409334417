import React, { useState } from 'react';

// AWS Amplify - API - Request Handler
import { handlePost } from '../../utils/amplifyInstance';

// Auth Session
import { useSession } from '../../context/AuthSession';

// Material-UI
import {
    IconButton,
    Tooltip,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    CircularProgress
} from '@mui/material';

// Icons
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';

function MessageDialog(props) {
  const { authToken } = useSession();
  const { accountid, open, setOpenDialog, row } = props;
  const [ acceptMessage, setAcceptMessage ] = useState(false);

  const AcceptMessage = async () => {
    let init = {
        message: {
          note: row.map(i => i.message)[0],
          publicmessageid: row.map(i => i.publicmessageid)[0]
        }
    }

    try {
      // var response = await post("EmployerApiGw", "/employer/" + accountid + "/acceptmessage", init);
      const response = await handlePost({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/acceptmessage`,
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        body: init
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        setAcceptMessage(false);
      }

    } catch (e) {
      console.log(e);
    }

    setOpenDialog(false);
  };

  const handleOnClick = () => {
    setAcceptMessage(true);

    AcceptMessage(
      accountid,
      row.map(i => i.publicmessageid)[0],
      row.map(i => i.message)[0]
    );
  };

  return (
    <div onClick={event => event.stopPropagation()}>
      <Dialog
        variant={"dense"}
        open={open}
      >
        <DialogContent>
          <InfoIcon
            sx={{ color: ['#2196f3'] }}
            fontSize={"large"}
          />
          <DialogContentText sx={{color:"black"}} >
            {row.map(i => i.message)[0]}
          </DialogContentText>
          <DialogActions>
            <Tooltip placement="left" title="Close">
              <IconButton
                sx={{ padding:"4px" }}
                disabled={acceptMessage}
                onClick={handleOnClick}
                size="large">
                {acceptMessage ?
                  <CircularProgress
                    sx={{ color: ['#2196f3'] }}
                    size={30}
                  />
                  :
                  <CheckIcon
                    sx={{ color: ['#2196f3'] }}
                    fontSize={"large"}
                  />
                }
              </IconButton>
            </Tooltip>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MessageDialog;