import React, { Fragment, useState } from 'react';

// AWS Amplify - Storage
import { remove } from 'aws-amplify/storage';

// Main Context
import { useMainContext } from '../../context/MainContext';

// Hook - useProtectedFileList
import useProtectedFileList from '../../hooks/useProtectedFileList';

// Material-UI
import {
  Box,
  Paper,
  Typography,
  TableCell,
  IconButton,
  Tooltip,
  Input,
  InputAdornment,
} from '@mui/material';

// Project Imports
import CustomLoadingScreen from '../../ui-components/CustomLoadingScreen';

// Helper Functions
import { pad, DownloadFile } from '../../common/HelperFunctions';

// Third Party
import { differenceInHours } from 'date-fns';

// DevExtreme React Grid
import { Plugin, Template, TemplatePlaceholder, Getter } from '@devexpress/dx-react-core';

// DevExtreme React Grid Material-UI
import {
  SearchState,
  SortingState,
  IntegratedFiltering,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';

// DevExtreme React Grid Material-UI Components
import {
  Grid,
  Toolbar,
  Table,
  VirtualTable,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

// Notistack
import { useSnackbar } from 'notistack';

// Icons
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import Search from '@mui/icons-material/Search';

const ToolbarSearch = ({ onValueChange, value }) => {
  return (
    <Plugin dependencies={[{ name: 'Toolbar' }]}>
      <Template name="toolbarContent">
        {(params) => (
          <TemplatePlaceholder>
            {() => (
              <Tooltip title="Search">
                <span>
                  <Box sx={{ pt: 2, pb: 2, pl: 2 }}>
                    <Input
                      onChange={(e) => onValueChange(e.target.value)}
                      value={value}
                      type="text"
                      placeholder="Search..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      }
                    />
                  </Box>
                </span>
              </Tooltip>
            )}
          </TemplatePlaceholder>
        )}
      </Template>
    </Plugin>
  );
};

const CanDelete = (file) => {
  if (differenceInHours(new Date(), file.lastModified) > 1.0) {
    return false;
  } else if (file.path.split('/').reverse()[1] !== 'employer') {
    return false;
  }
  return true;
};

const TableActionsColumn = ({ account, setRefresh }) => {
  const { enqueueSnackbar } = useSnackbar();

  const HandleFileDelete = (key) => {
    var options = {
      level: 'protected',
      path: key,
    };

    options.identityId = account.map((i) => i.cognito_identity_uuid);
    remove(options)
      .then((result) => {
        enqueueSnackbar('File deleted successfully!', { variant: 'success' });
      })
      .catch((e) => console.log(e));
  };


  return (
    <Fragment>
      <Plugin>
        <Getter
          name="tableColumns"
          computed={({ tableColumns }) => {
            const result = tableColumns.slice();
            result.splice(0, 0, {
              key: 'actions',
              type: 'actions',
              align: 'center',
              width: 85,
            });
            return result;
          }}
        />

        <Template
          name="tableCell"
          predicate={({ tableColumn, tableRow }) =>
            tableColumn.type === 'actions' && tableRow.type === Table.ROW_TYPE
          }
        >
          {(params) => (
            <TemplatePlaceholder>
              {() => (
                <TableCell align="center" padding="none">
                  <Tooltip title="Download">
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => {
                          DownloadFile(
                            params.tableRow.row.path, 
                            account.map(i => i.cognito_identity_uuid
                          ))
                        }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Delete File">
                    <span>
                      <IconButton
                        size="small"
                        disabled={!CanDelete(params.tableRow.row)}
                        onClick={() => {
                          setRefresh(true);
                          HandleFileDelete(params.tableRow.row.path);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              )}
            </TemplatePlaceholder>
          )}
        </Template>
        <Template
          name="tableCell"
          predicate={({ tableColumn, tableRow }) =>
            tableColumn.type === 'actions' && tableRow.type === TableHeaderRow.ROW_TYPE
          }
        >
          <TableCell align="center" padding="none">
            <b style={{ color: 'rgba(0,0,0,0.54)', fontSize: '0.75rem' }}>Actions</b>
          </TableCell>
        </Template>
      </Plugin>
    </Fragment>
  );
};

const TableHeaderContent = ({ column, children, classes, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    {...restProps}
    sx={{
      fontWeight: 'bold',
    }}
  >
    {children}
  </TableHeaderRow.Content>
);

// ===========================|| PROTECTED FILE LIST ||=========================== //

function ProtectedFileList() {
  const { account, companyid } = useMainContext();
  const [columns] = useState([
    {
      name: 'key',
      title: 'File Name',
      getCellValue: (row) => row.path.split('/').pop(),
    },
    {
      title: 'Date uploaded',
      name: 'lastModified',
      getCellValue: (row) =>
        row.lastModified.getUTCFullYear() +
        '-' +
        pad(row.lastModified.getUTCMonth() + 1) +
        '-' +
        pad(row.lastModified.getUTCDate()) +
        ' ' +
        pad(row.lastModified.getUTCHours()) +
        ':' +
        pad(row.lastModified.getUTCMinutes()) +
        ':' +
        pad(row.lastModified.getUTCSeconds()),
    },
    { title: 'Size (Bytes)', name: 'size' },
  ]);
  const [searchValue, setSearchState] = useState('');
  const [refresh, setRefresh] = useState(false);

  const { files, isLoading, isFetching, error } = useProtectedFileList(account, companyid, refresh);

  if (error) {
    return <Typography variant="h6">Error fetching files: {error.message}</Typography>;
  }

  return (
    <Fragment>
      {isLoading || isFetching ? (
        <CustomLoadingScreen text="Loading your files" />
      ) : files.length === 0 ? (
        <Typography variant="h6">No files found.</Typography>
      ) : (
        <Fragment>
          <Paper>
            <Grid rows={files} columns={columns}>
              <SearchState value={searchValue} onValueChange={setSearchState} />
              <SortingState />
              <IntegratedSorting />
              <IntegratedFiltering />
              <VirtualTable height={650} />
              <TableHeaderRow contentComponent={TableHeaderContent} showSortingControls />
              <TableActionsColumn account={account} companyId={companyid} setRefresh={setRefresh} />
              <Toolbar />
              <ToolbarSearch value={searchValue} onValueChange={setSearchState} />
            </Grid>
          </Paper>
        </Fragment>
      )}
    </Fragment>
  );
}

export default ProtectedFileList;
