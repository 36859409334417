import { useMemo } from 'react';

const useErrorDetection = (data) => {
  return useMemo(() => {
    return data.find((value) => {
      if (value?.__errors) {
        return Object.values(value.__errors)?.filter((err) => err.level === 'error').length;
      }
      return false;
    });
  }, [data]);
};

export default useErrorDetection;
