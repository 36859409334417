// Material UI 
import { styled } from '@mui/material/styles';

export const ContainerLayout = styled('div')({
  height: '89vh',
  display: 'flex',
  flexDirection: 'row',
});

export const ContentLayout = styled('div')({
  flex: 1,
});

