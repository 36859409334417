import React, { useState, useEffect } from "react";

// Material-UI
import {
  Button,
  TextField,
  Dialog,
  Typography,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@mui/material";

function AdminDialog(props) {
  const [adminid, setAdminId] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (props.isValid === false) {
      setMessage("*** Invalid AccountId!");
    } else {
      setMessage("");
    }
  }, [props.isValid]);

  const handleonChange = (e) => {
    setMessage("");
    setAdminId(e.target.value);
  };

  const handleOnClick = () => {
    setMessage("");
    props.setAccountId(adminid);
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Dialog open={props.open}>
        <DialogTitle id="adminDialog">Enter an Account ID</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="AdminId"
            label="Account ID"
            onChange={handleonChange}
            value={adminid}
          />
          <DialogActions>
            <Button onClick={handleOnClick}>Login</Button>
          </DialogActions>
          <DialogContentText>
            <Typography color="secondary" variant="caption">
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AdminDialog;
