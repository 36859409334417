import React, { Fragment, useState } from "react";

// Material UI
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";

// Project Imports
import SpreadSheetImporterWizard from "../index";
import ConfirmCloseAlert from "../components/Alerts/ConfirmCloseAlert";

export default function SpreadSheetImporterDialog({
  open,
  setOpen,
  accountid,
  companyid,
  roleid,
  statementRow,
  statementid,
  cognito_identity_uuid,
  billingStatementOpen,
}) {
  const [showModal, setShowModal] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  // Refresh the page when the dialog is closed and the user confirms
  const handleClose = () => {
    setShowModal(!showModal);
  };

  // Close the dialog and refresh the page
  const handleConfirmClose = () => {
    setShowModal(false);
    setOpen(false);
  };

  return (
    <Fragment>
      <Dialog maxWidth="xl" open={open} onClose={handleClose}>
        {open && (
          <>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Employer connect TA upload
              <IconButton
                edge="end"
                color="inherit"
                onClick={confirmClose ? handleConfirmClose : handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              sx={{
                overflowY: "auto",
                scrollBehavior: "inside",
                maxHeight: "auto",
              }}
            >
              <SpreadSheetImporterWizard
                accountid={accountid}
                companyid={companyid}
                roleid={roleid}
                statementRow={statementRow}
                statementid={statementid}
                cognito_identity_uuid={cognito_identity_uuid}
                confirmClose={confirmClose}
                setConfirmClose={setConfirmClose}
                billingStatementOpen={billingStatementOpen}
                handleClose={handleClose}
              />
            </DialogContent>
            <Box sx={{ mb: 3 }} />
          </>
        )}
      </Dialog>
      <ConfirmCloseAlert
        isOpen={showModal}
        onClose={handleClose}
        onConfirm={handleConfirmClose}
      />
    </Fragment>
  );
}
