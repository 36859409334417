import React from "react";

// Material UI
import { Box, Typography, Grid, Divider } from "@mui/material";

// Hooks
import { useInitialExcelHours } from "../../../hooks/useInitialExcelHours";

// Helper Functions
import { checkInput } from "../../../../../common/HelperFunctions";

// Project imports
import ContributionWidgetSummary from "./ContributionWidgetSummary";
import ContributionHoursSummary from "./ContributionHoursSummary";
import InfoTooltip from "../../../components/Info/InfoTooltip";
import CustomLoadingScreen from "../../../../../ui-components/CustomLoadingScreen";

const ContributionHoursInitial = ({
  accountid,
  companyid,
  authToken,
  version,
  statementid,
  payPeriodStart,
  payPeriodEnd,
  finalPenHrs,
  finalLtdHrs,
}) => {
  const { initialExcelHours, sumInitialExcelHours, isExcelHoursLoading, error } = useInitialExcelHours(accountid, companyid, authToken, version, statementid);

  if (isExcelHoursLoading) {
    return <CustomLoadingScreen text="Loading Total Hours..." />;
  }

  if (error) {
    return <Typography>Error: {error.message}</Typography>;
  }

  // Total Pension Hours Change
  const totalPenHrsChange = (initialExcelHours.reduce((acc, { penhrs }) => {
    return acc + checkInput(finalPenHrs) - checkInput(penhrs);
  }, 0));

  // Total LTD Hours Change
  const totalLtdHrsChange = (initialExcelHours.reduce((acc, { ltdhrs }) => {
    return acc + checkInput(finalLtdHrs) - checkInput(ltdhrs);
  }, 0));

  // Final Total Hours Change
  const totalHoursPension = finalPenHrs ? finalPenHrs : 0;
  const totalHoursLTD = finalLtdHrs ? finalLtdHrs : 0;

  // Sum of total hours for pension and LTD
  const totalHoursSum = totalHoursPension + totalHoursLTD > 0 ? (totalHoursPension + totalHoursLTD) : 0;

  // Formated Total Pension Hours Change
  const formatedTotalPenHrsChange = totalPenHrsChange.toFixed(2);

  // Formated Total LTD Hours Change
  const formatedTotalLtdHrsChange = totalLtdHrsChange.toFixed(2);
  
  // Formated Total Pension and LTD Hours Change
  const formatedTotalDiscrepancy = (totalHoursSum - sumInitialExcelHours);

  return (
    <div>
      <Box sx={{ m: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ContributionWidgetSummary
              title="Pension Hours Change"
              total={formatedTotalPenHrsChange}
              unit="hrs"
              sx={{
                backgroundColor: "#f0f0f0",
                borderLeft: "5px solid #008522",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <ContributionWidgetSummary
              title="LTD Hours Change"
              total={formatedTotalLtdHrsChange}
              unit="hrs"
              sx={{
                backgroundColor: "#f0f0f0",
                borderLeft: "5px solid #008522",
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <ContributionWidgetSummary
              title="Total Hours Change"
              total={formatedTotalDiscrepancy}
              unit="hrs"
              sx={{
                backgroundColor: "#f0f0f0",
                borderLeft: "5px solid #008522",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }} />
      <Divider />
      <Box
        sx={{
          gap: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1,
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Typography
          variant="subtitle"
          sx={{
            ml: 2,
            fontWeight: "bold",
            fontSize: "1rem",
            color: "#000",
          }}
          noWrap
        >
          Pay Period
        </Typography>

        <div>
          <Box
            sx={{
              mb: 1,
              gap: 12,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="span"
              sx={{
                mr: 0.5,
                ml: 0.5,
                borderRadius: 1,
                p: 0.5,
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Pension Hours
              <InfoTooltip
                initialHours={"Initial Pension Hours"}
                finalHours={"Final Pension Hours"}
              />
            </Box>
            <Box
              component="span"
              sx={{
                mr: 3.5,
                ml: 0.5,
                borderRadius: 1,
                p: 0.5,
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              LTD Hours
              <InfoTooltip
                initialHours={"Initial LTD Hours"}
                finalHours={"Final LTD Hours"}
              />
            </Box>
          </Box>
        </div>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#d5f2dc",
        }}
      >
        {initialExcelHours.length === 0 ? (
          <Typography variant="subtitle">No discrepancies found.</Typography>
        ) : (
          initialExcelHours.map((item, index) => (
            <ContributionHoursSummary
              key={item.employeelistid || index}
              item={item}
              payPeriodStart={payPeriodStart}
              payPeriodEnd={payPeriodEnd}
              finalPenHrs={finalPenHrs}
              finalLtdHrs={finalLtdHrs}
            />
          ))
        )}
      </Box>
    </div>
  );
};

export default ContributionHoursInitial;
