import React from "react";

// Material-UI
import { CircularProgress, Typography, Grid } from "@mui/material";

const CustomLoadingScreen = (props) => (
  <Grid container justifyContent="center" alignItems="center" style={props.style}>
    <Grid item>
      <CircularProgress />
    </Grid>
    <Grid>
      <Typography variant="h6">{props.text}</Typography>
    </Grid>
  </Grid>
);

export default CustomLoadingScreen;