import { useQuery } from "@tanstack/react-query";

// AWS Amplify - API - Request Handler
import { handleGet } from "../utils/amplifyInstance";

const useFetchCompanyList = (accountid, authToken) => {
  const fetchCompanyList = async () => {
    if (!accountid || !authToken) {
      return;
    }

    try {
      const response = await handleGet({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/companylist`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = response;
      const json = await body.json();

      return json.items;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["companyList", accountid, authToken],
    queryFn: fetchCompanyList,
    enabled: !!accountid && !!authToken,
    placeholderData: [],
  });

  return { data, error, isLoading };
};

export default useFetchCompanyList;
