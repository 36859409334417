import React from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Material UI
import { Button, Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Logo Responsive Box
const LogoResponsiveBox = styled('div')({
  img: {
    width: 'auto',
    '@media (min-width:1200px)': {
      width: 240,
    },
    '@media (min-width:992px) and (max-width:1199px)': {
      width: 240,
    },
    '@media (min-width:768px) and (max-width:991px)': {
      width: 240,
    },
    '@media (min-width:576px) and (max-width:767px)': {
      width: 240,
    },
    '@media (min-width:480px) and (max-width:575px)': {
      width: 220,
    },
    '@media (max-width:479px)': {
      width: 220,
    },
  },
});

const NotFoundView = () => {
  // Logos
  const ErConn = '/assets/images/erconn.png';

  const navigate = useNavigate();

  const handleGoHome = () => {
    try {
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'center',
      }}
    >
      <Container>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Sorry, page not found!
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Sorry, we couldn't find the page you were looking for. <br></br> Maybe you have a typo in
          the URL? Or you meant to go to a different location? <br></br>
          Either way, let's get you back to the home page.
        </Typography>
        <Box sx={{ m: 2 }} />
        <LogoResponsiveBox>
          <img src={ErConn} alt="ERConn Logo" style={{ height: '120px' }} />
        </LogoResponsiveBox>
        <Button variant="contained" onClick={handleGoHome} sx={{ mt: 3 }}>
          Go back to Home
        </Button>
      </Container>
    </Box>
  );
};

export default NotFoundView;
