import React, { Fragment } from "react";

// Material ui
import {
  Avatar,
  Paper,
  Button,
  Stack,
  Typography,
  SvgIcon,
  Box,
  Collapse,
} from "@mui/material";

// Icons
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const UnmatchedFieldsCollapse = ({ isOpen, onClose, onConfirm, fields }) => {
  return (
    <Fragment>
      <Collapse in={isOpen}>
        <Paper sx={{ border: "2px solid rgba(0, 133, 34, 0.3)" }}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", p: 3 }}>
              <Avatar
                sx={{
                  backgroundColor: "error.lightest",
                  color: "error.main",
                }}
              >
                <SvgIcon>
                  <WarningAmberIcon />
                </SvgIcon>
              </Avatar>
              <div>
                <Typography variant="h5">Not all columns matched</Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 1 }}
                  variant="body2"
                >
                  There are required columns that are not matched or ignored. Do
                  you want to continue?
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 1 }}
                  variant="body2"
                >
                  Columns not matched:
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 1, fontWeight: 700 }}
                  variant="body2"
                >
                  {" "}
                  {fields.join(", ")}
                </Typography>
              </div>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pb: 3,
                px: 3,
              }}
            >
              <Button
                color="inherit"
                variant="text"
                sx={{ mr: 2 }}
                onClick={onClose}
              >
                Close
              </Button>
              <Button color="primary" variant="contained" onClick={onConfirm}>
                Continue
              </Button>
            </Box>
          </Paper>
          <Box sx={{ p: 1 }} />
      </Collapse>
    </Fragment>
  );
};

export default UnmatchedFieldsCollapse;
