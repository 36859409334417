import React from "react";

// Material-UI
import { Button } from "@mui/material";

const DefaultEndDateButton = ({ handleSelectDefaultWorkStatusEndDate }) => {
  return (
    <Button
      variant="outlined"
      size="large"
      onClick={handleSelectDefaultWorkStatusEndDate}
    >
      Default end date
    </Button>
  );
};

export default DefaultEndDateButton;
