import React, { useState } from "react";

// PropTypes validation
import PropTypes from 'prop-types';

// Material - UI
import { Box, Stack } from "@mui/material";

// Project Imports
import AwsCognitoRegister from "../register/AwsCognitoRegister";
import RegisterCodeVerification from "../register-code-verification/RegisterCodeVerification";
import RegisteredSuccess from "../registration-complete/RegisteredSuccess";

const getStepRegister = (
  step,
  handleNext,
  handleBack,
  registerData,
  setRegisterData,
  codeVerificationData,
  setCodeVerificationData,
  handleBackToSignIn
) => {
  switch (step) {
    case 0:
      return (
        <AwsCognitoRegister
          handleNext={handleNext}
          registerData={registerData}
          setRegisterData={setRegisterData}
        />
      );
    case 1:
      return (
        <RegisterCodeVerification
          handleNext={handleNext}
          handleBack={handleBack}
          registerData={registerData}
          codeVerificationData={codeVerificationData}
          setCodeVerificationData={setCodeVerificationData}
        />
      );
    case 2:
      return <RegisteredSuccess handleBackToSignIn={handleBackToSignIn} />;
    default:
      return "Unknown step";
  }
};

// ===========================|| REGISTRATION WIZARD ||============================ //

const AuthRegister = ({ handleBackToSignIn }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [registerData, setRegisterData] = useState({ email: "", password: "" });
  const [codeVerificationData, setCodeVerificationData] = useState({
    code: "",
  });

  // handle next step
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  // handle back step
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Box sx={{ p: 0.5 }} />
        {getStepRegister(
          activeStep,
          handleNext,
          handleBack,
          registerData,
          setRegisterData,
          codeVerificationData,
          setCodeVerificationData,
          handleBackToSignIn
        )}
      </Stack>
    </Box>
  );
};

AuthRegister.propTypes = {
  handleBackToSignIn: PropTypes.func.isRequired,
};

export default AuthRegister;
