import { useQuery } from "@tanstack/react-query";

// AWS Amplify - API - Request Handler
import { handleGet } from "../utils/amplifyInstance";

const useCompanyInformation = (accountid, companyid, authToken, setOnlyLTD) => {
  const fetchCompanyInformation = async () => {
    if (!accountid || !companyid || !authToken) {
      return;
    }

    try {
      const response = await handleGet({
        apiName: "EmployerApiGw",
        path: `/employer/${accountid}/${companyid}/name`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const json = await body.json();

      setOnlyLTD(json.items[0].onlyltd);
      return json.items[0];

    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["companyInformation", accountid, companyid, authToken],
    queryFn: fetchCompanyInformation,
    enabled: !!accountid && !!companyid && !!authToken,
  });

  if (error) {
    console.log("Error fetching company information: ", error);
  }

  return { data, error, isLoading };
};

export default useCompanyInformation;
