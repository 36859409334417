import React, { Fragment } from "react";

// Material ui
import {
  Avatar,
  Paper,
  Button,
  Stack,
  Typography,
  SvgIcon,
  Box,
  Collapse,
} from "@mui/material";

// Icons
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ErrorDetectedCollapse = ({ isOpen, onClose }) => {
  return (
    <Fragment>
      <Collapse in={isOpen}>
          <Paper sx={{ border: "2px solid rgba(0, 133, 34, 0.3)" }}>
            <Stack direction="row" spacing={2} sx={{ display: "flex", p: 3 }}>
              <Avatar
                sx={{
                  backgroundColor: "error.lightest",
                  color: "error.main",
                }}
              >
                <SvgIcon>
                  <WarningAmberIcon />
                </SvgIcon>
              </Avatar>
              <div>
                <Typography variant="h5">ERRORS DETECTED</Typography>
                <Typography
                  color="text.secondary"
                  sx={{ mt: 1 }}
                  variant="body1"
                >
                 There are still some rows that contain errors. Please fix them before continuing. 
                 To quickly access errors toggle switch to SHOW ONLY ROWS WITH ERRORS, located on the top right side of the screen.
                </Typography>
              </div>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pb: 3,
                px: 3,
              }}
            >
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={onClose}
              >
                Close
              </Button>
            </Box>
          </Paper>
      </Collapse>
    </Fragment>
  );
};

export default ErrorDetectedCollapse;
