// Material UI
import { Box, List, ListItem } from "@mui/material";

const InfoBox = ({ description }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: "#0085224d",
        p: 2,
        borderRadius: 1,
        mt: 2,
        mb: 2,
        borderTop: "6px solid #008522",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <List
          sx={{ fontSize: "1rem", mr: 2, ml: 3, whiteSpace: "pre-line" }}
        >
          {description.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default InfoBox;
