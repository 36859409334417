import { useState, Fragment } from "react";

// Material UI
import {
  Card,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

// Project Import
import DiscardRowsVerification from "./Verification/DiscardRowsVerification";
import EmployeeActiveStatusConfirmation from "./ActivateStatus/EmployeeActiveStatusConfirmation";
import FileParsingSummaryComplete from "./Complete/FileParsingSummaryComplete";

// Step Options
const steps = [
  "Discard Rows Verification",
  "Employee Active Status Confirmation",
  "File Parsing Summary",
];

const getStepContent = (
  step,
  handleNext,
  submitData,
  setSubmitData,
  summaryData,
  statementid,
  deletedRows,
  setDeletedRows,
  employeeActiveCheckList,
  accountid,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  statementRow,
  totalHours,
  processedRows,
  discardedRows,
  isLoading,
  recordedRows,
  setRecordedRows,
  numOfProcessedRows,
  setNumOfProcessedRows,
  disableButtonAfterSubmit,
  onSubmit,
  submitDiscardedRows,
  handleClose
) => {
  // Full Wirzard Steps Validation Check
  if (deletedRows.length !== 0 && employeeActiveCheckList.length !== 0) {
    switch (step) {
      case 0:
        return (
          <EmployeeActiveStatusConfirmation
            handleNext={handleNext}
            submitData={submitData}
            setSubmitData={setSubmitData}
            setDeletedRows={setDeletedRows}
            employeeActiveCheckList={employeeActiveCheckList}
            accountid={accountid}
            onClaimEmployeeIds={onClaimEmployeeIds}
            totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
            statementRow={statementRow}
            setNumOfProcessedRows={setNumOfProcessedRows}
          />
        );
      case 1:
        return (
          <DiscardRowsVerification
            submitData={submitData}
            setSubmitData={setSubmitData}
            deletedRows={deletedRows}
            accountid={accountid}
            onClaimEmployeeIds={onClaimEmployeeIds}
            totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
            statementRow={statementRow}
            setRecordedRows={setRecordedRows}
            handleNext={handleNext}
            submitDiscardedRows={submitDiscardedRows}
          />
        );
      case 2:
        return (
          <FileParsingSummaryComplete
            summaryData={summaryData}
            accountid={accountid}
            statementid={statementid}
            statementRow={statementRow}
            totalHours={totalHours}
            numOfProcessedRows={numOfProcessedRows}
            discardedRows={discardedRows}
            processedRows={processedRows}
            recordedRows={recordedRows}
            isLoading={isLoading}
            submitData={submitData}
            disableButtonAfterSubmit={disableButtonAfterSubmit}
            onSubmit={onSubmit}
            handleClose={handleClose}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  } else if (deletedRows.length === 0 && employeeActiveCheckList.length !== 0) {
    switch (step) {
      case 0:
        return (
          <EmployeeActiveStatusConfirmation
            handleNext={handleNext}
            submitData={submitData}
            setSubmitData={setSubmitData}
            setDeletedRows={setDeletedRows}
            employeeActiveCheckList={employeeActiveCheckList}
            accountid={accountid}
            onClaimEmployeeIds={onClaimEmployeeIds}
            totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
            statementRow={statementRow}
            setNumOfProcessedRows={setNumOfProcessedRows}
          />
        );
      case 1:
        return (
          <FileParsingSummaryComplete
            summaryData={summaryData}
            accountid={accountid}
            statementid={statementid}
            statementRow={statementRow}
            totalHours={totalHours}
            numOfProcessedRows={numOfProcessedRows}
            discardedRows={discardedRows}
            processedRows={processedRows}
            recordedRows={recordedRows}
            isLoading={isLoading}
            submitData={submitData}
            disableButtonAfterSubmit={disableButtonAfterSubmit}
            onSubmit={onSubmit}
            handleClose={handleClose}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  } else if (deletedRows.length !== 0 && employeeActiveCheckList.length === 0) {
    switch (step) {
      case 0:
        return (
          <DiscardRowsVerification
            submitData={submitData}
            setSubmitData={setSubmitData}
            deletedRows={deletedRows}
            accountid={accountid}
            onClaimEmployeeIds={onClaimEmployeeIds}
            totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
            statementRow={statementRow}
            setRecordedRows={setRecordedRows}
            handleNext={handleNext}
            submitDiscardedRows={submitDiscardedRows}
          />
        );
      case 1:
        return (
          <FileParsingSummaryComplete
            summaryData={summaryData}
            accountid={accountid}
            statementid={statementid}
            statementRow={statementRow}
            totalHours={totalHours}
            numOfProcessedRows={numOfProcessedRows}
            discardedRows={discardedRows}
            processedRows={processedRows}
            recordedRows={recordedRows}
            isLoading={isLoading}
            submitData={submitData}
            disableButtonAfterSubmit={disableButtonAfterSubmit}
            onSubmit={onSubmit}
            handleClose={handleClose}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  } else {
    switch (step) {
      case 0:
        return (
          <FileParsingSummaryComplete
            summaryData={summaryData}
            accountid={accountid}
            statementid={statementid}
            statementRow={statementRow}
            totalHours={totalHours}
            numOfProcessedRows={numOfProcessedRows}
            discardedRows={discardedRows}
            processedRows={processedRows}
            recordedRows={recordedRows}
            isLoading={isLoading}
            submitData={submitData}
            disableButtonAfterSubmit={disableButtonAfterSubmit}
            onSubmit={onSubmit}
            handleClose={handleClose}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }
};

const FileParsingConfirmation = ({
  submitData,
  setSubmitData,
  deletedRows,
  setDeletedRows,
  employeeActiveCheckList,
  accountid,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  statementRow,
  totalHours,
  processedRows,
  discardedRows,
  isLoading,
  statementid,
  summaryData,
  recordedRows,
  setRecordedRows,
  disableButtonAfterSubmit,
  onSubmit,
  submitDiscardedRows,
  handleClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [numOfProcessedRows, setNumOfProcessedRows] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <Card elevation={0} style={{ border: "none" }}>
      <CardContent>
        <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 1, display: "none" }}>
          {steps.map((label, index) => {
            const labelProps = {};
            return (
              <Step key={label} sx={{ display: "none" }}>
                <StepLabel {...labelProps}>
                  <Typography variant="body1">{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Fragment>
          {getStepContent(
            activeStep,
            handleNext,
            submitData,
            setSubmitData,
            summaryData,
            statementid,
            deletedRows,
            setDeletedRows,
            employeeActiveCheckList,
            accountid,
            onClaimEmployeeIds,
            totalMaxHoursPerPayPeriod,
            statementRow,
            totalHours,
            processedRows,
            discardedRows,
            isLoading,
            recordedRows,
            setRecordedRows,
            numOfProcessedRows,
            setNumOfProcessedRows,
            disableButtonAfterSubmit,
            onSubmit,
            submitDiscardedRows,
            handleClose
          )}
        </Fragment>
      </CardContent>
    </Card>
  );
};

export default FileParsingConfirmation;
