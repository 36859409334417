import React from 'react';

// Material UI
import { Box, Tab, Card, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Hooks
import useSummaryTabs from '../../../hooks/useSummaryTabs';

// Project imports
import SummaryTabs from '../../../components/Tabs/SummaryTabs';

// Icons
import InfoIcon from '@mui/icons-material/Info';

const MAIN_TABS = [
  { value: 'statementSummary', label: 'STATEMENT SUMMARY' },
  { value: 'hoursAndEmployeesDetails', label: "HOURS AND EMPLOYEE'S DETAILS" },
];

const ContributionTabMain = ({ tabContents, sx, ...other }) => {
  const theme = useTheme();
  const tabs = useSummaryTabs('statementSummary');

  return (
    <Card sx={{ pb: 2, maxWidth: theme.breakpoints.values.lg, ...sx }} {...other}>
      <SummaryTabs
        value={tabs.value}
        onChange={tabs.handleChange}
        variant="fullWidth"
        sx={{
          my: 2,
          borderRadius: 3,
          backgroundColor: '#f5f5f5',
        }}
        tabSlotProps={{
          indicator: {
            borderRadius: 2,
            boxShadow: theme.shadows[1],
          },
          tab: { p: 2.5 },
        }}
      >
        {MAIN_TABS.map((tab, index) => (
          <Tab
            key={index}
            value={tab.value}
            label={
              <Box
                sx={{
                  width: 1,
                  display: 'flex',
                  gap: { xs: 1, md: 2.5 },
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: { xs: 'center', md: 'flex-start' },
                }}
              >
                <div>
                  <Box
                    sx={{
                      mb: 0,
                      gap: 0.5,
                      display: 'flex',
                      fontWeight: 'bold',
                      alignItems: 'center',
                      typography: 'subtitle',
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      {tab.label}
                      <Tooltip
                        title={
                          tab.value === 'statementSummary'
                            ? 'Statement Summary'
                            : "Hours and Employee's Details"
                        }
                        placement="top"
                      >
                        <InfoIcon fontSize="small" style={{ marginLeft: 3 }} />
                      </Tooltip>
                    </Box>
                  </Box>
                </div>
              </Box>
            }
          />
        ))}
      </SummaryTabs>
      {tabContents[tabs.value]}
    </Card>
  );
};

export default ContributionTabMain;
