import React from "react";

// Material-UI
import { Button } from "@mui/material";

const DiscardSelectedRowButton = ({ deleteSelectedRows }) => {
  return (
    <Button variant="outlined" size="large" onClick={deleteSelectedRows}>
      Discard selected rows
    </Button>
  );
};

export default DiscardSelectedRowButton;
