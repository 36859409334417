import React from 'react';

const useUnmatchedRequiredFields = (fields, columns) => {
  const findUnmatchedRequiredFields = (fields, columns) => {
    const requiredFields = fields.filter(field =>
      field.validations?.some(validation => validation.rule === "required")
    );
  
    const unmatchedRequiredFields = requiredFields.filter(field =>
      columns.every(column => !("value" in column) || column.value !== field.key)
    );
  
    return unmatchedRequiredFields.map(field => field.label);
  };

  const unmatchedFields = React.useMemo(() => {
    return findUnmatchedRequiredFields(fields, columns);
  }, [fields, columns]);

  return unmatchedFields;
};

export default useUnmatchedRequiredFields;