import { useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet } from '../utils/amplifyInstance';

const useCompanyAddress = (submitData, validate, props, reload) => {
  const { accountid, companyid, authToken } = props;

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      if (!accountid || !companyid || !authToken) {
        return;
      }

      try {
        // var response = await get("EmployerApiGw", "/employer/" + accountid + "/" + companyid + "/address");
        const response = await handleGet({
          apiName: "EmployerApiGw",
          path: "/employer/" + accountid + "/" + companyid + "/address",
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });

        if (cancel) {
          return;
        }

        // response = JSON.parse(
        //   JSON.stringify(response, (k, v) => {
        //     return v == null ? "" : v;
        //   })
        // );

        const { body } = await response;
        const json = await body.json();

        setData(json.items[0]);
      } catch (e) {
        console.log(e);
        setData({});
      }

      setErrors({});
      setIsSubmitting(false);
      setIsLoading(false);
    };

    fetchData();

    return () => {
      cancel = true;
    }
  }, [reload, accountid, companyid, authToken]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setIsSubmitting(false);
      submitData();
    }
  }, [errors, isSubmitting, submitData]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    setErrors(validate(data));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.persist();

    var value = "";

    if ( event.target.name === "address1"
      || event.target.name === "address2"
      || event.target.name === "city"
      || event.target.name === "postalcode") {
      value = event.target.value.toUpperCase();
    } else if (event.target.type === 'checkbox') {
      value = event.target.checked ? "Y" : "N";
    } else {
      value = event.target.value;
    }
    setData(data => ({ ...data, [event.target.name]: value }));
  };

  const handleLOVChange = (name, value) => {
    setData({...data, [name]: value });
  };

  return {
    isLoading,
    handleChange,
    handleLOVChange,
    handleSubmit,
    data,
    errors
  }
};

export default useCompanyAddress;