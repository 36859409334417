import { useCallback } from "react";

// Notistack
import { useSnackbar } from "notistack";

/**
 * Custom hook to handle default work status end date.
 * @param {Object} props - The properties.
 * @param {Array} props.data - The data array.
 * @param {Array} props.selection - The selected rows.
 * @param {Function} props.updateData - Function to update data.
 * @param {Function} props.setSelection - Function to set selection.
 * @param {Array} props.requireStartAndEndDateWorkStatuses - The work statuses that require start and end dates.
 * @param {Object} props.statementRow - The statement row.
 * @returns {Object} - Object containing the handleSelectDefaultWorkStatusEndDate function.
 *
 */

const useDefaultWorkStatusEndDate = ({
  data,
  selection,
  updateData,
  setSelection,
  requireStartAndEndDateWorkStatuses,
  statementRow,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectDefaultWorkStatusEndDate = useCallback(() => {

    if (selection.length === 0) {
      enqueueSnackbar("Please select a row to update.", { variant: "warning" });
      return;
    }

    let isUpdated = false;

    const updatedData = data.map((row) => {
      if (
        selection.includes(row.__index) &&
        requireStartAndEndDateWorkStatuses.includes(row.workstatus?.trim())
      ) {
        isUpdated = true;
        return {
          ...row,
          workstatusenddate: statementRow.periodend,
        };
      }
      return row;
    });

    if (isUpdated) {
      setSelection([]);
    } else {
      enqueueSnackbar(
        "Please select a work status that requires start and end dates.",
        { variant: "warning" }
      );
      return;
    }

    updateData(updatedData);
    enqueueSnackbar("Work status end date updated.", { variant: "success" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selection, updateData, enqueueSnackbar]);

  return {
    handleSelectDefaultWorkStatusEndDate,
  };
};

export default useDefaultWorkStatusEndDate;
