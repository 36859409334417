import { Link } from "react-router-dom";

// Material-UI
import { useTheme } from "@mui/material/styles";

import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { customInput } from "../../../../theme/Typography";

// Project Imports
import { useUser } from "../../../../context/AuthContext";
import ErrorBox from "../../../../ui-components/NotificationErrorBox";

// Third-party
import * as Yup from "yup";
import { Formik } from "formik";

// ===========================|| AUTH - CODE VERIFICATION ||=========================== //

const RegisterCodeVerification = ({
  handleNext,
  registerData,
  setCodeVerificationData,
}) => {  
  // User Context
  const { handleConfirmSignUp, resendSignUp } = useUser();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ maxWidth: 400, margin: "0 auto" }}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Grid
          container
          direction={matchDownSM ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Box sx={{ mt: 2 }} />
            <Stack spacing={2}>
              <Typography gutterBottom variant={matchDownSM ? "h5" : "h6"} align="center">
                Verification Code
              </Typography>
              <Typography variant="subtitle1" align="center">
                Please enter the verification code sent to your email address {registerData && registerData.email ? registerData.email : "associated with your account"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                code: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string()
                .required("Verification code is required")
                .matches(/^[0-9]{6}$/, "Verification code must be a 6-digit number"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, errors }
              ) => {
                try {
                  const code = values.code;
                  await handleConfirmSignUp({
                    username: registerData.accountID.trim().replace(/\s+/g, ''),
                    confirmationCode: code.trim().replace(/\s+/g, ''),
                  });
                  setCodeVerificationData({ 
                    code: code.trim().replace(/\s+/g, ''),
                   });
                  setStatus({ success: true });
                  setSubmitting(false);
                  if (!errors) {
                    handleNext();
                  }
                } catch (error) {
                  console.log(error.message );
                  setStatus({ success: false });
                  setErrors({ submit: error.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.code && errors.code)}
                    sx={customInput}
                  >
                    <InputLabel htmlFor="code">Verification Code</InputLabel>
                    <OutlinedInput
                      id="code"
                      name="code"
                      type="text"
                      value={values.code}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Verification Code"
                    />
                    {touched.code && errors.code && (
                      <FormHelperText error id="code-helper-text">
                        {touched.code && errors.code}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Box sx={{ mt: 2 }} />
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#008521",
                      color: theme.palette.primary.contrastText,
                      "&:hover": { backgroundColor: "#008521" },
                    }}
                    disabled={isSubmitting}
                  >
                    Verify
                  </Button>
                  <Box sx={{ mt: 2 }} />
                  {errors.submit && (
                    <ErrorBox message={errors.submit} />
                  )}
                  <Grid item xs={12}>
                    <Typography
                      component={Link}
                      to="/"
                      variant="subtitle1"
                      sx={{ textDecoration: "none", color: "inherit" }}
                      textAlign={matchDownSM ? "center" : "inherit"}
                    >
                      Did not receive the code? Check your spam filter, or
                    </Typography>
                  </Grid>
                  <Box sx={{ mt: 2 }} />
                  <Grid item xs={12}>
                    <Button
                      disableElevation
                      fullWidth
                      size="large"
                      variant="outlined"
                      onClick={async () => { await resendSignUp(registerData.accountID.trim().replace(/\s+/g, '')); }}
                      sx={{
                        color: "#008521",
                        backgroundColor: "transparent",
                        borderColor: "#008521",
                        "&:hover": { 
                          backgroundColor: "transparent",
                          borderColor: "#008521",
                        },
                      }}
                    >
                      Resend Code
                    </Button>
                    <Box sx={{ mt: 2 }} />
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Box>
  );
};

export default RegisterCodeVerification;
