import React, { Fragment } from "react";

// Material-ui
import { Box, Container, Typography } from "@mui/material";

// Project imports
import TableActivateValidation from "../../table/TableActivateValidation";

const EmployeeActiveStatusConfirmation = ({
  handleNext,
  submitData,
  setSubmitData,
  setDeletedRows,
  employeeActiveCheckList,
  accountid,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  statementRow,
  setNumOfProcessedRows,
}) => {
  return (
    <Fragment>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" marginLeft={1}>
          No hours were reported for the employees listed below. Please change
          their status if they are not currently active.
        </Typography>
      </Box>
      <Container maxWidth="xl" disableGutters>
        <TableActivateValidation
          handleNext={handleNext}
          submitData={submitData}
          setSubmitData={setSubmitData}
          setDeletedRows={setDeletedRows}
          employeeActiveCheckList={employeeActiveCheckList}
          accountid={accountid}
          onClaimEmployeeIds={onClaimEmployeeIds}
          totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
          statementRow={statementRow}
          setNumOfProcessedRows={setNumOfProcessedRows}
        />
      </Container>
    </Fragment>
  );
};

export default EmployeeActiveStatusConfirmation;
