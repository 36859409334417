import React, { useState } from "react";

// React Router
import { useNavigate } from "react-router-dom";

// Material-UI
import {
  Menu,
  Button,
  MenuItem,
  ListItemIcon
} from "@mui/material";

// Icons
import DownIcon from "@mui/icons-material/ArrowDropDown";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SignoutIcon from "@mui/icons-material/SettingsPower";

// Project Imports
import ChangePasswordDialog from "../components/change-password/ChangePasswordDialog";

const UserMenu = (props) => {
  const { email, signOut } = props;

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const logOut = () => {
    try {
      navigate("/", { replace: true });
      signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Button
          id="user-button"
          aria-controls="user-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          onClick={handleClick}
          endIcon={<DownIcon />}
        >
          {email}
        </Button>
        <Menu
          id="user-menu"
          MenuListProps={{
            'aria-labelledby': 'user-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setOpenDialog(true);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <VpnKeyIcon fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              logOut();
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <SignoutIcon fontSize="small"/>
            </ListItemIcon>
            Signout
          </MenuItem>
        </Menu>
      </div>

      <ChangePasswordDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
};

export default UserMenu;
