import { Grid, Typography } from "@mui/material";

const ContributionGross = ({ description }) => {  
    return (
      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  
  export default ContributionGross;